
.btn-square{
    @include flexbox(row,center,center);
    background: $button-gradient;
    height: 42px;
    width: 42px;
    min-width: 42px;
}

.btn-square.--info{
    border-color: $border-gold;
    background: $gold-gradient-transp;
}

.btn-square.--filters{
    background:$input-gradient;
    border:1px solid $border;
    @include padding-responsive;
}

.chat-panel{
    &__dialog-action{
        .btn-square{
            height: 44px;
            width: 44px;
            min-width: 44px;
            background:$button-gradient-opaque;
        }
    }
}

.laptop-options{
    .btn-square{
        background: linear-gradient(-60deg,#3A3655,#223039 100%);
    }
}

.searchframe .btn-square{
    // background: $button-gradient-opaque;
    background: $input-gradient;
    border: 1px solid $border;
}

.searchframe .btn-square.active{
    background: linear-gradient(45deg,#6E30AD64,#444EA564);
    border: 1px solid #8d5cf6a4;
}


@media screen and (max-width:1600px) {


    .btn-square{
        height: 42px;
        width: 42px;
        min-width: 42px;
    }

}

@media screen and (max-width:1400px) {

    .btn-square{
        height: 40px;
        width: 40px;
        min-width: 40px;
    }

}


@media screen and (max-width:1024px) {


    .chat-panel__dialog-action .btn-square {
        height: 42px;
        width: 42px;
        min-width: 42px;
    }

    .modale-setup{
        &__body{
            .btn-square{
                width: 36px;
                height: 36px;
                min-width: 36px;
            }
        }
    }



}