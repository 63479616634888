.logotype{
    cursor: pointer;
    @include flexbox(row,center,center);
    gap: 1rem;
    svg{
        display: flex;
        transition: 0.24s ease;
        @include responsive-pictogram;
    }
}





@media screen and (max-width:800px) {

    .logotype{
        svg{
            animation: unset;
        }
    }

}