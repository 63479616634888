.custom-screen-frame{
    display:flex;
    width:100%;
    border:1px solid $border;
    img{
        aspect-ratio: 1.9;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

}


.visual-effect-box{
    @include flexbox(column);
    @include gap-large;
    &__body{
        display:flex;
    }

    &__footer{
         @include flexbox(column);
         gap:1rem;
        .btn-frame{
            @include flexbox(row,space-between,flex-start);
            // display: flex;
            // justify-content: space-between;
        }
        .pill-carousel{
            &__frame{
                display: flex;
                @include gap-responsive;
                overflow: hidden;
            }
        }
    }
}