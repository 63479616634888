.more-options-box{
    display: flex;
    flex-direction: column;
    position: relative;
    @include gap-responsive;
    z-index: 9;
    .random-color__pill{
        margin-top:0.6rem;
    }
    .upload-profile__box{
        @include flexbox(row,flex-start,center);
        @include gap-responsive;
    }
}