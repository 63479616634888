.tab-content{
    &__block{
        border: 2px solid red;
        @include basic-frame;   
        @include padding-responsive;   
        .progress-bar{
            @include flexbox(column);
            @include gap-responsive;
            position: relative;   
            padding: 1rem 0rem;
            &__legend{
                @include flexbox(row,space-between);
                &__right{
                    @include flexbox(column,flex-end);
                    text-align:end; 
                }
            }
            &__bar{
                position: relative;
            }
            &__grid{
                position: absolute;
                width: 100%;
                display:flex;
                justify-content: space-between;
                align-items: flex-end;
                height: 48px;
                font-size: 12px;
                div{
                    // border: 2px solid red;
                    display: flex;
                    color: $write-transp;
                }
            }
        }
    }

    .--more-options{
        // border: 1px solid red;
        .tab-content__body{
            @include flexbox(column,flex-start,flex-start);
            @include gap-responsive;
        }
        .input-frame{
            @include flexbox(column);
            @include gap-responsive;
            width: 100%;
            &__single{
                @include flexbox(column);
                gap:0.5rem;
            }
        }
        .check-frame{
            @include flexbox(column,flex-start);
            @include gap-responsive;
            &__option{
                @include flexbox(row,flex-start);
                @include gap-responsive;
            }
        }
    }
}

.--screen-layout{
    @include flexbox(column);
    @include gap-responsive;    
    .btn-grid{
        // border: 2px solid red;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:0.5rem;        
    }
    .tab-content__block{
        padding: 1rem 1rem 2rem;
    }
    .tab-content__frame{
        &.--top{
            // border: 2px solid purple;
            @include flexbox(column,center,center);
        }
        .grid-range{
            display: grid;
            grid-template-columns: 0.1fr 1fr 0.1fr;
            align-items: center;
            @include gap-responsive;
        }
    }
}