.video-box{
    // background-color:#d455;
    display: flex;
    @include bg-center-cover;
    // background-image: url(assets/pictures/portrait.jpg);
    min-height: 100%;   
    height: 100%;
    input{
        opacity: 0;
        width: 100%;
        height: 100%;
        @include flexbox(row,center,center);
        margin: auto;
    }

}

.macaroon-box{
    @include flexbox(row,center,center);
    height: 100%;
}

.prejoin{
    &__right-panel{
        &__video-frame{

            .option-layer{
                position: absolute;
                top: 0;
                width: 100%;
                @include flexbox(row,flex-end,flex-end);
                height: 100%;
                padding: 1rem;
                span{
                    padding:6px;
                }
                &:nth-of-type(2) {
                    align-items: flex-start;
                }
            }
        }
    }
}


