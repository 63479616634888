.chat-panel{
    .message__row{
        @include flexbox(row,flex-end,flex-end);
    }
    .message__box{
        border:1px solid $border;
        @include flexbox(column);
        padding: 15px;
        gap:10px;
        width: auto;
        max-width: 70%;
        &__author{
            color:$write-transp;
            @include flexbox(row,flex-start,center);
            gap: 5px;
            span{
                @include font-responsive;
            }
            .round-pill{
                span{
                    font-size: 10px;
                }
            }
        }
    }

    .message__box{
        &__body{
            @include flexbox(column,flex-start);
            @include gap-responsive;
        }
        &__heading{
            @include flexbox(row,flex-start,center);
            gap:0.5rem;
        }
        &__options{
            @include flexbox(column,flex-start);
            @include gap-responsive;
        }
        &__footer{
            @include flexbox(row,center,center);
            color:$write-transp;
            font-size: smaller;
        }
    }

    .message__row{
        gap: 0.5rem;
        .sending-time{
            span{
                font-size: 12px;
                color:$write-transp;
            }
        }
    }

    .message__row.--right{
        @include flexbox(row-reverse,flex-start,flex-end);
        .message__box{
            background-color: #fff2;
            border-radius: 16px 16px 4px 16px;  
        }
        .message__info{
            align-items: flex-end;
        }
    }

    .message__row.--right.--following{
        .message__box{
            border-radius: 16px 4px 4px 16px;
        }
    }

    .message__row.--right.--last{
        .message__box{
            border-radius: 16px 4px 16px 16px;
        }
    }

    .message__row.--left{
        justify-content: flex-start;
        .message__box{
            background-color: #fff1;
            border-radius: 16px 16px 16px 4px;
        }   
    }
    .message__row.--left.--following{
        .message__box{
            border-radius: 4px 16px 16px 4px;
        }
    }


    .message__row.--left.--last{
        .message__box{
            border-radius: 4px 16px 16px 16px;
        }
    }


    .message__info{
        @include flexbox(column,center,flex-start);
        display: none;
        .options{
            display: flex;
        }
    }

    .message__row.--sondage .message__box{
        background: linear-gradient(#72BBF116,#4B90E216);
    }

    .message__box.--sondage{
        min-width: 70%;
    }


    .message__row:hover .message__info{
        display: flex;
    }

}


@media screen and (max-width:1600px) {








}