
.navbar{
    position: absolute;
    display: flex;
    height: 100%;
    padding: 1.5rem;
    &__body{
        @include flexbox(column,center,center);
        justify-content: space-between;
        flex-direction: column;
        border: 1px solid $border-alt;
        box-shadow:$modal-shadow;
        border-radius: 16px;
        width: auto;
        background-color: #242129b8;
        height: 100%;
        padding: 2.5rem 1rem;
        width: min-content;
        .options{
            @include flexbox(column,center,center);
        }
    }
}

.language{
    li{
        color: white;
    }
}

@media screen and (max-width:1600px) {

    .navbar{
        padding: 1.25rem;
        &__body{
            padding: 2rem 0.75rem;
        }
    }

}



@media screen and (max-width:1024px) {

    .navbar{
        padding: 1rem;
    }


}




@media screen and (max-width:800px) {

    .navbar{
        // border: 4px solid red;
        width: 100%;
        height: auto;
        padding: 0;
        &__body{
            width: 100%;
            flex-direction: row;
            padding:1.5rem $padding-mobile;
            height: auto;
            border-radius: unset;   
            border: unset;
            background: unset;
            box-shadow: unset;
            .options{
                width: min-content;
                @include flexbox(column,center,center);
            }

        }
    }


}



@media screen and (min-width:1921px) {

    .navbar{
        &__body{
            padding:2.4vw 1vw;
            .logotype{
                svg{
                    width: 3.2vw;
                    height: 3.2vw;
                }
            }
        }
    }


}






