$screen-offset:0.75rem;

.devroom{
    background: $bg-gradient;
    position: relative;
    overflow: hidden;
    &__body{
        position: relative;
        @include flexbox(row,flex-start,flex-start);
        height: 100vh;
        flex-wrap: wrap;
        gap:0px 10px;
        padding: 10px;
        font-family: $font-primary;
        &__main{
            overflow: overlay;
            display: flex;
            height: calc(100vh - 90px - 12px);
            // border: 2px solid limegreen;
            min-height: 32vw;
            width: auto;
            flex:1;
            @include gap-responsive;
        }
        &__side-panel{
            display:flex;
            // flex-basis: 25%;
            width: 400px;
            min-width: 400px;
            height: calc(100vh - 90px - 12px);
        }
    }
    &__participant-panel{
        display: grid;
        grid-template-columns: 0.8fr 0.2fr;
        @include gap-responsive;
    }
    &__side-participants{
        border: 4px solid olivedrab;
    }
    &__featured-participant{
        border:4px solid #72BBF1;
        border-radius: 16px;
        overflow: hidden;
        &__box{
            display: flex;
            background-image: url('assets/pictures/portrait-peter.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            height: 100%;
            opacity: 0.72;
            width: 100%;
            input[type="file"]{
                display: flex;
                height: 100%;
                width: 100%;
                opacity: 0;
            }
        }
        &__display-layout{
            position: relative;
            top: -100%;
            height: 100%;
            display: flex;
            .top-left,.top-right,.bottom-left,.bottom-right{
                position: absolute;
            }
            .top-left{
                top:$screen-offset;
                left:$screen-offset;
            }
            .top-right{
                top:$screen-offset;
                right:$screen-offset;
            }
            .bottom-left{
                bottom: $screen-offset;
                left: $screen-offset;
            }
            .bottom-right{
                bottom: $screen-offset;
                right: $screen-offset;
            }
        }
    }
    &__option-panel{
        flex-basis: 100%;
        position: relative;
    }

}


.devroom{
    .test-cross{
        position: absolute;
        color:$write;
        left: 0.65rem;
        top: 0.65rem;
        // visibility: hidden;
        // opacity: 0;
        button{
            background: $button-gradient-opaque-hover;
            border: 1px solid $primary;
            display: flex;
            // cursor: pointer;
            &:hover{
                background: $button-gradient-opaque;
            }
        }
    }
}


@media screen and (max-width:1024px) {

    .devroom__body__side-panel {
        position: absolute;
        background: $bg-gradient;
        width: 100%;
        min-width: initial;
        height: calc(100vh - 144px);
        // min-height: 100vh;
        overflow: hidden;
        padding: 0rem;
        // border: 1px solid orange;
        border-radius: unset;
        top: 0;
        right: 0;
        z-index: 99;
        .chat-panel{
            border-radius: unset;
        }
    }


}


@media screen and (max-width:800px) {



    .devroom{
        &__body{
            padding:6px;
            // height: 100vh;
            height: calc(100vh - 148px);
            overflow: hidden;
            &__main{
                height: 100%;
                overflow: overlay;
            }
        }
    }



    .devroom{
        &__option-panel{
            // position: absolute;
            border: 1px solid $border-alt;
            border-left: unset;
            border-right: unset;
            border-bottom: transparent;
            position: fixed;
            bottom: 0;
            @include flexbox(column,center,center);
            min-height: 72px;
            @include gap-responsive;
            padding: 1rem;
            z-index: 99;
        }
    }


    .devroom__body__main.--flex-layout{
        flex-direction: column;
    }

    @supports (-webkit-touch-callout: none) {

        .devroom{
            &__body{
                overflow: overlay;
                // border: 1px solid blue;
                box-sizing: border-box;
                height: 80dvh;
                max-height: 80dvh;
                &__side-panel{
                    height: 100%;
                }   
            }
        }
        
        .devroom{
            &__option-panel{
                // border: 1px solid orange;
                min-height: 10dvh;
            }
        }

    }



}