.meeting-room-info{
    // border: 1px solid transparent;
    position: absolute;
    transform: translate(-36.5%, -110%) scale(0.2);
    min-width: 430px;
    padding: 15px;
    @include gap-responsive;
    @include flexbox(column);
    // background-image: linear-gradient(-180deg,),linear-gradient(90deg,#A64FFF,#6370E7);
    background-image: 
    linear-gradient(to bottom, #A64FFF, transparent, #6370E7),
    linear-gradient(110deg, #6370E7,#6370E7, #A64FFF);
    // url('chemin/vers/votre/image.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    // display: none;
    transition: 0.2s ease-in;
    transform-origin: top 160px;
    opacity: 0;
    z-index: 99;
    visibility: hidden;
    &__header{
        @include flexbox(row,space-between);
    }
    &__triangle{
        opacity: 0.99;
        transform: translate(160px, 100%);
        position: absolute;
        bottom: 1px;
        left: 0;
        >div{
            background: #6370E7;
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            // position: absolute;
            width: 16px;
            height: 10px;
        }
    }

}


.meeting-room-info.--show{
    opacity: 1;
    visibility: visible;
    transform: translate(-36.5%, -110%) scale(1);
}


@media screen and (max-width:1600px) {


    .meeting-room-info{
        min-width:340px;
    }

    .meeting-room-info.--show {
        transform: translate(-26.5%, -120%) scale(1);
    }

    .meeting-room-info__triangle {
        transform: translate(94px, 100%);
    }

}

@media screen and (max-width:800px) {

    .meeting-room-info{
        min-width: 330px;
        transform-origin:100px 200px;
    }

    .meeting-room-info__triangle{
        display: none;
    }

    
    .meeting-room-info.--show{
        transform: translate(-40%, 36%) scale(1);
    }

    .top-bar-mobile{
        .info-box{
            @include flexbox(row,flex-start,center);
            @include gap-responsive;
        }
        #meet-info{
            display: flex;
        }
    }

}


