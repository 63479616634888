.tab-content__block.--admin{
    .option-frame{
        // border: 2px solid red;
        @include flexbox(row,space-between);
        @include padding-responsive;
        @include gap-responsive;
        cursor: pointer;
        &:hover{
            background:linear-gradient(to left, #A64FFF10,#6370E710);
        }
        &__left{
            @include flexbox(row,flex-start,center);
            @include gap-responsive;
        }
        &__right{
            @include flexbox(row,center,center);
        }
    }
}

.tab-content .audio-video-box{
    .option-frame{
        @include flexbox(row);
        @include padding-responsive;
        @include gap-responsive;
    }
}