.prejoin{
    .greetings-box__mobile{
        display: none;
    }
}

@media screen and (max-width:800px) {

    .prejoin{
        &__left-panel{
            .greetings-box{
                display: none;
            }
        }
        .greetings-box__mobile{
            display: block;
        }
    }

}
