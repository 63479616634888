.prejoin{
    background: $bg-gradient;
    position: relative;
    overflow: hidden;
    &__body{
        // @include padding-responsive;
        position: relative;
        display: flex;
        height: 100vh;
        &__container{
            height: 100%;
            margin-left: 6.4%;
            width: 100%;
            display: grid;
            grid-template-columns: 0.35fr 0.65fr;
            gap:1rem;
            padding: 1.5rem;
            > div {
                opacity: 0;
            }
        }
    }
    &__left-panel{
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
        @include flexbox(column);
        @include basic-frame;
        gap:1rem;
        padding: 1.5rem;
    }
    &__right-panel{
        padding:0;
        gap:1rem;
        @include flexbox(column);
        &__video-frame{
            @include basic-frame;
            background-color: #242129c4;
            border-radius: 16px;
            flex-basis: 90%;
            overflow: hidden;
            position: relative;
        }
        &__action-frame{   
            flex-basis: 10%;
            @include flexbox(row,space-between,center);
            @include gap-responsive;
            .action-frame{
                @include flexbox(row,center,center);
                @include gap-responsive;
                flex-basis: 24%;
            }
            .btn-frame{
                flex-basis: 24%;
            }
            .empty-frame{
                flex-basis: 24%;
            }
        }
    }
}


@media screen and (max-width:1600px) {

    .prejoin{
        &__body{
            &__container{
                padding: 1.25rem;

            }
        }
    }
    
}


@media screen and (max-width:1400px) {

    .prejoin{
        &__right-panel{
            &__action-frame{
                .btn-frame{
                    flex-basis: 30%;
                }
            }
        }
    }
    
}


@media screen and (max-width:1200px) {

    .prejoin{
        &__body{
            height: auto;
            min-height: 100vh;
            &__container{
                grid-template-columns: 1fr;
                margin-left: 10%;
                padding: 1rem;
            }
        }
        &__right-panel{
            &__video-frame{
                min-height: 512px;
            }
        }
    }


}


@media screen and (max-width:800px) {

    .prejoin{
        overflow: auto;
        background: linear-gradient(-90deg,#292832,$dark);
        &__body{
            height: auto;
            min-height: 100vh;
            @include flexbox(column);
            &__container{
                margin-left: unset;
                grid-template-columns: 1fr;
                padding-top: 0;
                gap:unset;
                height: fit-content;
                grid-template-areas: 
                'a'
                'b';
            }
            .greetings-box__mobile{
                padding-top: 20%;
            }
        }
        &__left-panel,&__right-panel{
            box-shadow:unset;
        }
        &__left-panel{
            grid-area:b;
            padding:unset;
            height: max-content;
            background:unset;
            border-radius: 0;
            overflow-y: hidden;
            bottom:0;
            left:0;
            width: 100%;
            border:unset;
            padding: 1rem 0.5rem;
            z-index: 99;
        }
        &__right-panel{
            &__action-frame{
                @include flexbox(column-reverse,center,center);
                // justify-content: center;
                gap: 1rem;
            }
            // .btn-frame{
            //     display: none;
            // }
            .empty-frame{
                display:none;
            }
            &__video-frame{
                // min-height: -webkit-fill-available;
                min-height: 270px;
                max-height: 270px;
                height: 270px;
            }
        }
    }

}

@media screen and (min-width:1920px) {

    .prejoin__body__container{
        padding:2vw;
        gap:2vw;
    }

}