a{
    color:$write;
}

.prejoin{
    a{
        color:$write-transp;
        &:hover{
            color:$write;
        }
    }
}