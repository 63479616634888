label{
    @include font-responsive;
    font-family: $font-secondary;
    color:$write;
}

.prejoin{
    &__left-panel{
        .form__header{
            label{
                @include font-responsive;
                cursor: pointer;
            }
        }
    }
}

.preview-settings-modal{
    label{
        cursor: pointer;
        &::first-letter{
            text-transform: uppercase;
        }
    }
}

.check-frame label{
    cursor: pointer;
}


@media screen and (max-width:1600px) {

    .prejoin{
        &__left-panel{
            .form__header{
                label{
                    font-size: 13px;
                }
            }
        }
    }


}


@media screen and (max-width:800px) {

    .prejoin{
        &__left-panel{
            .form__header{
                label{
                    font-size: 12px;
                }
            }
        }
    }

    .preview-settings-modal{
        label{
            font-size: 11px;
        }
    }

}


@media screen and (min-width:1921px) {

    .prejoin{
        &__left-panel{
            .form__header{
                label{
                    font-size: 0.68vw;
                }
            }
        }
    }

}