.record-modal {
	@include basic-frame;
	border-radius: 8px;
	color: $write;
	top: -330px;
    left: calc(50% - 200px);
	min-height: 240px;
	width: 400px;
	padding: 1.5rem;
    z-index: 99;
	&__header {
		@include flexbox(row, space-between,center);
		.heading {
			@include flexbox(row, center, center);
			gap: 0.5rem;
		}
	}
	&__body{
		@include flexbox(column);
		gap:5px
	}
}

.record-modal.--end,
.record-modal.--start {
	position: absolute;
	@include flexbox(column);
	gap:40px;
}



@media screen and (max-width: 1600px) {
    
	.record-modal.--end,
	.record-modal.--start {
		padding: 1rem;
        top:-300px;
	}
}

@media screen and (max-width: 800px) {

	.record-modal.--end,
	.record-modal.--start {
		width: 340px;
		left: calc(50% - 170px);
		top: -310px;
	}

}
