.devroom{
    .chat-panel{
        width: 100%;
        color:$write;
        @include flexbox(column);
        @include basic-frame;
        @include gap-large;
        border-radius: 12px;
        overflow: hidden;
        padding:1rem;
        &__body{
            @include flexbox(column,space-between);
            @include gap-responsive;
            height: 100%;
        }
        &__searchbar{
            @include flexbox(column,center,center);
            width: 100%;
            gap:0.75rem;
            &__frame{
                gap:0.75rem;
                @include flexbox(row,space-between);
                width: 100%;
            }
        }
        &__option-panel{
            display:grid;
            width: 100%;
            grid-template-columns:1fr 1fr;
            @include gap-responsive;
        }
        &__dialog-frame{
            @include flexbox(column);
            // margin-bottom: 4rem;
            gap:0.25rem;
            position: relative;
            overflow: overlay;
            max-height: 60vh;
            justify-content: flex-end;
            height: 100%;
            // min-height: 520px;
        }
        &__dialog-action{
            width: 100%;
            display: flex;
            align-items: flex-end;
            @include gap-responsive;
            // position: sticky;
            position: relative;
            bottom: 0;
        }
        &__sending-file{
            border: 1px solid $border;
            background-color:$primary;
            border-radius: $button-radius;
            position: absolute;
            width: 100%;
            height: auto;
            bottom: 0;
            z-index: 99;
            padding:1rem;
            @include padding-responsive;
            @include flexbox(column);
            &__header{
                @include flexbox(row,space-between,center);
            }
            &__body{
                @include flexbox(column);
                gap:1.5rem;
            }
        }
    }
}

@media screen and (max-width:800px) {

    .devroom{
        .chat-panel{
            padding:1.5rem;
            border: unset;
            &__dialog-frame{
                // max-height: 40dvh;
                min-height: 400px;
                // border:2px solid red;
                // margin-bottom: unset;
            }
            &__dialog-action{
                // position: fixed;
                width: auto;
            }
        }
    }
    
    @supports (-webkit-touch-callout: none) {
        
        .devroom .chat-panel{
            // border:2px solid goldenrod;
            height: 79.5dvh;
            &__dialog-frame{
                min-height: auto;
            }
        }

    }


}