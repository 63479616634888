.message__box__content,
p,span{
    font-family: $font-secondary;
    @include font-responsive;
}

.meet-created-modal,
.share-modal,
.contacts-modal,
.prejoin,
.room-not-found,
.homepage{
    p{
        color: $write;
    }
}

.options__profil{
    p{
        line-height: 0;
        text-align: center;
    }
}


.advanced-settings{
    .form{
        &__row{
            .input__frame{
                p{
                    margin-block-start: 0;
                    margin-block-end: 0;
                    color:$write-transp;
                }
            }
        }
    }
}

.contacts-modal{
    p{
        margin-block-end: 0.25rem;
        margin-block-start: 0.25rem;
    }
    span{
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
        color:#fffb;
    }
}

.user-modal{
    p{
        font-family: $font-secondary;
        text-align: center;
        color: $write;
        line-height: 19px;
    }
    span{
        font-size: 12px;
        text-align: center;
        color: $write-transp;
        line-height: 16px;
    }
}

#text_video_meeting_link{
    color: $write-transp;
}

// navbar 

.options{
    cursor: pointer;
    span{
        font-size: 12px;
        text-align: center;
        color:$write;
    }
}

// prejoin 


.prejoin{
    &__left-panel__body,
    .greetings-box__mobile{
        p{
            text-align: center;
            @include font-responsive;  
        }
    }
}

.prejoin{
    .greetings-box,.greetings-box__mobile{
        p,.legend{
            color:$write-transp;
        }
    }

}

.macaroon-box{
    span{
        text-transform: uppercase;
        text-align: center;
        font-size: 6.4vw;
        font-family: $font-secondary;
        color:$write;
        letter-spacing: 0.56vw;
        line-height: 0;
    }
}

// preview settings modal

.visual-effect-box .pill-carousel__item{
    span{
        color:$write-transp;
        @include font-responsive;
        user-select: none;
    }
}

// screen room 

.devroom{
    &__mosaic{
        &__screen__overlay{
            span{
                font-family: $font-secondary-bold;
                @include font-responsive;
                text-transform: capitalize;
                text-shadow: 1px 0px 1px #000000c4,0px -1px 1px #00000048;
            }
        }
    }
}


.someone-raising-hand{
    span{
        font-family: $font-secondary-bold;
        font-size: 17px
    }
}


// chat panel 

.message__box__content{
    font-size: 15px;
    font-family: 'EmojiMart';
    letter-spacing: 0.016rem;
    white-space: pre-line;
    line-height: 20px;
}

.upload-frame__content__bottom{
    span{
        color:$write-transp;
    }
}

.progress-upload{
    .rate{
        color:#36CDCF;
    }
    .max-size{
        color:$write-transp;
    }
}


// participant panel 

.participant-list__item__label{
    .username::first-letter{
        text-transform: uppercase;
    }
}


// record modals 

.record-modal{
    p{
        color:$write-transp;
    }
}

.tab-content{
    .progress-bar p{
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 13px;
        color:$write-transp;
    }
}

.--screen-layout .tab-content__frame.--top{
    p{
        margin-block-end: 0.5rem;
        margin-block-start: 0.5rem;
    }
    p:nth-child(1){
        color:$write;
    }
    p:nth-child(2){
        color:$write-transp;
    }
}


.tab-content{
    .--more-options{
        span{
            color:$write-transp;
        }
    }
}




.devroom__option-panel{
    p,span{
        @include font-large;
    }
}


.devroom__option-panel .btn-record span{
    font-size: 15px;
}

.meeting-info-modale{
    span{
        @include font-responsive;
    }
}


.record-modal{
    p{
        margin-block: 0;
        font-size: 13px;
        text-align: justify;
    }
}


.devroom__option-panel__left .info-box span{
    font-size:18px;
}

// windows 

.all-settings__frame.--ms-settings .option-frame__label span{
    font-size: 14px;
}


.all-settings__frame.--ms-settings .option-frame__legend span{
    font-size: 12px;
}

@media screen and (max-width:1600px) {



    .options{
        &__avatar{
            span{
                font-size: 12px;

            }
        }
    }


    .options__profil{
        p{
            font-size: 13px;
        }
    }

}


@media screen and (max-width:1024px) {

    .macaroon-box{
        span{
            font-size: 64px;
        }
    }

    .options__profil{
        p{
            font-size: 12px;
        }
    }
    
}

@media screen and (max-width:800px) {

    .advanced-settings{
        .form__row{
            .input__frame{
                p{
                    font-size: 11px;
                }
            }
        }
    }

    .share-modal{
        p{
            margin-block-start: 0.25rem;
            margin-block-end: 0.25rem;
        }

        &__icon-list{
            .link-icon-wrapper,.link-icon{
                span{
                    font-size: 9px;
                    text-align: center;
                }
            }
        }
    }

    .devroom{
        .action-frame{
            &__mobile{
                &__options{
                    li{
                        cursor: pointer;
                        span{
                            color:$write-transp;
                            transition: 0.2s ease;
                            &:hover{
                                color: $write;
                            }
                        }
                        &:last-child{
                            color:#A64FFF;
                            span{
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }

    .participant-fixed-option-box .action-frame__mobile__options li:last-child span{
        font-size: 13px;
    }


    .options{
        &__avatar{
            span{
                font-size: 10px;
            }
        }
    }
}


.link-icon{
    span{
        color: $write-transp;
    }
}


.myselect__legend span,.myselect__bar__title{
    @include font-responsive;
}


@media screen and (min-width:1920px) {


    .macaroon-box{
        span{
            font-size: 5.6vw;
        }
    }
    
}