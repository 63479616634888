.stats-panel{
    &__participant-list{
        // border: 2px solid purple;
        table{
            // border: 2px solid red;
            border-spacing: 0 0.32rem;
            width: 100%;
            tr td{
                @include padding-responsive;
            }
            thead{
                tr{
                    td{
                        padding:0.5rem 0;
                        color:$write-transp;
                    }
                    td{
                        &:nth-child(2){
                            // border: 2px solid orange;
                            text-align: end;
                            width: 40%;
                        }
                        &::first-letter{
                            text-transform: uppercase;
                        }
                    }
                }
            }
            tbody{
                gap: 0.5rem;
                tr{
                    td{
                        background-color: #ffffff16;
                        &:nth-child(1){
                            border-radius: 6px 0 0 6px;
                            border:1px solid $border-alt;
                            border-right: unset;
                        }
                        &:last-child{
                            border-radius: 0px 6px 6px 0;
                            border:1px solid $border-alt;
                            border-left: unset;
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
}