// global 

.form{
    &__row{
        @include flexbox(row,flex-start,flex-start);
        gap:1rem;
        .input-frame{
            textarea{
                width: 100%;
            }
        }
        &__block{
            height: 100%;
            @include flexbox(row,center,center);
        }
        &__icon{
            padding-top: 6px;
        }
    }
}



.form{
    &__body{
        .input-frame{
            background: $input-gradient;
            font-family: $font-secondary;
            border-radius: 6px;
            border:1px solid $border-alt;
        }
    }
}

// specific 


#guest-row.form__row{
    align-items: center;
}

.homepage,.prejoin{
    .form{
        &__body{
            .input-frame{
                @include flexbox(row,flex-start,center);
                height: 48px;
                width: 440px;
            }
        }
    }
}

.prejoin{
    .form{
        &__body{
            .input-frame{
                width: 100%;
            }
        }
    }
}


@media screen and (min-width:1920px) {

    .form{
        &__row{
            gap:1vw;
        }
    }

}