.devroom{
    &__mosaic{
        &__screen{

            @include flexbox(row,center,center);
            margin: 0rem;
            padding: 0;
            height: max-content;
            height: -moz-available;
            aspect-ratio: 16/9;
            width: 100%;
            background-color: $primary;
            border-radius: 10px;
            overflow: hidden;

            @-moz-document url-prefix() {
                overflow: initial;
            }
            position: relative;
            user-select: none;
            .portrait{
                width: 100%;
                display: flex;
                height: 100%;
                margin: 0;
                margin-inline: unset;
                overflow: hidden;
                img{
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                }
            }
            &__overlay{
                position: absolute;
                width: 100%;
                height: 100%;
                justify-content: flex-end;
                display: flex;
                color: $write;
                @include padding-responsive;
            }
            &__overlay.--bottom{
                align-items: flex-end;
                margin: auto auto 0;
                bottom: 0;
                height: auto;
                .container{
                    justify-content: space-between;
                    display: flex;
                    width: 100%;
                    div{
                        @include flexbox(row,center,center);
                        @include gap-responsive;
                    }
                }
            }
        }

        &__screen.--talking{
            border:4px solid #72BBF1;
        }
    }
}



.featured-frame{
    .devroom__mosaic__screen{
        overflow: hidden;
        max-height: -webkit-fill-available;
        border:4px solid #72f1c0;
        height: 100%;
    }
}


@media screen and (max-width:800px) {



    .featured-frame{
        .devroom__mosaic__screen{
            height: 100%;
            max-height: -webkit-fill-available;
            min-height: 100%;
            border-width:3px;
        }
    }
    
    .devroom{
        &__mosaic{
            &__screen.--talking{
                border-width:3px;
            }
            &__screen{
                .portrait{
                    img{
                        border-radius: 6px;
                    }
                }
            }
        }
    }

}