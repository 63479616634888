
.share-modal{
    opacity: 0;
    visibility: hidden;
    &__heading{
        @include flexbox(row,space-between,center);
    }


    &__link{
        display: flex;
        @include padding-responsive;
        @include gap-responsive;
        backdrop-filter: brightness(0.92) contrast(0.92);
        border: 1px solid $border;
        border-radius: 6.4px;        
        &__content{
            width: 100%;
            @include flexbox(row,space-between,center);
        }
    }


}

.share-modal{
    &__container{
        transform-origin: center;
        transform: translateY(25%) scale(0.8);
        transition: 0.24s linear;
    }
}


.share-modal.show{
    opacity: 1;
    visibility: visible;
}


.share-modal.show .share-modal__container{
    transform: scale(1) translateY(0%);
}

.share-modal{
    &__link{
        &__img{
            width: 56px;
        }
    }
    &__icon-list{
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(4, 1fr);
        @include gap-responsive;

        .link-icon-wrapper{
            position: relative;
        }
        .link-icon{
            @include flexbox(column,flex-start,center);
            @include gap-responsive;
            padding: 1rem;
            min-width: 180px;
            cursor: pointer;
            background: linear-gradient(#22303920,#3A365520);
            border-radius: $button-radius;
            border: 1px solid $border;
            &:hover{
                background: linear-gradient(180deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
                border: 1px solid;
                border-image-slice: 1;
                border-image-source: linear-gradient(to left, #823CCA, #4C57BB);
            }
        }
        .link-icon.is-disabled{
            opacity: 0.3;
            &:hover {
                background: gray 0.2;
                border: 1px solid;
                border-image-slice: 1;
                border-image-source: linear-gradient(to left, #823CCA, #4C57BB);
            }
        }
        .link-icon-info{
            cursor: not-allowed;
            text-align: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }
}


.share-modal__container.large-modal__container{
    padding: 2.5rem;
}


@media screen and (max-width:1600px) {

    .share-modal{

        &__icon-list{
            .link-icon{
                min-width: 160px;
            }
        }
    }

    .share-modal__container.large-modal__container{
        padding: 2rem;
    }
}


@media screen and (max-width:800px) {

    .share-modal{
        align-items: flex-end;
        &__container{
            width: 100%;
            border: 1px solid $border;
            border-radius: 20px 20px 0px 0px;        
        }
        &__heading{
            display: none;
        }
        &__icon-list{
            grid-template-columns: repeat(4,1fr);
            .link-icon{
                min-width: 72px;
                justify-content: space-between;
                gap: 0.5rem;
                padding:0.5rem;
            }
        }
        
        &__link{
            &__img{
                width: 40px;
            }
        }
    }
    .share-modal.large-modal{
        align-items: flex-end;
    }

    .share-modal__container.large-modal__container{
        border-radius:16px 16px 0px 0px;
        padding: 1rem;
    }
}


@media screen and (min-width:1921px) {

    .share-modal__icon-list .link-icon {
        min-width: 7.2vw;
    }
}
