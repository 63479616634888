ul li{
    font-family: $font-secondary;
    @include font-responsive;
}

ul{
    padding-inline-start: 0;
    margin-block-end: 0;
    margin-block-start: 0;
}


.participant-option-box ul,
.participant-panel__frame ul,
.participant-list__more-options{
    // padding-inline-start: inherit;
    list-style-type: none;
    @include flexbox(column);
    @include padding-responsive;
    gap:10px;
    li:not(.disabled){
        color:$write;
        cursor: pointer;
        &:hover{
            color:$write-transp;
        }
    }
}

.participant-option-box ul{
    gap: 0.65rem;
}


ul .disabled{
    color:$write-transp-max;
}