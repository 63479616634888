
.user-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $border;
    border: 1px solid $border-alt;
    border-radius: $button-radius;
    padding:0.5rem;
    &__profil{
        @include flexbox(row,center,center);
        @include gap-responsive;
        &__picture{
            border-radius: 50%;
        }
    }
    &__is-to-add {
        background: $border-modal;
    }
    &__is-added{
        background: $green;
    }
}


@media screen and (max-width:800px) {

    .user-item__operate{
        background-color: plum;
        display: none;
    }
}

