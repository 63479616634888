.chat-panel{
    &__upload-frame{
        position: absolute;
        top: -440px;
        height: auto;
        width: 100%;
        @include flexbox(row,flex-end,flex-end);
        &__body{
            height: 100%;
            width: 100%;
            @include flexbox(column,center);
            @include gap-responsive;
            @include basic-frame;
            @include large-padding;
        }
    }
    .upload-frame{
        &__header{
            @include flexbox(row,space-between);
        }
        &__content{
            flex:1;
            border: 2px dashed #fefefe56;
            border-radius: 8px;
            gap:2rem;
            padding:2rem;
            @include flexbox(column,center,center);
            &__top{
                @include flexbox(column,center,center);
                @include gap-large;
            }
            &__bottom{
                @include flexbox(column,center,center);
                gap:2rem;
            }
        }
    }
}


@media screen and (max-width:800px) {


    .chat-panel{
        &__upload-frame{
            top: -400px;
        }
    }

}