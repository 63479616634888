@mixin flexbox($direction: row, $justify: flex-start, $align: stretch) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}
  


@mixin font-primary-regular-white{
    color:#fffd;    
    font-weight: initial;
    font-family: $font-primary;
}

@mixin font-primary-light-white{
    color:#fffd;    
    font-weight: initial;
    font-family: $font-primary-light;
}

@mixin padding-responsive{

    padding:0.75rem;
    

    @media screen and (max-width:1024px) {        
        padding:0.55rem;
    }
}


@mixin gap-large{

    gap:20px;
    
    @media screen and (max-width:800px) {        
        gap:10px;
    }
}

@mixin large-padding{

    padding: 1.5rem;
    
    @media screen and (max-width:1600px) {        
        padding:1.2rem;
    }

    @media screen and (max-width:1200px) {        
        padding:0.9rem;
    }
}

@mixin font-responsive{

    font-size:$default-size;
    
    
    @media screen and (max-width:800px) {        
        font-size:$mobile-size;
    }

}


@mixin font-large{
    font-size:18px;

    @media screen and (max-width:800px) {        
        font-size:13px;
    }
}

@mixin font-message-responsive{

    font-size:17px;
        
    @media screen and (max-width:1024px) {        
        font-size:16px;
    }

    @media screen and (min-width:1921px) {        
        font-size:0.74vw;
    }
}

@mixin font-small-responsive{

    font-size:15px;
    
    @media screen and (max-width:1600px) {
        font-size:14px;
    }
    
    
    @media screen and (max-width:1024px) {        
        font-size:13px;
    }

    @media screen and (max-width:800px) {        
        font-size:12px;
    }

    @media screen and (min-width:1921px) {        
        font-size:0.54vw;
    }
}


@mixin gap-responsive{

    gap:10px;

    
    @media screen and (max-width:800px) {

        gap:5px;

    }

}


@mixin responsive-pictogram{

    width: 64px;
    height: 64px;
    aspect-ratio: 1/1;

    @media screen and (max-width:1600px) {

        width: 52px;
        height: 52px;
    }

    @media screen and (max-width:1024px) {

        width: 48px;
        height: 48px;

    }


    @media screen and (max-width:800px) {

        width: 40px;
        height: 40px;

    }

    @media screen and (min-width:1921px) {

        width: 3.2vw;
        height: 3.2vw;

    }

}


@mixin basic-frame{
    border:1px solid $border-alt;
    box-shadow:$modal-shadow;
    border-radius: 10px;
    background: $primary;
}

// boutons 

@mixin basic-btn{
    background: $button-gradient;
    border-radius: $button-radius;
    @include padding-responsive;
    @include font-responsive;
    border:unset;
    &:hover{
        background: $button-gradient-hover;
    }
}


@mixin glassmorphism{
    background: rgba(36, 33, 41, 0.9);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.2px);
    -webkit-backdrop-filter: blur(4.2px);
}

// background 

@mixin bg-center-cover{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}