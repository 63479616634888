.audio-video-box,.visual-effect-box,.more-options-box{
    color:$write;
    font-family: $font-secondary;
}

.audio-video-box{
    @include flexbox(column);
    @include gap-responsive;
    .screen-frame{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include gap-responsive;
        // &__left{
            .video-box{
                min-height: 180px;
                aspect-ratio: auto;
            
            }
        // }
        &__right{
            .video-box{
                background: unset;
                background-color: $dark;
                border:1px solid $border;
                @include flexbox(column,center,center);
                position: relative;
                &::after{
                    content: '';
                    // background-color: red;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    opacity: 0.6;
                    background-image: url(assets/icons/headphones.svg);
                    @include bg-center-cover;
                }
            }
        }
    }
}


@media screen and (max-width:1600px) {


    .audio-video-box{
        .screen-frame{
            .video-box{
                min-height: 170px;
            }
        }
    }

}



@media screen and (max-width:800px) {


    .preview-settings-modal{
        &__body{
            &__right{
                .screen-frame .video-box {
                    min-height: 24vw;
                    width: auto;
                }
            }
        }
    }


}