// global style

button {
	cursor: pointer;
	transition: 0.2s ease;
	@include font-responsive;
	font-family: $font-secondary;
	color: $write;
	user-select: none;
	&:active {
		transform: scale(0.96);
	}
}

.btn-frame button {
	@include font-large;
}

.btn-icon,
.btn-square,
.btn-regular {
	border-radius: $button-radius;
	border: unset;
	img {
		width: 24px;
		height: 24px;
	}
}

.btn-regular {
	@include flexbox(row, center, center);
	height: 50px;
	background: $button-gradient-opaque;
	width: 100%;
	&:hover {
		background: $button-gradient-opaque-hover;
	}
}

.action-frame__mobile .btn-square,
.action-frame__mobile .btn-icon__usergroup,
.laptop-options .btn-square,
.action-frame .btn-square,
.action-frame .btn-icon__usergroup {
	border-radius: 20px;
	background: linear-gradient(-120deg, #3a365580, #22303980 100%);
	border: 1px solid $border;
	box-shadow: $modal-shadow;
	overflow: visible;
	position: relative;
	height: 52px;
	width: 52px;
	min-width: 52px;
	&:hover {
		border: 1px solid $border;
		background: $pill-gradient;
		img {
			transform: scale(1.25);
		}
	}
	img {
		transition: 0.2s linear all;
		z-index: 9;
	}
}



.preview-settings-modal__footer {
	.btn-regular {
		@include padding-responsive;
	}
}

.btn-duo-frame {
	display: grid;
	grid-template-columns: 1fr 1fr;
	@include gap-responsive;
	button {
		height: 55px;
	}
	button,
	button span {
		font-size: 18px;
	}
}

.btn-duo-frame {
	button {
		&:nth-child(1) {
			background: $button-gradient;
			&:hover {
				background: $button-gradient-hover;
			}
		}
		&:nth-child(2) {
			background: $button-gradient-opaque;
			&:hover {
				background: $button-gradient-opaque-hover;
			}
		}
	}
}

.duo-tab,
.room-not-found,
.prejoin {
	.btn-frame {
		.btn-icon {
			background: linear-gradient(20deg, #3a365556, #22303956 100%);
			box-shadow: $button-shadow;
			border: 1px solid $border-alt;
			border-radius: $button-radius;
			@include flexbox(row, flex-start, center);
			height: 50px;
			padding: 0 10px;
			@include gap-responsive;
			&:hover {
				box-shadow: unset;
			}
		}
	}
}

.duo-tab {
	.btn-frame {
		.btn-icon {
			color: $write-transp;
			&:hover {
				color: $write;
				border: 1px solid #8d5cf6a4;
				background: $button-gradient;
				img {
					opacity: 1;
				}
			}
		}
		img {
			opacity: 0.4;
		}
	}
}

.duo-tab,
.preview-settings-modal {
	.btn-frame {
		.btn-icon.active {
			background: $button-gradient;
			color: $write;
			border: 1px solid #8d5cf6a4;
			img {
				opacity: 1;
			}
		}
	}
}



.user-modal {
	.btn-regular {
		@include flexbox(row, center, center);
		@include padding-responsive;
		background: $button-gradient-opaque;
		width: auto;
		&:hover {
			background: $button-gradient-opaque-hover;
		}
	}
}

.homepage {
	&__body {
		.button-frame {
			button {
				padding: 1rem 1.5rem;
				gap: 1rem;
				@include flexbox(row, center, center);
			}
		}
	}
}


// room bottom panel

.devroom__option-panel .action-frame button:hover img {
	filter: brightness(5);
}


.btn-record {
	height: 52px;
	min-width: 172px;
	border: unset;
	border-radius: 20px;
	@include flexbox(row, center, center);
	background: linear-gradient(-120deg, #823cca, #4c57bb);
	overflow: hidden;
	transform-origin: center;
	transition: 0.1s linear;
	cursor: pointer;
	&:hover {
		background: linear-gradient(-120deg, #823cca48, #4c57bb48);
	}
	&:active {
		transform: scale(0.92);
	}
	z-index: 1;
	&__content {
		height: 50px;
		min-width: 170px;
		padding: 0 15px;
		background: linear-gradient(-60deg, #3a365596, #22303996 100%);
		@include flexbox(row, center, center);
		gap: 0.5rem;
		border-radius: inherit;
		color: $write;
		position: relative;
		#stop-btn,
		#pause-btn {
			cursor: pointer;
			transform-origin: center;
			display: flex;
			transition: 0.1s;
			&:hover {
				transform: scale(1.1);
			}
		}
		#pause-btn {
			border-radius: unset;
		}
	}
	#record-notif {
		width: 10px;
		height: 10px;
		background: $red-gradient;
		border-radius: 50%;
	}
}

.btn-square.--filters.active,
.action-frame .active {
	background: linear-gradient(45deg, #6e30ad40, #444ea540);
	border: 1px solid #8d5cf6a4;
}

// modal buttons 

.contacts-modal {
	&__button-frame-top,
	&__button-frame-bottom {
		@include flexbox(row, space-between, center);
		@include gap-responsive;

		.btn-icon {
			color: $write;
			background: $border-modal;
			border: 1px solid $border;
			border-radius: $button-radius;
			border-image-slice: 1;
			border-image-source: linear-gradient($border-alt, $border-alt);
			width: 100%;
			&__is-selected,
			&:hover {
				background: linear-gradient(180deg, #6e30ad40, #444ea540);
				border: 1px solid;
				border-image-slice: 1;
				border-image-source: linear-gradient(to left, #823cca, #4c57bb);
			}
			&__background_btn {
				background: $button-gradient;
			}
		}

		button {
			@include padding-responsive;
		}
	}
}


.preview-send-anim-modale .btn-icon {
	@include gap-responsive;
	@include flexbox(row, center, center);
}

.meeting-info-modale .btn-icon {
	padding: 10px 15px;
}

.preview-settings-modal {
	&__body {
		&__left {
			.btn-icon {
				img {
					opacity: 0.5;
				}
			}
			.btn-icon.active {
				img {
					opacity: 1;
				}
			}
		}
	}
}

// special buttons 

.action-frame #camera-icon.btn-square.camera-active,
.action-frame #camera-icon.btn-square:hover {
	background: $red-gradient-transp;
	border-color: $border-red;
}


.action-frame {
	.record-container {
		position: relative;
	}
}


.action-frame__mobile,
.action-frame {
	.btn-icon__usergroup {
		@include flexbox(row, center, center);
		@include gap-responsive;
		padding: 0px 15px;
		width: auto;
		min-width: 80px;
		justify-content: center;
		&:hover {
			background: $pill-gradient;
		}
	}
}


// option panel buttons 



.chat-panel {
	&__option-panel {
		.btn-icon {
			@include flexbox(row, center, center);
			background: $input-gradient;
			border: 1px solid $border-alt;
			padding: 0.5rem;
		}
	}
}

.chat-panel__option-panel .btn-icon__active,
.btn-square.active {
	background: linear-gradient(45deg, #6e30ad40, #444ea540);
	border:1px solid #8d5cf6a4;
	img {
		opacity: 1;
	}
}

.external-box{
    .btn-regular {
        width: 100%;
        background: $button-gradient-opaque-hover;
    }
    .btn-icon {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        background: $button-gradient-opaque;
        height: 30px;
        width: 30px;
        @include flexbox(row, center, center);
    }
}



#test-auth-btn {
	position: absolute;
	@include basic-btn;
	background: $green;
	font-size: 12px;
	border-radius: unset;
	padding: 0.32rem;
	width: 120px;
	left: 0;
	top: 0;
	&:hover {
		background: $green;
		filter: brightness(2);
		text-decoration: underline;
	}
}

// boutons prejoin

.prejoin {
	.notif-box {
		.form {
			.btn-regular.not-ready {
				pointer-events: none;
				background: #ffffff15;
				height: 48px;
				span {
					&::first-letter {
						text-transform: uppercase;
					}
				}
				&:hover {
					background: $border-modal;
				}
			}
			.btn-regular.active {
				background: $pill-gradient;
			}
		}
	}
}

.prejoin {
	#test-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		span {
			width: 100%;
			background: $green;
			border: unset;
			text-align: center;
			border-radius: unset;
			padding: 6px;
			@include font-responsive;
			box-shadow: unset;
		}
	}
}

#upload-image-preview-settings {
	@include flexbox(row, center, center);
	@include gap-responsive;
	padding: 0;
	border: unset;
	background: unset;
}



// boutons mosaic screen

.btn-icon__raise-hand {
	background: linear-gradient(to right, #242129b3 60%, #443f4db3 100%);
	@include flexbox(row, center, center);
	@include padding-responsive;
	@include gap-responsive;
}



.participant-request {
	&__footer {
		.btn-frame {
			button:nth-child(1) {
				background: linear-gradient(90deg, #a64fff32, #6370e732);
				&:hover {
					background: linear-gradient(90deg, #a64fff80, #6370e780);
				}
			}
		}
	}
}

.btn-check {
	border: 1px solid $border-alt;
	border-radius: $button-radius;
	display: flex;
	background: #242129b3;
	@include padding-responsive;
	@include gap-responsive;
	color: $write-transp;
	text-transform: capitalize;
	@include flexbox(row, space-between, center);
	&:hover {
		border: 1px solid transparent;
		// border-image-slice: 1;
		background: linear-gradient(
			-120deg,
			rgba(166, 79, 255, 0.2509803922),
			rgba(99, 112, 231, 0.2509803922)
		);
		color: $write;
	}
	&__left {
		@include flexbox(row, flex-start, center);
		@include gap-responsive;
	}
}

.btn-check.active {
	border: 1px solid #8d5cf6a4;
	background: linear-gradient(
		-120deg,
		rgba(166, 79, 255, 0.32),
		rgba(99, 112, 231, 0.32)
	);
	color: $write;
	img {
		opacity: 1;
	}
}

#open-shortcuts {
	background: -webkit-linear-gradient(-20deg, #a64fff, #6370e7);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	border: unset;
	border-bottom: 1px solid transparent;
	padding: 0;
	&:hover {
		border-bottom: 1px solid #6370e7;
	}
}



@media screen and (max-width: 1600px) {


	.btn-icon,
	.btn-square,
	.btn-regular {
		img {
			width: 22px;
			height: 22px;
		}
	}

	.btn-record {
		height: 54px;
		&__content {
			height: 52px;
		}
	}

	.action-frame__mobile .btn-square,
	.action-frame__mobile .btn-icon__usergroup,
	.action-frame .btn-square,
	.action-frame .btn-icon__usergroup {
		height: 54px;
		width: 54px;
		min-width: 54px;
		border-radius: 22px;
	}

	.--ms-panel .action-frame__mobile .btn-square,
	.--ms-panel .action-frame__mobile .btn-icon__usergroup,
	.--ms-panel .action-frame .btn-square,
	.--ms-panel.action-frame .btn-icon__usergroup {
		height: 46px;
		width: 46px;
		min-width: 46px;
		border-radius: 20px;
	}

	.action-frame .btn-icon__usergroup {
		min-width: 80px;
	}

}

@media screen and (max-width: 1024px) {
	.modale-setup {
		&__body {
			&__frame:nth-child(4) {
				.btn-regular {
					padding: 0.75rem 0.25rem;
				}
			}
		}
	}

	.homepage {
		&__body {
			.button-frame {
				button {
					padding: 0.65rem;
				}
			}
		}
	}

	.prejoin {
		.notif-box {
			.form {
				.btn-regular {
					padding: 1rem 1.5rem;
				}
			}
		}
	}

	.modale-setup .btn-regular.create-meeting {
		padding: 0.75rem;
	}

	.btn-regular {
		padding: 0.65rem;
	}

	.action-frame__mobile .btn-square,
	.action-frame__mobile .btn-icon__usergroup,
	.action-frame .btn-square,
	.action-frame .btn-icon__usergroup {
		height: 50px;
		width: 50px;
		min-width: 50px;
	}
}

@media screen and (max-width: 800px) {
	.prejoin {
		#test-btn {
			position: fixed;
			top: unset;
			bottom: 0;
			right: 0;
			button {
				z-index: 999;
			}
		}
	}

	.btn-regular {
		padding: 0.55rem;
	}

	.action-frame__mobile .btn-icon__usergroup {
		min-width: 80px;
	}

	.btn-record,
	.btn-record__content {
		height: 44px;
	}

	.btn-duo-frame .button-frame{
		font-size: 16px;
	}
	
	.btn-duo-frame button{
		height: 50px;
	}
}
