.input-link.input-frame{
    position: relative;
    ::placeholder{
        color: $write-transp;
    }
    .icon-frame{
        position: absolute;
        top: 0;
        width: auto;
        height: 100%;
        gap:0.5rem;
        right: 0;
        @include flexbox(row,flex-end,center);
        @include padding-responsive;
    }
    textarea{
        width: 100%;
        padding-right: 5rem;
        letter-spacing: 0.02rem;
    }
}
.input-link.input-frame.input-textarea{
    display: flex;
    .icon-frame{
        align-items: flex-end;
    }
}




.input-frame__input{
    width: 100%;
}


@media screen and (max-width:800px) {

    .input-link.input-frame .icon-frame{
        img{
            width: auto;
        }
    }

}