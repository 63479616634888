// colors 

$primary: #242129;
$dark: #212120;
$write:#fffe;
$write-transp:#fff8;
$write-transp-max:#fff4;


// gradient 

$btn-gradient:linear-gradient(-120deg, #A64FFF 13.05%, #6370E7 81.16%);
$bg-gradient : linear-gradient(-130deg,#5F3C7A -20%,#3B3656 16%,#292832 72%,$dark 100%);
$input-gradient : linear-gradient(-60deg,#3A365548,#22303964 100%);
$button-gradient: linear-gradient(-120deg,#A64FFF40,#6370E740);
$button-gradient-hover: linear-gradient(45deg,#6E30AD40,#444EA540);
$green:linear-gradient(135deg, rgba(54, 205, 207, 0.5) 0%, rgba(64, 224, 207, 0.5) 100%);
$ringover-gradient:linear-gradient(#36CDCF, #40E0CF);


$button-gradient-opaque: linear-gradient(-120deg,#A64FFF,#6370E7);
$button-gradient-opaque-hover: linear-gradient(-120deg,#823CCA,#4C57BB);
$pill-gradient:linear-gradient(45deg,#A64FFF,#6370E7);

$red-gradient:linear-gradient(#FF8888,#F05F5F);
$red-gradient-transp:linear-gradient(45deg,#FF888832,#F05F5F32);

$gold-gradient-transp:linear-gradient(-120deg,#FFCF4F30,#FF9B4A30);

// gaps 

$gap-desktop:1rem;
$padding-mobile:1.5rem;

// radius 

$button-radius:5px;
$screen-radius:10px;

// borders 

$border:#ffffff16;
$border-alt:#ffffff10;
$border-modal:#ffffff08;
$border-red:#F05F5FA4;
$border-gold:#FFCF4Fc4;


// shadow 

$modal-shadow:0px 0px 1px 0px #00000012,0px 2px 1px 0px #00000012,0px 2px 2px 1px #00000016;
$button-shadow:1px 1px 1px 1px #00000012,0px 1px 2px 2px #00000016;


// font primary

$font-primary-light : 'Poppins Light',sans-serif;
$font-primary : 'Poppins Regular',sans-serif;

// font secondary 

$font-secondary:'Open Sans Regular',sans-serif;
$font-secondary-bold:'Open Sans Bold',sans-serif;
$font-secondary-extrabold:'Open Sans ExtraBold',sans-serif;

// font-sizes 

$default-size: 14px;
$mobile-size: 13px;


