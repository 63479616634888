

@keyframes rotatingCircles {

    from{
        transform: scale(1);
        opacity: 0.6;
    }
    to{
        transform: scale(1) rotate(360deg);
        opacity: 1;
    }

}

@keyframes rotatingScalingCircle {

    from{
        transform: scale(0.8);
        opacity: 0.5;
    }
    1%{
        transform: scale(1) rotate(360deg);
        opacity: 1;
    }
    100%{
        transform: scale(1) rotate(360deg);
    }

}

@keyframes logoRotate {
    0% {
      transform: rotate(0) scale(1.2);
    }
    50% {
      transform: scale(0.9);
    }
    75% {
      transform: scale(0.8)  rotate(-45deg);
    }
    100% {
      transform:scale(1.2) rotate(0deg);
    }
}


@keyframes textclip {
  from{
      background-position: initial;
  }
  50% {
      background-position: 200% center;
  }
  100%{
      background-position: initial;
  }
}

@keyframes pulse {
    from{
        transform: scale(1);
    }
    25%{
        transform: scale(1.2);
    }
    50%{
        transform: scale(1);
    }
    75%{
        transform: scale(0.8);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes rotateThumbs {
    from{
        transform: rotate(0deg);
        opacity: 0.75;
    }
    25%{
        transform: rotate(-10deg) scale(2);
        opacity: 0.95;
    }
    40%{
        transform: rotate(0deg) translateY(-400px) scale(3);
        opacity: 1;
    }
    45%{
        transform: rotate(-1deg) translateY(-400px) scale(4);
    }
    50%{
        transform: rotate(1deg) translateY(-400px) scale(3);
    }
    75%{
        transform: rotate(-10deg) scale(2);
    }
    100%{
        transform: rotate(0deg) scale(0);
    }

}

@keyframes clappingHands {
    from{
        transform: rotate(0deg);
        opacity: 0.75;
    }
    25%{
        transform: rotate(-10deg) scale(1);
        opacity: 0.95;
    }
    40%{
        transform: rotate(0deg) translateY(-250px) scale(2);
        opacity: 1;
    }
    45%{
        transform: rotate(-1deg) translateY(-275px) scale(3);
    }
    50%{
        transform: rotate(1deg) translateY(-250px) scale(2);
    }
    55%{
        transform: rotate(1deg) translateY(-260px) scale(2.25);
    }
    60%{
        transform: rotate(1deg) translateY(-250px) scale(2);
    }
    65%{
        transform: rotate(1deg) translateY(-260px) scale(2.25);
    }
    70%{
        transform: rotate(1deg) translateY(-250px) scale(2);
    }
    75%{
        transform: rotate(-10deg) scale(1);
    }
    100%{
        transform: rotate(0deg) scale(0);
    }

}
@keyframes thumbsDown {
    from{
        transform: rotate(0deg) translateY(-400px);
        opacity: 0.75;
    }
    25%{
        transform: rotate(-10deg) scale(2);
        opacity: 0.95;
    }
    40%{
        transform: rotate(0deg) scale(2.5);
        opacity: 1;
    }
    45%{
        transform: rotate(-1deg) scale(3);
    }
    50%{
        transform: rotate(1deg) translateY(0px) scale(2);
    }
    75%{
        transform: rotate(-10deg) scale(1);
        opacity: 0.75;
    }
    100%{
        transform: rotate(0deg) scale(0);
        opacity: 0.5;
    }

}