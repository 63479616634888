.participant-request{
    // position: absolute;
    font-family: $font-secondary;
    border: 2px solid $border-alt;
    border-radius: $screen-radius;
    @include glassmorphism;
    background: rgba(36, 33, 41, 0.9);
    @include flexbox(column);
    min-width: 340px;
    padding: 1rem;
    gap: 1rem;
    &__header{
        @include flexbox(row,space-between,center);
        @include gap-responsive;
    }
    &__header.--left{
        @include flexbox(row,flex-start,center);
        gap:0;
    }
    
    .myselect{
        // border: 1px solid red;
        @include flexbox(column,flex-start);
        gap:0.25rem;
        color:$write;
        &__bar{
            @include flexbox(column,flex-start,center);
            @include gap-responsive;
            @include padding-responsive;
            border: 1px solid $border-alt;
            border-radius: $button-radius;
            background:$write-transp-max;
            box-shadow: $modal-shadow;
            cursor: pointer; 
            &:hover{
                background:#fff2; 
            }
            &__content{
                @include flexbox(row,flex-start,center);
                @include gap-responsive;
                width: 100%;
            }
            &__header{
                @include flexbox(row);
                width: 100%;
            }
            &__toggle-arrow{
                display: flex;
            }
        }
        &__legend{
            color:$write-transp;
        }
        &__body{
            @include flexbox(column,flex-start,flex-start);
            width: 100%;
            ul{
                width: 100%;
                @include flexbox(column,flex-start,flex-start);
                @include gap-responsive;
                li{
                    @include flexbox(row,flex-start,flex-start);
                    padding:0.25rem;
                    width: 100%;
                    &:hover{
                        background-color: #fff2;
                    }
                }
            }
        }
    }

    &__footer{
        .btn-frame{
            width: 100%;
            @include flexbox(row);
            @include gap-responsive;
        }
    }
}