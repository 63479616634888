#ddlp.content-box{
    background-color: $primary;
    border-color: $border-alt;
}


#ddlp .content-box__body{
    .grid-container{
        li a span:nth-child(1){
            border-color: $border-modal;
            border-width: 3px;
            &:hover{
                background:$button-gradient-hover;
            }
        }
    }
    ul li span{
        color:$write;
    }
}

#ddlp .exit-frame a{
    color:$write-transp;
}


#ddlp .exit-frame a{
    background: $button-gradient-hover!important;
}

#ddlp.content-box .grid-container #meet.active a span:nth-child(1){
    background: $button-gradient-hover!important;
}

@media screen and (max-width:800px) {

    #ddlp.content-box{
        top:80%!important;
    }

}