

.homepage{
    background: $bg-gradient;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
    &__body{
        position: relative;
        display: flex;
        height: 100%;
        min-height: 100vh;
        &__container{
            width: auto;
            min-height: 600px;
            padding-left:12.5%;
            padding-top: 3.8%;
            padding-bottom: 4%;
            @include flexbox(column,flex-start,flex-start);
            gap: 3.4vw;
            > div {
                opacity: 0;
            }
        }
    }
    .form{
        &__body{
            @include flexbox(column,flex-start,flex-start);
            width: fit-content;
            gap: $gap-desktop;
        }
    }
}


.homepage{
    &__body{
        .button-frame{
            display: grid;
            grid-template-columns: 1fr 0.85fr;
            width: 440px;
            gap: $gap-desktop;
        }
    }
}


@media screen and (max-width:1600px) {




}


@media screen and (max-width:800px) {

    .homepage{
        // background: $primary;
        background: linear-gradient(-90deg,#292832,$dark);
        &__body{
            &__container{
                width: 100%;
                padding-left: unset;
                padding: $padding-mobile;
                gap: 3.2rem;
                padding-top: 20%;
            }
        }
        .form{
            &__body{
                width: auto;
                .input-frame,.button-frame{
                    width: auto;
                }
            }
        }
    }

}



@media screen and (min-width:1921px) {

    .homepage{
        &__body{
            .button-frame{
                width: 24vw;
                gap:0.65vw;
            }
        }
    }

    .homepage{
        .form{
            &__body{
                gap:0.65vw;
                .input-frame{
                    width: 24vw;
                    height: 2.6vw;
                }
            }
        }
    }

}