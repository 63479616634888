// to refacto

.meet-created-modal{
  &__content {
    min-height: 120px;
    align-items: center;
    display: flex;
  }
  &__footer {
    .btn-frame {
      display: flex;
      @include gap-responsive;
    }
  }
}



@media screen and (max-width: 800px) {
  .meet-created-modal {
    // padding:1rem;
    &__container {
      width: auto;
    }
  }

  .meet-created-modal.large-modal {
    align-items: flex-end;
  }

  .meet-created-modal__container.large-modal__container {
    border-radius: 16px 16px 0px 0px;
  }
}
