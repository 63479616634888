.chat-panel{
    .progress-upload{
        display: grid;
        width: 100%;
        grid-template-columns: auto 1fr auto;
        gap:0.75rem;
        align-items: center;
        &__content{
            @include flexbox(column);
            gap:0.5rem;
        }
        &__picto,&__cross{
            display: flex;
        }
        &__cross{
            img{
                border:1px solid $write;
                border-radius: 50%;
                padding:0.16rem;
            }
        }
        
    }
}


.progress-upload-bar{
    display: flex;
    position: relative;
    &__value{
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    span{
        font-size: 9px;
        line-height: 0;
        height: -webkit-fill-available;
    }
}


progress[value]{
    -webkit-appearance: none;
    appearance:none;
    width: 250px;
    height: 6px;
}


progress[value]::-webkit-progress-bar{
    border-radius: 12px; 
    height: 6px;
    overflow: hidden;
    background: $button-gradient-hover;
    border: 1px solid #fff1;
}

progress[value]::-webkit-progress-value{
    // border-radius: 12px; 
    background-color: #A64FFF;

}

// @keyframes fillBar {
//     from{
//         width: 0;
//     }
//     to{
//         width: 100%;
//     }
// }