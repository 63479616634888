.participant-option-box{
    position: absolute;
    bottom: 0.75rem;
    left: 2.5rem;
    @include glassmorphism;
    padding:0.4rem;
    border-radius: $button-radius;
    border:1px solid $border-alt;
    z-index: 99;
    ul{
        li{
            color:$write-transp;   

        }
    }

}

.devroom__mosaic__screen__overlay.--bottom .container .participant-fixed-option-box{
    position: fixed;
    @include basic-frame;
    width: 100%;
    left: 0;
    border-radius: 24px 24px 0 0;
    padding: 1rem;
    min-height: 24vh;
    @include flexbox(column,flex-start,flex-start);
    gap:20px;
    bottom: 0;
    z-index: 999;
    .action-frame__mobile__options{
        li{
            img{
                opacity: 1;
            }
        }
    }
}