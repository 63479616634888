.modale-setup,.modale-setup__body{
    transition: 0.84s ease;
}

.modale-setup{
    color: $write;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100;
    left: 0;
    width: 100%;
    min-width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 2rem;
    backdrop-filter: brightness(0.56) contrast(0.92);
    z-index: 999;
    transition: all 0.3s ease;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    &__body{
        overflow: hidden;
        transform: translate(100%,0%);
        background-color: $primary;
        border:1px solid $border-alt;
        box-shadow: $modal-shadow;
        width: 50%;
        height: 100%;
        padding: 2rem;
        border-radius: 20px;
        @include flexbox(column);
        gap:2rem;
        min-width: 660px;
        justify-content: space-between;
        &__frame{
            background-color: $primary;
            border:1px solid $border-alt;
            box-shadow: $modal-shadow;
            border-radius: 10px;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        .btn-frame{
            gap:1rem;
            display: flex;
            flex-direction: row;
        }
        &__main-frame{
            overflow-y: overlay;
        }
        
    }
    &__accordeon__frame{
        @include flexbox(column);
        @include gap-responsive;
        
    }
}

.modale-setup.show{
    visibility: visible;
    opacity: 1;
}

.modale-setup.show .modale-setup__body{
    transform: translate(0%,0%);
    overflow:overlay;
}


@media screen and (max-width:800px) {

    .modale-setup.show{
        z-index: 9999;
        height: 100vh;
        overflow: hidden;
        
    }
    
    
    .modale-setup{
        padding:1rem;
        &__body{
            width: 100%;
            background-color: rgba(36, 33, 41,1);
            padding:1.25rem;
            transform-origin: center;
            transform: translate(0%,0%) scale(0.8);
            margin-top: 22%;
            height: 72%;
            min-width: unset;
            // box-shadow: unset;
            &__frame{
                padding: 0rem;
                padding-bottom:1rem;
                border:unset;
                border-bottom: 1px solid $border-alt;
                box-shadow: unset;
                border-radius: 0;
            }
            &__frame:nth-child(4){
                padding: 0rem;
                border: unset;
            }
            .btn-frame{
                flex-direction: column;
            }
            &__main-frame{
                @include flexbox(column);
                @include gap-responsive;
            }
        }
    }

}


@media screen and (min-width:1921px) {

    .modale-setup__body{
        width: 48%;
        &__frame{
            padding: 1.5vw;
        }
        .btn-frame{
            gap: 1vw;
        }
    }


}