// tous les boutons carrés et arrondis (pills) 


.square-pill{
    @include flexbox(row,center,center);
    overflow: hidden;
    width: 50px;
    height: 50px;
}



.Collapsible table{
    .square-pill,.round-pill{
        width: 40px;
        height: 40px;
    }
}

.square-pill{
    border-radius: 26px;
    background: linear-gradient(90deg,#FFFFFF40,#86597a40);
    padding: 2px;
    overflow: visible;
    position: relative;
    &__frame{
        padding: 1rem;
        background: linear-gradient(30deg,#162025c4,#13121fc4);
        border-radius: 24px;
        @include flexbox(row,center,center);
        cursor: pointer;
    }
    .notification{
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius:8px;
        background: linear-gradient(#FF8888,#F05F5F);
        transition: all 0.8s linear;
        top: -4px;
        z-index: 99;
        margin-right: -60%;
    }
    svg{
        width: 28px;
        height: 28px;
    }
}



.round-pill {
    cursor: pointer;
    border-radius: 50%;
    border: unset;
    user-select: none;
    display: flex;
    img {
        @include responsive-pictogram;
    }
}

.round-pill.avatar {
    img,.macaroon{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover;
        @include flexbox(row,center,center);
        text-transform: uppercase;
    }
}

.participant-list .round-pill.avatar {
    img,.macaroon{
        width: 40px;
        height: 40px;
    }
}

.message__box{
    .round-pill.avatar img{
        width: 20px;
        height: 20px;
    }
}


.options__avatar{
    .round-pill.avatar {
        img {
            @include responsive-pictogram;
        }
    }
}

.avatar .round-pill{
    @include flexbox(row,center,center);
    text-transform: uppercase;
    color:#fff;
}


.share-modal__clipped{
    position: fixed;
    bottom: 2%;
    transition: 0.4s linear all;
    z-index: 999;
    .pill{
        background: $border;
        border:1px solid $border-alt;
        border-radius: 32px;
        padding:0.75rem 1.25rem;
        p{
            margin-block-start: 0;
            margin-block-end: 0;
        }
    }
}

// visual effect pills 

.visual-effect-box{
    .pill-carousel{
        &__item{
            @include flexbox(column,center,center);
            @include gap-responsive;
            &__content{
                width: 96px;
                height: 96px;
                border-radius: 16px;
                border:1px solid $border;
                background-color:#2f2a38;
                @include flexbox(row,center,center);
                transform-origin: center;
                transition: 0.32s ease all;
                cursor: pointer;
                padding:0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transform: translateZ(0); 
            }
            input[type='file']{
                -webkit-appearance: unset;
                opacity: 0;
                width: 100%;
                height:100%;
            }
        }
    }
}


.random-color__pill,.upload-profile__pill{
    width: 66px;
    height: 66px;
    background-color:#2f2a38;
    @include flexbox(row,center,center);
    transform-origin: center;
    text-align: center;
    border-radius: 50%;
    text-transform: uppercase;
    text-shadow: 1px 1px 0px $primary;
    font-size: smaller;
    user-select: none;
    cursor: pointer;
        &::before{
        content:'';
        position: absolute;
        width: 68px;
        height: 68px;
        background: $pill-gradient;
        z-index: -1;
        position: absolute;
        border-radius: 50%;
    }
    
}

.random-color__pill{
    position: relative;
}

.upload-profile__pill{
    background: $button-gradient-opaque-hover;
    width: 64px;
    height: 64px;
    input[type='file']{
        position: absolute;
        width: inherit;
        height: inherit;
        opacity: 0;
        cursor: pointer;
    }
    &::before{
        // background: linear-gradient(96deg,#fff4,#fff6);
        width: 68px;
        height: 68px;
        // background: $border;
    }
    &.--preview{
        background-image: url(assets/pictures/upload-profile-default.jpg);
        @include bg-center-cover;
        background-size: 120%;
        cursor: initial;
    }
}

.random-color__pill span{
    // color: red;
    z-index: 999;
    width: 100%;
    // border: 2px solid red;
    height: 100%;
    @include flexbox(column,center,center);
    position: relative;
    &:hover + .palette{
        // transform: rotate(-45deg);
    }
}


.palette {
    position:absolute;
    left: -116px;
    bottom: 0;
    display: flex;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition:1s;
    z-index: 99;
    // border: 2px solid red;
    transform-origin: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.palette.active {
    opacity: 1;
    left: 0px;
    animation: logoRotate 8s infinite;
    // animation: logoRotate;
}



.palette div{
    position: absolute;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    transition:0.6s ease;
    @include flexbox(row,center,center);
    box-shadow: $modal-shadow;
    transform: translate(100px,0px);
    border:1px solid $primary;
    margin:auto;
}



.palette.active{
    #pal1{
        transform: translateX(0%) translateY(-250%);
    }
    #pal2{
        transform: translateX(0%) translateY(250%);
    }
    #pal3{
        transform: translateX(250%) translateY(0%);
    }
    #pal4{
        transform: translateX(-250%) translateY(0%);
    }
    #pal5{
        transform: translateX(188%) translateY(188%);
    }
    #pal6{
        transform: translateX(188%) translateY(-188%);
    }
    #pal7{
        transform: translateX(-188%) translateY(-188%);
    }
    #pal8{
        transform: translateX(-188%) translateY(188%);
    }
}



@media screen and (max-width:1600px) {

    .share-modal__clipped{
        .pill{
            padding:0.65rem 1rem;
        }
    }

    .random-color{
        &__pill{
            width: 64px;
            height: 64px;
            font-size: 12px;
            &::before{
                width: 68px;
                height: 68px;
            }
        }
    }

    

}


@media screen and (max-width:1024px) {

    .square-pill{
        
        &__frame{
            padding: 1rem;
        }
    }

}


@media screen and (max-width:800px) {

    .square-pill{
        background: unset;
        &__frame{
            background: $pill-gradient;
        }
    }
    
    .round-pill{
        width: auto;
        height: auto;
    }

    .round-pill.avatar {
        img {
            width: 32px;
            height: 32px;
            opacity: 1;
        }
    }


    .basic-settings{
        .round-pill{
            width: auto;
            height: auto;
            img{
                padding: 0;
            }
        }
    }
    
    .square-pill{
        border-radius: 50%;
        width: auto;
        height: auto;
        &__frame{
            border-radius: 50%;
            padding: 0.75rem;
            svg{
                height: 24px;
                width: 24px;
            }
        }
    }


    .Collapsible table .square-pill, .Collapsible table .round-pill{
        width: 32px;
        height: 32px;
    }

    .visual-effect-box{
        .pill-carousel{
            &__item{
                &__content{
                    width: 72px;
                    height: 72px;
                }
            }
        }
    }

    .random-color,.upload-profile{
        &__pill{
            width: 56px;
            height: 56px;
            font-size: 10px;
            &::before{
                width: 60px;
                height: 60px;
            }
        }
    }

}



@media screen and (min-width:1921px) {

    

    .square-pill{
        width: 3vw;
        height: 3vw;
        svg{
            width: 40px;
            height: 40px;
        }
    }



    .square-pill__frame {
        padding: 1.5rem;
    }


}

