* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;
}

#root{
	overflow: hidden;
}

html,
body {
	padding: 0;
	margin: 0;
}

img {
	image-rendering: -webkit-optimize-contrast;
	transform: translateZ(0);
	user-select: none;
}

.lock {
	pointer-events: none;
	user-select: none;
}


@media screen and (max-width:800px) {
	
	body{
		height: 100vh;
		overflow: hidden; /* Empêche le défilement supplémentaire */
		overscroll-behavior: none;
		background-color: $primary;
	}

	@supports (-webkit-touch-callout: none) {

		body{
			height: 100dvh;
		}

	}

	
}