.contacts-modal{
    &__container{
        min-width: 50%;
    }
    &__heading{
        width: 100%;
        @include flexbox(row,space-between,center);
    }
    &__list{
        width: 100%;
        display: flex;
       
        & > *{
            border: 1.5px solid $border-alt;
            height: 380px;
        }

        &__search-letter{
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y:auto;
        width: 48px;

            & span{
                font-size: 16px;
            }
        }

        &__sources, 
        &__destination{
            flex: 1;
            width: 360px;
            @include flexbox(column);
            padding: 1rem;
            @include gap-responsive;
            &__list{
                @include flexbox(column);
                height: 267px;
                overflow-y:auto;
                gap: 0.5rem;
                padding-right: 0.75rem
            }
        }
    }
}

.overlay-contacts-modal {
    position: fixed;
    inset: 0px;
    background-color:$border-alt;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width:1600px) {

    .contacts-modal{
        &__container{
            width: 56%;
            min-width: 780px;
        }
    }

}

@media screen and (max-width:800px) {

    .contacts-modal{
        background: $primary;
        padding:0rem;
        &__heading, 
        &__button-frame-top,
        &__list__destination, 
        &__list__sources__header{
            display: none;
        }
       
        &__link__img{
            width: 40px;
        }

        &__list{
            &__search-letter, &__sources,&__sources__list{
                height: 440px;
            }   
            &__sources__list{
                margin: 0;
            }
        }

        &__container{
            width: 56%;
            min-width: 100%;
        }
        &__button-frame-bottom{
            @include flexbox(column);
        }
    }

    .contacts-modal.large-modal{
        align-items: flex-start;
    }

    .contacts-modal__container.large-modal__container{
        border:unset;
        border-radius: unset;
        background-color: unset;
    }
}
