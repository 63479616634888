.room-not-found{
    background: $bg-gradient;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
    &__body{
        position: relative;
        display: flex;
        height: 100%;
        min-height: 100vh;
        &__container{
            height: 100%;
            min-height: 100vh;
            width: 100%;
            @include flexbox(column,center,center);
            @include gap-responsive;
        }
        &__footer{
            @include padding-responsive;
        }
    }
}