.action-frame__mobile,
.share-modal,.meet-created-modal{
    &__separator{
        display: none;
        hr{
            border: unset;
            height: 1px;
            background-color: $write-transp;
            width: 25%;
        }

    }
}

.bright-stroke{
    background-color: white!important;
    filter: brightness(8)!important;
}

.devroom{
    &__option-panel{
        hr{
            height: 30px;
            width: 0px;
            border: unset;
            border-left: 1.5px solid #fff3;
        }
    }
}

.upload-frame__separator{
    display:flex;
    width: 100%;
    hr{
        width: 72%;
        border:unset;
        height: 1px;
        background-color: $write-transp;
    }
}

.action-frame{
    &__mobile{
        &__separator{
            @include flexbox(row,center,center);
            width: 24%;
            height: 48px;
            // background-color: red;
            margin: auto;
            hr{
                border: unset;
                width: 100%;
                height: 1px;
                cursor: pointer;
            }
        }
    }
    hr{
        margin-left: 8px;
        margin-right: 8px;
    }
}


.devroom .action-frame__mobile__bottom hr{
    height: 40px;
    border: unset;
    width: 1px;
    background: $write-transp;
}

@media screen and (max-width:800px) {

    .share-modal,.meet-created-modal{
        &__separator{
            display: flex;
            min-height: 48px;
            align-items: center;
        }
    }
    


}