.external-dialog-frame{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color:$write;
    z-index: 9;
    pointer-events: none;
    outline:none;
    @include flexbox(row);
    &__body{
        pointer-events: auto;
        background: unset;
        outline:none;
        border:unset;
        top:10%;
        left: 1%;
        @include basic-frame;
        padding: 1.5rem;
        @include gap-responsive;
        @include flexbox(row,center);
        position: absolute;
        box-shadow: unset;
        border-radius: unset;
        user-select: auto;
        // -webkit-user-drag: auto !important;
        cursor:grab;
        button{
            @include padding-responsive;
            background: indigo;
        }
        textarea{
            @include padding-responsive;
            background:$primary;
            color:$write;
        }
    }
}

.external-dialog-frame.active{
    pointer-events: auto;
    background-color: rgba(100, 0, 255, 0.1); /* Exemple : fond semi-transparent bleu */
}

.external-dialog-frame.active .external-dialog-frame__body{
    opacity: 0.5;
}

.external-box{
    position: absolute;
    left: 1rem;
    background-color: $primary;
    border:1px solid $border;
    @include flexbox(column,center,flex-start);
    @include gap-responsive;
    @include padding-responsive;

    width: 100%;
    margin: auto;
    left: 0;
    top: 0;
    height: 100%;

}