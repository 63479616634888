.all-settings{
    position: fixed;
    // border: 2px solid red;
    left: 0;
    top: 0;
    color:$write;
    z-index: 99;
    width: 100%;
    height: 100%;
    @include flexbox(row,center,center);
    backdrop-filter: blur(2px) grayscale(0.16) brightness(0.4);
    &__frame{
        @include basic-frame;
        border-radius: 20px;
        box-shadow: 0 4px 24px -1px rgba(0,0,0,.2);
        @include flexbox(column);
        backdrop-filter: none;
        min-width:640px;
        min-height: 560px;
        gap:1.5rem;
        padding: 1.5rem;
    }
    &__header,&__heading{
        @include flexbox(row,space-between,center);
    }
    &__heading{
        @include gap-responsive;
    }
    
}

.all-settings__frame.--ms-settings{
    // border: 2px solid red!important;
    min-height: 540px;
    max-height: 540px;
}

.all-settings__frame.--ms-settings .duo-tab__content{
    // border: 2px solid blue!important;
    max-height: 400px;
    overflow-y: scroll;
}

.all-settings__frame.--ms-settings .duo-tab{
    // border: 2px solid blue!important;
    max-height: 360px;
    overflow-y: scroll;
}


