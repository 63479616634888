.overlay-user-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    backdrop-filter: brightness(0.64) contrast(0.96);
    transition: 0.48s ease all;
    z-index: 9999;
}

.user-modal {
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    background-color: $primary;
    border-radius: 10px;
    overflow: auto;
    outline: none;
    padding: 1.5rem;
    width: 350px;

    
    position: absolute;
    z-index: 10000;
    @include flexbox(column,center,center);
    gap: 1rem;

    &__avatar {
        img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
        }
    }

}


@media screen and (max-width:800px) {

    .user-modal {
        top: 100%;
        right: 10px;
    }

}

@media screen and (min-width:800px) {

    .user-modal {
        left: 100%;
        bottom: 40px;
    }

}