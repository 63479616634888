.large-modal{
  position: fixed;
  inset: 0px;
  backdrop-filter: brightness(0.72) contrast(0.96);
  transition: 0.16s ease all;
  @include flexbox(row,center,center);
  z-index: 99999;
  &__container{
    @include flexbox(column);
      border: 1px solid $border;
      box-shadow: $modal-shadow;
      background-color: $primary; 
      border-radius:16px;
      overflow: auto;
      outline: none;
      padding: 1.5rem;
      gap: 1rem;
  }

  .heading-frame{
    display: flex;
    @include gap-responsive;
  }
} 

.meet-created-modal,
.preview-settings-modal {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transform-origin: center;
  &__container {
    width: 36%;
    @include flexbox(column);
    @include gap-responsive;
    box-shadow: $modal-shadow;
    transition: 0.1s ease;
    transform: scale(0.8);
  }
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .exit-frame {
      display: flex;
    }
  }
}

.preview-settings-modal.show .preview-settings-modal__container,
.meet-created-modal.show .meet-created-modal__container {
  transform: scale(1);
}


.meet-created-modal.show,
.preview-settings-modal.show {
  display: flex;
  opacity: 1;
  visibility: visible;
}


@media screen and (max-width:1600px) {


  .meet-created-modal{
    &__container{
      width: 40%;
    }
  }

}