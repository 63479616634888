#smiley{
    position: relative;
}

.emoji-library{
    position: absolute;
    @include basic-frame;
    @include large-padding;
    @include flexbox(column-reverse,flex-start,flex-start);
    left: 0;
    top: -500px;
    height: 480px;
    overflow-y: overlay;
    gap:1rem;
    width: 100%;
    span{
        font-family: 'EmojiMart';
    }
    &__grid{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
        place-items: center;
        gap: 6px;
        span{
            @include flexbox(row,center,center);
            height: 32px;
            width: 32px;
            font-size: 22px;
            aspect-ratio: 1/1;
            padding-top: 8px;
            border-radius: 50%;
            &:hover{
                background-color: #A64FFF80;
            }
        }
    }
    &__last-used{
        border: 1px solid $border;
        border-radius: 6px;
        width: 100%;
        position: relative;
        @include flexbox(column);
        .heading{
            position: absolute;
            top: -0.75rem;
            left: 0.5rem;
            background: $primary;
            padding:0.25rem 1rem;
        }
        .frame{
            padding:1rem;
            span{
                font-size: 18px;
            }
        }
    }
}


@media screen and (max-width:800px) {

    @supports (-webkit-touch-callout: none) {

        .emoji-library{
            height: 280px;
            top: -300px;
        }

    }
    
}