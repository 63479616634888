.devroom{
    .stats-panel{
        width: 100%;
        color:$write;
        @include flexbox(column);
        @include basic-frame;
        @include gap-large;
        padding:1rem;
        &__body{
            overflow: overlay;
            @include flexbox(column,flex-start);
            @include gap-responsive;
            .Collapsible{
                @include basic-frame;
                background: #ffffff05;
                padding:1rem;
            }
        }
        &__frame{
            @include basic-frame;
            @include padding-responsive;
        }
    }
}

@media screen and (max-width:800px) {

    .devroom{
        .stats-panel{
            border-radius: unset;
            border:unset;
        }
    }

    @supports (-webkit-touch-callout: none) {

        .devroom .stats-panel{
            // border:2px solid lightgreen;
            height: 79.5dvh;
        }
    }
    

}