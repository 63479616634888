.top-bar-mobile{
    padding: 1rem;
    background-color: $primary;
    height: 72px;
    // border: 1px solid red;
    &__body{
        @include flexbox(row,space-between,center);
        color:$write;
        hr{
            display: flex;
            height: 24px;
            border: unset;
            width: 1px;
            background: $write;
        }
        // img{
        //     width: 36px;
        // }
        .info-box{
            img {
                width: 20px;
            }
        }
    }
    &__left{
        @include flexbox(row,flex-start,center);
        gap:15px;
    }
    &__right{
        @include flexbox(row);
        gap:15px;
    }
}

@supports (-webkit-touch-callout: none) {

    .top-bar-mobile{
        height: 10dvh;
        // border: 1px solid greenyellow;
    }

}
