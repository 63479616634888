$sound-effect : #72BBF132;
$sound-effect-medium : #72BBF148;
$sound-effect-high : #72BBF1a4;

.prejoin{
    .option-layer{
        img{
            border-radius: 50%;
            border: 5px solid transparent;
        }
        span{
            display: flex;
            width: auto;
            height: auto;
            border-radius: 50%;
            border:0px solid transparent;
            transform-origin: center;
            padding:6px;
        }
        .no{
            padding:6px;
            border:0px solid transparent;
        }
        .regular{
            border:1px solid $sound-effect;
            padding:5px;
            img{
                border: 5px solid $sound-effect;
            }
        }
        .medium{
            border:2px solid $sound-effect-medium;
            padding:4px;
            img{
                border: 5px solid $sound-effect-medium;   
            }
        }
        .high{
            border:3px solid $sound-effect-high;
            padding:3px;
            img{
                border: 5px solid $sound-effect-high;
            }
        }
    }

}