@charset "UTF-8";
@font-face {
  font-family: "Poppins Regular";
  src: url("/src/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Poppins Light";
  src: url("/src/assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Regular";
  src: url("/src/assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Bold";
  src: url("/src/assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans ExtraBold";
  src: url("/src/assets/fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "EmojiMart";
  src: url("/src/assets/fonts/Seguiemj/seguiemj.ttf") format("truetype");
  font-display: swap;
}
@keyframes rotatingCircles {
  from {
    transform: scale(1);
    opacity: 0.6;
  }
  to {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}
@keyframes rotatingScalingCircle {
  from {
    transform: scale(0.8);
    opacity: 0.5;
  }
  1% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
@keyframes logoRotate {
  0% {
    transform: rotate(0) scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(0.8) rotate(-45deg);
  }
  100% {
    transform: scale(1.2) rotate(0deg);
  }
}
@keyframes textclip {
  from {
    background-position: initial;
  }
  50% {
    background-position: 200% center;
  }
  100% {
    background-position: initial;
  }
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rotateThumbs {
  from {
    transform: rotate(0deg);
    opacity: 0.75;
  }
  25% {
    transform: rotate(-10deg) scale(2);
    opacity: 0.95;
  }
  40% {
    transform: rotate(0deg) translateY(-400px) scale(3);
    opacity: 1;
  }
  45% {
    transform: rotate(-1deg) translateY(-400px) scale(4);
  }
  50% {
    transform: rotate(1deg) translateY(-400px) scale(3);
  }
  75% {
    transform: rotate(-10deg) scale(2);
  }
  100% {
    transform: rotate(0deg) scale(0);
  }
}
@keyframes clappingHands {
  from {
    transform: rotate(0deg);
    opacity: 0.75;
  }
  25% {
    transform: rotate(-10deg) scale(1);
    opacity: 0.95;
  }
  40% {
    transform: rotate(0deg) translateY(-250px) scale(2);
    opacity: 1;
  }
  45% {
    transform: rotate(-1deg) translateY(-275px) scale(3);
  }
  50% {
    transform: rotate(1deg) translateY(-250px) scale(2);
  }
  55% {
    transform: rotate(1deg) translateY(-260px) scale(2.25);
  }
  60% {
    transform: rotate(1deg) translateY(-250px) scale(2);
  }
  65% {
    transform: rotate(1deg) translateY(-260px) scale(2.25);
  }
  70% {
    transform: rotate(1deg) translateY(-250px) scale(2);
  }
  75% {
    transform: rotate(-10deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(0);
  }
}
@keyframes thumbsDown {
  from {
    transform: rotate(0deg) translateY(-400px);
    opacity: 0.75;
  }
  25% {
    transform: rotate(-10deg) scale(2);
    opacity: 0.95;
  }
  40% {
    transform: rotate(0deg) scale(2.5);
    opacity: 1;
  }
  45% {
    transform: rotate(-1deg) scale(3);
  }
  50% {
    transform: rotate(1deg) translateY(0px) scale(2);
  }
  75% {
    transform: rotate(-10deg) scale(1);
    opacity: 0.75;
  }
  100% {
    transform: rotate(0deg) scale(0);
    opacity: 0.5;
  }
}
#ddlp.content-box {
  background-color: #242129;
  border-color: rgba(255, 255, 255, 0.062745098);
}

#ddlp .content-box__body .grid-container li a span:nth-child(1) {
  border-color: rgba(255, 255, 255, 0.031372549);
  border-width: 3px;
}
#ddlp .content-box__body .grid-container li a span:nth-child(1):hover {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
}
#ddlp .content-box__body ul li span {
  color: rgba(255, 255, 255, 0.9333333333);
}

#ddlp .exit-frame a {
  color: rgba(255, 255, 255, 0.5333333333);
}

#ddlp .exit-frame a {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922)) !important;
}

#ddlp.content-box .grid-container #meet.active a span:nth-child(1) {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922)) !important;
}

@media screen and (max-width: 800px) {
  #ddlp.content-box {
    top: 80% !important;
  }
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

#root {
  overflow: hidden;
}

html,
body {
  padding: 0;
  margin: 0;
}

img {
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  user-select: none;
}

.lock {
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 800px) {
  body {
    height: 100vh;
    overflow: hidden; /* Empêche le défilement supplémentaire */
    overscroll-behavior: none;
    background-color: #242129;
  }
  @supports (-webkit-touch-callout: none) {
    body {
      height: 100dvh;
    }
  }
}
button {
  cursor: pointer;
  transition: 0.2s ease;
  font-size: 14px;
  font-family: "Open Sans Regular", sans-serif;
  color: rgba(255, 255, 255, 0.9333333333);
  user-select: none;
}
@media screen and (max-width: 800px) {
  button {
    font-size: 13px;
  }
}
button:active {
  transform: scale(0.96);
}

.btn-frame button {
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  .btn-frame button {
    font-size: 13px;
  }
}

.btn-icon,
.btn-square,
.btn-regular {
  border-radius: 5px;
  border: unset;
}
.btn-icon img,
.btn-square img,
.btn-regular img {
  width: 24px;
  height: 24px;
}

.btn-regular {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: linear-gradient(-120deg, #A64FFF, #6370E7);
  width: 100%;
}
.btn-regular:hover {
  background: linear-gradient(-120deg, #823CCA, #4C57BB);
}

.action-frame__mobile .btn-square,
.action-frame__mobile .btn-icon__usergroup,
.laptop-options .btn-square,
.action-frame .btn-square,
.action-frame .btn-icon__usergroup {
  border-radius: 20px;
  background: linear-gradient(-120deg, rgba(58, 54, 85, 0.5019607843), rgba(34, 48, 57, 0.5019607843) 100%);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  overflow: visible;
  position: relative;
  height: 52px;
  width: 52px;
  min-width: 52px;
}
.action-frame__mobile .btn-square:hover,
.action-frame__mobile .btn-icon__usergroup:hover,
.laptop-options .btn-square:hover,
.action-frame .btn-square:hover,
.action-frame .btn-icon__usergroup:hover {
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  background: linear-gradient(45deg, #A64FFF, #6370E7);
}
.action-frame__mobile .btn-square:hover img,
.action-frame__mobile .btn-icon__usergroup:hover img,
.laptop-options .btn-square:hover img,
.action-frame .btn-square:hover img,
.action-frame .btn-icon__usergroup:hover img {
  transform: scale(1.25);
}
.action-frame__mobile .btn-square img,
.action-frame__mobile .btn-icon__usergroup img,
.laptop-options .btn-square img,
.action-frame .btn-square img,
.action-frame .btn-icon__usergroup img {
  transition: 0.2s linear all;
  z-index: 9;
}

.preview-settings-modal__footer .btn-regular {
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .preview-settings-modal__footer .btn-regular {
    padding: 0.55rem;
  }
}

.btn-duo-frame {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .btn-duo-frame {
    gap: 5px;
  }
}
.btn-duo-frame button {
  height: 55px;
}
.btn-duo-frame button,
.btn-duo-frame button span {
  font-size: 18px;
}

.btn-duo-frame button:nth-child(1) {
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
}
.btn-duo-frame button:nth-child(1):hover {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
}
.btn-duo-frame button:nth-child(2) {
  background: linear-gradient(-120deg, #A64FFF, #6370E7);
}
.btn-duo-frame button:nth-child(2):hover {
  background: linear-gradient(-120deg, #823CCA, #4C57BB);
}

.duo-tab .btn-frame .btn-icon,
.room-not-found .btn-frame .btn-icon,
.prejoin .btn-frame .btn-icon {
  background: linear-gradient(20deg, rgba(58, 54, 85, 0.337254902), rgba(34, 48, 57, 0.337254902) 100%);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.0705882353), 0px 1px 2px 2px rgba(0, 0, 0, 0.0862745098);
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .duo-tab .btn-frame .btn-icon,
  .room-not-found .btn-frame .btn-icon,
  .prejoin .btn-frame .btn-icon {
    gap: 5px;
  }
}
.duo-tab .btn-frame .btn-icon:hover,
.room-not-found .btn-frame .btn-icon:hover,
.prejoin .btn-frame .btn-icon:hover {
  box-shadow: unset;
}

.duo-tab .btn-frame .btn-icon {
  color: rgba(255, 255, 255, 0.5333333333);
}
.duo-tab .btn-frame .btn-icon:hover {
  color: rgba(255, 255, 255, 0.9333333333);
  border: 1px solid rgba(141, 92, 246, 0.6431372549);
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
}
.duo-tab .btn-frame .btn-icon:hover img {
  opacity: 1;
}
.duo-tab .btn-frame img {
  opacity: 0.4;
}

.duo-tab .btn-frame .btn-icon.active,
.preview-settings-modal .btn-frame .btn-icon.active {
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
  color: rgba(255, 255, 255, 0.9333333333);
  border: 1px solid rgba(141, 92, 246, 0.6431372549);
}
.duo-tab .btn-frame .btn-icon.active img,
.preview-settings-modal .btn-frame .btn-icon.active img {
  opacity: 1;
}

.user-modal .btn-regular {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  background: linear-gradient(-120deg, #A64FFF, #6370E7);
  width: auto;
}
@media screen and (max-width: 1024px) {
  .user-modal .btn-regular {
    padding: 0.55rem;
  }
}
.user-modal .btn-regular:hover {
  background: linear-gradient(-120deg, #823CCA, #4C57BB);
}

.homepage__body .button-frame button {
  padding: 1rem 1.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.devroom__option-panel .action-frame button:hover img {
  filter: brightness(5);
}

.btn-record {
  height: 52px;
  min-width: 172px;
  border: unset;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-120deg, #823cca, #4c57bb);
  overflow: hidden;
  transform-origin: center;
  transition: 0.1s linear;
  cursor: pointer;
  z-index: 1;
}
.btn-record:hover {
  background: linear-gradient(-120deg, rgba(130, 60, 202, 0.2823529412), rgba(76, 87, 187, 0.2823529412));
}
.btn-record:active {
  transform: scale(0.92);
}
.btn-record__content {
  height: 50px;
  min-width: 170px;
  padding: 0 15px;
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.5882352941), rgba(34, 48, 57, 0.5882352941) 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: inherit;
  color: rgba(255, 255, 255, 0.9333333333);
  position: relative;
}
.btn-record__content #stop-btn,
.btn-record__content #pause-btn {
  cursor: pointer;
  transform-origin: center;
  display: flex;
  transition: 0.1s;
}
.btn-record__content #stop-btn:hover,
.btn-record__content #pause-btn:hover {
  transform: scale(1.1);
}
.btn-record__content #pause-btn {
  border-radius: unset;
}
.btn-record #record-notif {
  width: 10px;
  height: 10px;
  background: linear-gradient(#FF8888, #F05F5F);
  border-radius: 50%;
}

.btn-square.--filters.active,
.action-frame .active {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
  border: 1px solid rgba(141, 92, 246, 0.6431372549);
}

.contacts-modal__button-frame-top, .contacts-modal__button-frame-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .contacts-modal__button-frame-top, .contacts-modal__button-frame-bottom {
    gap: 5px;
  }
}
.contacts-modal__button-frame-top .btn-icon, .contacts-modal__button-frame-bottom .btn-icon {
  color: rgba(255, 255, 255, 0.9333333333);
  background: rgba(255, 255, 255, 0.031372549);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 5px;
  border-image-slice: 1;
  border-image-source: linear-gradient(rgba(255, 255, 255, 0.062745098), rgba(255, 255, 255, 0.062745098));
  width: 100%;
}
.contacts-modal__button-frame-top .btn-icon__is-selected, .contacts-modal__button-frame-top .btn-icon:hover, .contacts-modal__button-frame-bottom .btn-icon__is-selected, .contacts-modal__button-frame-bottom .btn-icon:hover {
  background: linear-gradient(180deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #823cca, #4c57bb);
}
.contacts-modal__button-frame-top .btn-icon__background_btn, .contacts-modal__button-frame-bottom .btn-icon__background_btn {
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
}
.contacts-modal__button-frame-top button, .contacts-modal__button-frame-bottom button {
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .contacts-modal__button-frame-top button, .contacts-modal__button-frame-bottom button {
    padding: 0.55rem;
  }
}

.preview-send-anim-modale .btn-icon {
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .preview-send-anim-modale .btn-icon {
    gap: 5px;
  }
}

.meeting-info-modale .btn-icon {
  padding: 10px 15px;
}

.preview-settings-modal__body__left .btn-icon img {
  opacity: 0.5;
}
.preview-settings-modal__body__left .btn-icon.active img {
  opacity: 1;
}

.action-frame #camera-icon.btn-square.camera-active,
.action-frame #camera-icon.btn-square:hover {
  background: linear-gradient(45deg, rgba(255, 136, 136, 0.1960784314), rgba(240, 95, 95, 0.1960784314));
  border-color: rgba(240, 95, 95, 0.6431372549);
}

.action-frame .record-container {
  position: relative;
}

.action-frame__mobile .btn-icon__usergroup,
.action-frame .btn-icon__usergroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 15px;
  width: auto;
  min-width: 80px;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .action-frame__mobile .btn-icon__usergroup,
  .action-frame .btn-icon__usergroup {
    gap: 5px;
  }
}
.action-frame__mobile .btn-icon__usergroup:hover,
.action-frame .btn-icon__usergroup:hover {
  background: linear-gradient(45deg, #A64FFF, #6370E7);
}

.chat-panel__option-panel .btn-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  padding: 0.5rem;
}

.chat-panel__option-panel .btn-icon__active,
.btn-square.active {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
  border: 1px solid rgba(141, 92, 246, 0.6431372549);
}
.chat-panel__option-panel .btn-icon__active img,
.btn-square.active img {
  opacity: 1;
}

.external-box .btn-regular {
  width: 100%;
  background: linear-gradient(-120deg, #823CCA, #4C57BB);
}
.external-box .btn-icon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background: linear-gradient(-120deg, #A64FFF, #6370E7);
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#test-auth-btn {
  position: absolute;
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 14px;
  border: unset;
  background: linear-gradient(135deg, rgba(54, 205, 207, 0.5) 0%, rgba(64, 224, 207, 0.5) 100%);
  font-size: 12px;
  border-radius: unset;
  padding: 0.32rem;
  width: 120px;
  left: 0;
  top: 0;
}
@media screen and (max-width: 1024px) {
  #test-auth-btn {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  #test-auth-btn {
    font-size: 13px;
  }
}
#test-auth-btn:hover {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
}
#test-auth-btn:hover {
  background: linear-gradient(135deg, rgba(54, 205, 207, 0.5) 0%, rgba(64, 224, 207, 0.5) 100%);
  filter: brightness(2);
  text-decoration: underline;
}

.prejoin .notif-box .form .btn-regular.not-ready {
  pointer-events: none;
  background: rgba(255, 255, 255, 0.0823529412);
  height: 48px;
}
.prejoin .notif-box .form .btn-regular.not-ready span::first-letter {
  text-transform: uppercase;
}
.prejoin .notif-box .form .btn-regular.not-ready:hover {
  background: rgba(255, 255, 255, 0.031372549);
}
.prejoin .notif-box .form .btn-regular.active {
  background: linear-gradient(45deg, #A64FFF, #6370E7);
}

.prejoin #test-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.prejoin #test-btn span {
  width: 100%;
  background: linear-gradient(135deg, rgba(54, 205, 207, 0.5) 0%, rgba(64, 224, 207, 0.5) 100%);
  border: unset;
  text-align: center;
  border-radius: unset;
  padding: 6px;
  font-size: 14px;
  box-shadow: unset;
}
@media screen and (max-width: 800px) {
  .prejoin #test-btn span {
    font-size: 13px;
  }
}

#upload-image-preview-settings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
  border: unset;
  background: unset;
}
@media screen and (max-width: 800px) {
  #upload-image-preview-settings {
    gap: 5px;
  }
}

.btn-icon__raise-hand {
  background: linear-gradient(to right, rgba(36, 33, 41, 0.7019607843) 60%, rgba(68, 63, 77, 0.7019607843) 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  gap: 10px;
}
@media screen and (max-width: 1024px) {
  .btn-icon__raise-hand {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .btn-icon__raise-hand {
    gap: 5px;
  }
}

.participant-request__footer .btn-frame button:nth-child(1) {
  background: linear-gradient(90deg, rgba(166, 79, 255, 0.1960784314), rgba(99, 112, 231, 0.1960784314));
}
.participant-request__footer .btn-frame button:nth-child(1):hover {
  background: linear-gradient(90deg, rgba(166, 79, 255, 0.5019607843), rgba(99, 112, 231, 0.5019607843));
}

.btn-check {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-radius: 5px;
  display: flex;
  background: rgba(36, 33, 41, 0.7019607843);
  padding: 0.75rem;
  gap: 10px;
  color: rgba(255, 255, 255, 0.5333333333);
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .btn-check {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .btn-check {
    gap: 5px;
  }
}
.btn-check:hover {
  border: 1px solid transparent;
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
  color: rgba(255, 255, 255, 0.9333333333);
}
.btn-check__left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .btn-check__left {
    gap: 5px;
  }
}

.btn-check.active {
  border: 1px solid rgba(141, 92, 246, 0.6431372549);
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.32), rgba(99, 112, 231, 0.32));
  color: rgba(255, 255, 255, 0.9333333333);
}
.btn-check.active img {
  opacity: 1;
}

#open-shortcuts {
  background: -webkit-linear-gradient(-20deg, #a64fff, #6370e7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: unset;
  border-bottom: 1px solid transparent;
  padding: 0;
}
#open-shortcuts:hover {
  border-bottom: 1px solid #6370e7;
}

@media screen and (max-width: 1600px) {
  .btn-icon img,
  .btn-square img,
  .btn-regular img {
    width: 22px;
    height: 22px;
  }
  .btn-record {
    height: 54px;
  }
  .btn-record__content {
    height: 52px;
  }
  .action-frame__mobile .btn-square,
  .action-frame__mobile .btn-icon__usergroup,
  .action-frame .btn-square,
  .action-frame .btn-icon__usergroup {
    height: 54px;
    width: 54px;
    min-width: 54px;
    border-radius: 22px;
  }
  .--ms-panel .action-frame__mobile .btn-square,
  .--ms-panel .action-frame__mobile .btn-icon__usergroup,
  .--ms-panel .action-frame .btn-square,
  .--ms-panel.action-frame .btn-icon__usergroup {
    height: 46px;
    width: 46px;
    min-width: 46px;
    border-radius: 20px;
  }
  .action-frame .btn-icon__usergroup {
    min-width: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .modale-setup__body__frame:nth-child(4) .btn-regular {
    padding: 0.75rem 0.25rem;
  }
  .homepage__body .button-frame button {
    padding: 0.65rem;
  }
  .prejoin .notif-box .form .btn-regular {
    padding: 1rem 1.5rem;
  }
  .modale-setup .btn-regular.create-meeting {
    padding: 0.75rem;
  }
  .btn-regular {
    padding: 0.65rem;
  }
  .action-frame__mobile .btn-square,
  .action-frame__mobile .btn-icon__usergroup,
  .action-frame .btn-square,
  .action-frame .btn-icon__usergroup {
    height: 50px;
    width: 50px;
    min-width: 50px;
  }
}
@media screen and (max-width: 800px) {
  .prejoin #test-btn {
    position: fixed;
    top: unset;
    bottom: 0;
    right: 0;
  }
  .prejoin #test-btn button {
    z-index: 999;
  }
  .btn-regular {
    padding: 0.55rem;
  }
  .action-frame__mobile .btn-icon__usergroup {
    min-width: 80px;
  }
  .btn-record,
  .btn-record__content {
    height: 44px;
  }
  .btn-duo-frame .button-frame {
    font-size: 16px;
  }
  .btn-duo-frame button {
    height: 50px;
  }
}
/* toggle box  */
.toggle-box {
  display: flex;
}

.toggle-box input {
  visibility: hidden;
  width: 0px;
  height: 0px;
  position: absolute;
}

.toggle-box label {
  width: 56px;
  height: 30px;
  position: relative;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 32px;
  background: #3e3b42;
  cursor: pointer;
}

.toggle-box label::after {
  content: "";
  width: 24px;
  height: 24px;
  top: 2px;
  left: 3px;
  position: absolute;
  background-color: #242129;
  border-radius: 50%;
  transition: all 0.32s ease;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  display: flex;
}

.toggle-box input:checked + label {
  background: linear-gradient(-90deg, rgba(166, 79, 255, 0.6431372549), rgba(99, 112, 231, 0.6431372549));
}

.toggle-box input:checked + label:after {
  content: "";
  left: 28px;
}

@media screen and (max-width: 1600px) {
  .toggle-box label {
    width: 50px;
    height: 26px;
    top: 1px;
    left: 0px;
  }
  .toggle-box label::after {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2.5px;
  }
  .toggle-box input:checked + label:after {
    left: 25px;
  }
}
@media screen and (min-width: 1920px) {
  .toggle-box label {
    width: 64px;
    height: 34px;
  }
  .toggle-box label::after {
    width: 28px;
    height: 28px;
    top: 2px;
    left: 3px;
  }
  .toggle-box input:checked + label:after {
    left: 32px;
  }
}
.btn-square {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
  height: 42px;
  width: 42px;
  min-width: 42px;
}

.btn-square.--info {
  border-color: rgba(255, 207, 79, 0.768627451);
  background: linear-gradient(-120deg, rgba(255, 207, 79, 0.1882352941), rgba(255, 155, 74, 0.1882352941));
}

.btn-square.--filters {
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .btn-square.--filters {
    padding: 0.55rem;
  }
}

.chat-panel__dialog-action .btn-square {
  height: 44px;
  width: 44px;
  min-width: 44px;
  background: linear-gradient(-120deg, #A64FFF, #6370E7);
}

.laptop-options .btn-square {
  background: linear-gradient(-60deg, #3A3655, #223039 100%);
}

.searchframe .btn-square {
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
}

.searchframe .btn-square.active {
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.3921568627), rgba(68, 78, 165, 0.3921568627));
  border: 1px solid rgba(141, 92, 246, 0.6431372549);
}

@media screen and (max-width: 1600px) {
  .btn-square {
    height: 42px;
    width: 42px;
    min-width: 42px;
  }
}
@media screen and (max-width: 1400px) {
  .btn-square {
    height: 40px;
    width: 40px;
    min-width: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .chat-panel__dialog-action .btn-square {
    height: 42px;
    width: 42px;
    min-width: 42px;
  }
  .modale-setup__body .btn-square {
    width: 36px;
    height: 36px;
    min-width: 36px;
  }
}
*[data-interactive=true] {
  user-select: none;
  display: flex;
}

*[data-interactive=true] span,
*[data-interactive=true] svg,
*[data-interactive=true] img {
  opacity: 0.5;
  transition: 0.16s ease;
  object-fit: contain;
  user-select: none;
  cursor: pointer;
}
*[data-interactive=true] span:hover,
*[data-interactive=true] svg:hover,
*[data-interactive=true] img:hover {
  opacity: 1;
}

*[data-interactive=true] span {
  padding: 0.25rem;
  opacity: 1;
}

*[data-interactive=true] .opaque,
*[data-interactive=true] #participant-add-user-icon,
*[data-interactive=true] #pinned {
  opacity: 1;
}
*[data-interactive=true] .opaque:hover,
*[data-interactive=true] #participant-add-user-icon:hover,
*[data-interactive=true] #pinned:hover {
  opacity: initial;
}

*[data-interactive=true] img.top {
  transform-origin: center;
  transform: scaleY(-1);
}

#participant-add-user-icon {
  width: 30px;
  height: 30px;
}

button[data-interactive=true]:hover img, button[data-interactive=true]:hover svg {
  opacity: 1;
}

*[data-size=x-large] img {
  width: 36px;
  height: 36px;
}

*[data-size=large] img {
  width: 26px;
  height: 26px;
}

*[data-size=medium] img {
  width: 24px;
  height: 24px;
}

*[data-size=small] img {
  width: 24px;
  height: 24px;
}

*[data-size=x-small] img {
  width: 24px;
  height: 24px;
}

img.active {
  opacity: 1;
}

particle {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 9999;
}

@media screen and (max-width: 1600px) {
  *[data-size=x-large] img {
    width: 32px;
    height: 32px;
  }
}
@media screen and (min-width: 1921px) {
  *[data-size=large] img {
    width: 24px;
    height: 24px;
  }
  *[data-size=medium] img {
    width: 24px;
    height: 24px;
  }
}
a {
  color: rgba(255, 255, 255, 0.9333333333);
}

.prejoin a {
  color: rgba(255, 255, 255, 0.5333333333);
}
.prejoin a:hover {
  color: rgba(255, 255, 255, 0.9333333333);
}

.message__box__content,
p, span {
  font-family: "Open Sans Regular", sans-serif;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .message__box__content,
  p, span {
    font-size: 13px;
  }
}

.meet-created-modal p,
.share-modal p,
.contacts-modal p,
.prejoin p,
.room-not-found p,
.homepage p {
  color: rgba(255, 255, 255, 0.9333333333);
}

.options__profil p {
  line-height: 0;
  text-align: center;
}

.advanced-settings .form__row .input__frame p {
  margin-block-start: 0;
  margin-block-end: 0;
  color: rgba(255, 255, 255, 0.5333333333);
}

.contacts-modal p {
  margin-block-end: 0.25rem;
  margin-block-start: 0.25rem;
}
.contacts-modal span {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  color: rgba(255, 255, 255, 0.7333333333);
}

.user-modal p {
  font-family: "Open Sans Regular", sans-serif;
  text-align: center;
  color: rgba(255, 255, 255, 0.9333333333);
  line-height: 19px;
}
.user-modal span {
  font-size: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5333333333);
  line-height: 16px;
}

#text_video_meeting_link {
  color: rgba(255, 255, 255, 0.5333333333);
}

.options {
  cursor: pointer;
}
.options span {
  font-size: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9333333333);
}

.prejoin__left-panel__body p,
.prejoin .greetings-box__mobile p {
  text-align: center;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .prejoin__left-panel__body p,
  .prejoin .greetings-box__mobile p {
    font-size: 13px;
  }
}

.prejoin .greetings-box p, .prejoin .greetings-box .legend, .prejoin .greetings-box__mobile p, .prejoin .greetings-box__mobile .legend {
  color: rgba(255, 255, 255, 0.5333333333);
}

.macaroon-box span {
  text-transform: uppercase;
  text-align: center;
  font-size: 6.4vw;
  font-family: "Open Sans Regular", sans-serif;
  color: rgba(255, 255, 255, 0.9333333333);
  letter-spacing: 0.56vw;
  line-height: 0;
}

.visual-effect-box .pill-carousel__item span {
  color: rgba(255, 255, 255, 0.5333333333);
  font-size: 14px;
  user-select: none;
}
@media screen and (max-width: 800px) {
  .visual-effect-box .pill-carousel__item span {
    font-size: 13px;
  }
}

.devroom__mosaic__screen__overlay span {
  font-family: "Open Sans Bold", sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.768627451), 0px -1px 1px rgba(0, 0, 0, 0.2823529412);
}
@media screen and (max-width: 800px) {
  .devroom__mosaic__screen__overlay span {
    font-size: 13px;
  }
}

.someone-raising-hand span {
  font-family: "Open Sans Bold", sans-serif;
  font-size: 17px;
}

.message__box__content {
  font-size: 15px;
  font-family: "EmojiMart";
  letter-spacing: 0.016rem;
  white-space: pre-line;
  line-height: 20px;
}

.upload-frame__content__bottom span {
  color: rgba(255, 255, 255, 0.5333333333);
}

.progress-upload .rate {
  color: #36CDCF;
}
.progress-upload .max-size {
  color: rgba(255, 255, 255, 0.5333333333);
}

.participant-list__item__label .username::first-letter {
  text-transform: uppercase;
}

.record-modal p {
  color: rgba(255, 255, 255, 0.5333333333);
}

.tab-content .progress-bar p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5333333333);
}

.--screen-layout .tab-content__frame.--top p {
  margin-block-end: 0.5rem;
  margin-block-start: 0.5rem;
}
.--screen-layout .tab-content__frame.--top p:nth-child(1) {
  color: rgba(255, 255, 255, 0.9333333333);
}
.--screen-layout .tab-content__frame.--top p:nth-child(2) {
  color: rgba(255, 255, 255, 0.5333333333);
}

.tab-content .--more-options span {
  color: rgba(255, 255, 255, 0.5333333333);
}

.devroom__option-panel p, .devroom__option-panel span {
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  .devroom__option-panel p, .devroom__option-panel span {
    font-size: 13px;
  }
}

.devroom__option-panel .btn-record span {
  font-size: 15px;
}

.meeting-info-modale span {
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .meeting-info-modale span {
    font-size: 13px;
  }
}

.record-modal p {
  margin-block: 0;
  font-size: 13px;
  text-align: justify;
}

.devroom__option-panel__left .info-box span {
  font-size: 18px;
}

.all-settings__frame.--ms-settings .option-frame__label span {
  font-size: 14px;
}

.all-settings__frame.--ms-settings .option-frame__legend span {
  font-size: 12px;
}

@media screen and (max-width: 1600px) {
  .options__avatar span {
    font-size: 12px;
  }
  .options__profil p {
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  .macaroon-box span {
    font-size: 64px;
  }
  .options__profil p {
    font-size: 12px;
  }
}
@media screen and (max-width: 800px) {
  .advanced-settings .form__row .input__frame p {
    font-size: 11px;
  }
  .share-modal p {
    margin-block-start: 0.25rem;
    margin-block-end: 0.25rem;
  }
  .share-modal__icon-list .link-icon-wrapper span, .share-modal__icon-list .link-icon span {
    font-size: 9px;
    text-align: center;
  }
  .devroom .action-frame__mobile__options li {
    cursor: pointer;
  }
  .devroom .action-frame__mobile__options li span {
    color: rgba(255, 255, 255, 0.5333333333);
    transition: 0.2s ease;
  }
  .devroom .action-frame__mobile__options li span:hover {
    color: rgba(255, 255, 255, 0.9333333333);
  }
  .devroom .action-frame__mobile__options li:last-child {
    color: #A64FFF;
  }
  .devroom .action-frame__mobile__options li:last-child span {
    font-size: 17px;
  }
  .participant-fixed-option-box .action-frame__mobile__options li:last-child span {
    font-size: 13px;
  }
  .options__avatar span {
    font-size: 10px;
  }
}
.link-icon span {
  color: rgba(255, 255, 255, 0.5333333333);
}

.myselect__legend span, .myselect__bar__title {
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .myselect__legend span, .myselect__bar__title {
    font-size: 13px;
  }
}

@media screen and (min-width: 1920px) {
  .macaroon-box span {
    font-size: 5.6vw;
  }
}
h1, h2, h3, h4, h5 {
  font-weight: initial;
  user-select: none;
}

h1 {
  font-family: "Poppins Regular", sans-serif;
}

h2 {
  font-family: "Poppins Regular", sans-serif;
  margin-block: 0px;
}
h2 span {
  font-size: inherit;
}

h3 {
  font-family: "Open Sans Regular", sans-serif;
}

h4 {
  font-family: "Open Sans Regular", sans-serif;
  margin-block-start: 0.6rem;
  margin-block-end: 0.6rem;
}

h5 {
  font-family: "Open Sans Regular", sans-serif;
}

.share-modal h3, .contacts-modal h3, .meet-created-modal h3 {
  font-family: "Poppins Regular", sans-serif;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.9333333333);
  margin-block-start: 0;
  margin-block-end: 0;
}

.user-modal h4 {
  font-family: "Open Sans ExtraBold", sans-serif;
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9333333333);
  line-height: 22px;
}

.meet-created-modal .heading-frame {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .meet-created-modal .heading-frame {
    gap: 5px;
  }
}

.modale-setup h3 {
  font-size: 18px;
}

.devroom h1 {
  color: rgba(255, 255, 255, 0.5333333333);
}

.meeting-room-info h4 {
  font-family: "Open Sans Bold", sans-serif;
}

.navbar__body #text_meet_by_ringover {
  color: rgba(255, 255, 255, 0.9333333333);
  font-size: 18px;
  display: none;
}

.homepage h1, .homepage h2, .homepage h4 {
  color: rgba(255, 255, 255, 0.9333333333);
}
.homepage h1 {
  font-size: 42px;
}
.homepage h1 span {
  font-family: "Poppins Light", sans-serif;
  font-size: inherit;
}
.homepage h2 {
  font-size: 32px;
  margin-block-end: 0.5rem;
  margin-block-start: 0.5rem;
}
.homepage h2 span {
  font-size: inherit;
  font-family: inherit;
}
.homepage h4 {
  font-size: 16px;
  font-family: "Open Sans Regular", sans-serif;
}
.homepage .heading-frame h4 {
  color: rgba(255, 255, 255, 0.6);
}
.homepage .form-frame h4 {
  margin-block-start: 0;
  margin-block-end: 1rem;
  font-family: "Open Sans Bold", sans-serif;
}
.homepage h5 {
  color: rgba(255, 255, 255, 0.5333333333);
  font-size: 14px;
  width: fit-content;
  background-image: linear-gradient(-90deg, #A64FFF 0%, #6370E7 24%, rgba(255, 255, 255, 0.9333333333) 50%, white 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .homepage h5 {
    font-size: 13px;
  }
}
.homepage h5 span {
  font-size: inherit;
  font-family: "Open Sans Bold", sans-serif;
  background: -webkit-linear-gradient(-20deg, #A64FFF, #6370E7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homepage h5::after {
  content: "ok";
  transition: 0.4s ease;
  background-color: rgba(255, 255, 255, 0.5333333333);
  display: flex;
  height: 0.5px;
  width: 100%;
  opacity: 0;
}
.homepage h5:hover {
  animation: textclip 3.2s ease-in infinite;
}
.homepage h5:hover span {
  background: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}
.homepage h5:hover::after {
  opacity: 1;
}

.stats-panel h4 {
  font-size: 16px;
}

.share-modal h3, .contacts-modal h3, .preview-settings-modal h3 {
  color: rgba(255, 255, 255, 0.9333333333);
}

.advanced-settings .form__row .input__frame h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.advanced-settings h4 {
  font-size: 16px;
  font-family: "Open Sans Regular", sans-serif;
}

.room-not-found__body__header h2 {
  text-align: center;
  color: rgba(255, 255, 255, 0.9333333333);
}

.prejoin__left-panel .greetings-box h2,
.greetings-box__mobile h2 {
  color: rgba(255, 255, 255, 0.9333333333);
  text-align: center;
  text-transform: capitalize;
  font-size: 28px;
}

.chat-panel__upload-frame h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.message__box__heading {
  text-transform: capitalize;
}
.message__box__heading span {
  font-family: "Open Sans Bold", sans-serif;
  color: #72BBF1;
}

.participant-request h4 {
  color: rgba(255, 255, 255, 0.9333333333);
}

.record-modal h2::first-letter {
  text-transform: uppercase;
}
.record-modal h5 {
  font-family: "Open Sans Bold", sans-serif;
  font-size: 15px;
  margin-block: 0px;
}

.meeting-info-modale h2, .all-settings h2 {
  margin-block: unset;
}
.meeting-info-modale h4, .all-settings h4 {
  font-family: "Open Sans Bold", sans-serif;
  font-size: 15px;
  margin-block: unset;
}

.all-settings__frame.--ms-settings h2 {
  margin-block-start: 0.25rem;
  margin-block-end: 0.25rem;
}

@media screen and (max-width: 1600px) {
  .share-modal h3 {
    color: rgba(255, 255, 255, 0.9333333333);
  }
  .homepage h2 {
    font-size: 30px;
  }
  .homepage h1 {
    font-size: 36px;
  }
  .homepage h4,
  .meeting-room-info h4,
  .advanced-settings h4,
  .stats-panel h4 {
    font-size: 15px;
  }
  .modale-setup h3 {
    font-size: 18px;
  }
  .share-modal h3, .contacts-modal h3, .meet-created-modal h3 {
    font-size: 22px;
  }
  .prejoin__left-panel .greetings-box h2 {
    font-size: 24px;
  }
  .record-modal h2 {
    font-size: 22px;
  }
}
@media screen and (max-width: 1024px) {
  .homepage h2 {
    font-size: 28px;
  }
  .homepage h1 {
    font-size: 34px;
  }
  .homepage h4 {
    font-size: 15px;
  }
  .advanced-settings h4 {
    font-size: 14px;
  }
  .share-modal h3, .contacts-modal h3, .meet-created-modal h3 {
    font-size: 18px;
  }
  .preview-settings-modal h3 {
    font-size: 16px;
  }
  .modale-setup h3 {
    font-size: 15px;
  }
}
@media screen and (max-width: 800px) {
  #text_set_up_meet,
  .navbar__body #text_meet_by_ringover {
    display: block;
  }
  .homepage h1 {
    font-size: 28px;
  }
  .homepage h2 {
    font-size: 17px;
    display: none;
  }
  .homepage h4 {
    font-size: 14px;
  }
  .meeting-room-info h4 {
    font-size: 14px;
  }
  .modale-setup__body__heading h2 {
    font-size: 20px;
  }
  .greetings-box__mobile h2 {
    font-size: 22px;
  }
  .meeting-info-modale h4 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1921px) {
  .homepage h2 {
    font-size: 1.72vw;
  }
  .homepage h1 {
    font-size: 2.3vw;
  }
  .homepage h4 {
    font-size: 0.84vw;
  }
  .modale-setup h3 {
    font-size: 0.8vw;
  }
  #text_set_up_meet {
    font-size: 1.4vw;
  }
  h3 {
    font-size: 0.72vw;
  }
  .share-modal h3, .contacts-modal h3, .meet-created-modal h3 {
    font-size: 1.2vw;
  }
  .share-modal h3, .contacts-modal h3 {
    font-size: 1.5vw;
  }
}
ul li {
  font-family: "Open Sans Regular", sans-serif;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  ul li {
    font-size: 13px;
  }
}

ul {
  padding-inline-start: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

.participant-option-box ul,
.participant-panel__frame ul,
.participant-list__more-options {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0.75rem;
  gap: 10px;
}
@media screen and (max-width: 1024px) {
  .participant-option-box ul,
  .participant-panel__frame ul,
  .participant-list__more-options {
    padding: 0.55rem;
  }
}
.participant-option-box ul li:not(.disabled),
.participant-panel__frame ul li:not(.disabled),
.participant-list__more-options li:not(.disabled) {
  color: rgba(255, 255, 255, 0.9333333333);
  cursor: pointer;
}
.participant-option-box ul li:not(.disabled):hover,
.participant-panel__frame ul li:not(.disabled):hover,
.participant-list__more-options li:not(.disabled):hover {
  color: rgba(255, 255, 255, 0.5333333333);
}

.participant-option-box ul {
  gap: 0.65rem;
}

ul .disabled {
  color: rgba(255, 255, 255, 0.2666666667);
}

textarea {
  resize: none;
}

textarea,
::placeholder,
input, select, option {
  font-size: 14px;
  font-family: "Open Sans Regular", sans-serif;
  border-radius: 5px;
}
@media screen and (max-width: 800px) {
  textarea,
  ::placeholder,
  input, select, option {
    font-size: 13px;
  }
}

input[type=number] {
  font-size: 20px;
  font-family: "Open Sans Bold", sans-serif;
}

input[type=time] {
  border: 2px solid #ccc;
  padding: 8px;
  font-size: 16px;
  color: #333;
  background-color: #242129;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

input[type=time]:focus {
  outline: none;
  border-color: #5c5cff;
  box-shadow: 0 0 5px rgba(92, 92, 255, 0.5);
}

input[type=submit] {
  -webkit-appearance: none;
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: unset;
}

::placeholder {
  color: rgba(255, 255, 255, 0.2666666667);
}

select, option {
  width: fit-content;
  color: rgba(255, 255, 255, 0.9333333333);
  padding: 0.75rem;
  appearance: initial;
}
@media screen and (max-width: 1024px) {
  select, option {
    padding: 0.55rem;
  }
}

.input-frame__input, .input-frame textarea {
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.9333333333);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
}

.basic-settings .form__row__block select, .basic-settings .form__row__block option {
  background-color: #242129;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  width: 100%;
}
.basic-settings .form__row__block select {
  background: rgba(255, 255, 255, 0.0862745098);
}

.homepage .form__body .input-frame input, .prejoin .form__body .input-frame input {
  background: unset;
  color: rgba(255, 255, 255, 0.9333333333);
  border: unset;
  height: auto;
  position: absolute;
  text-indent: 3.8rem;
  width: inherit;
  height: 48px;
}
.homepage .form__body .input-frame svg, .homepage .form__body .input-frame img, .prejoin .form__body .input-frame svg, .prejoin .form__body .input-frame img {
  position: absolute;
  margin: auto 1.25rem;
  width: 25px;
  height: 25px;
}

.prejoin .form__body .input-frame {
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .prejoin .form__body .input-frame {
    padding: 0.55rem;
  }
}
.prejoin .form__body .input-frame input {
  position: relative;
}

.input-frame.input-searchbar {
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  height: 100%;
  position: relative;
}
.input-frame.input-searchbar input {
  background: unset;
  border: unset;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  height: 100%;
  text-indent: 50px;
  width: inherit;
}
.input-frame.input-searchbar svg, .input-frame.input-searchbar img {
  position: absolute;
  margin: auto 10px auto 15px;
  width: 24px;
  height: 24px;
}

.HourPicker input {
  background-color: #242129;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  padding: 0.75rem;
  color: rgba(255, 255, 255, 0.9333333333);
  width: 18%;
}
@media screen and (max-width: 1024px) {
  .HourPicker input {
    padding: 0.55rem;
  }
}
.HourPicker input[type=submit] {
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
}

.chat-panel__dialog-action .input-frame {
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  width: 100%;
}
.chat-panel__dialog-action .input-frame textarea {
  background: unset;
  border: unset;
  font-family: "EmojiMart";
  width: 100%;
  height: 42px;
  resize: none;
  z-index: 0;
  color: rgba(255, 255, 255, 0.9333333333);
  font-size: 16px;
  border-radius: unset;
  max-height: 122px;
}
.chat-panel__dialog-action .input-frame .overlay {
  height: 100%;
  display: flex;
  right: 0;
  gap: 10px;
  width: auto;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px 8px 10px;
}
.chat-panel .label-file {
  background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
  border-radius: 5px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5333333333);
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .chat-panel .label-file {
    padding: 0.55rem;
  }
}
.chat-panel .label-file:hover {
  color: rgba(255, 255, 255, 0.9333333333);
}
.chat-panel .input-file {
  display: none;
}

.external-box__textarea {
  background: unset;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  height: 50%;
  padding: 0.5rem;
  width: 100%;
  resize: none;
  z-index: 0;
  color: rgba(255, 255, 255, 0.9333333333);
  border-radius: unset;
}

.input-link.input-frame textarea, .input-link.input-frame input, .input-link.input-frame ::placeholder {
  color: rgba(255, 255, 255, 0.5333333333);
}

.meeting-details-box__container__content .input-link.input-frame input::placeholder {
  color: rgba(255, 255, 255, 0.9333333333);
}

@media screen and (max-width: 1600px) {
  .homepage .form__body .input-frame input {
    height: 46px;
  }
}
@media screen and (max-width: 1024px) {
  .chat-panel__dialog-action .input-frame textarea {
    height: 40px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
  .homepage .form__body .input-frame input {
    width: 100%;
  }
  .input-link.input-frame textarea, .input-link.input-frame input, .input-link.input-frame ::placeholder {
    font-size: 11px;
  }
}
@media screen and (min-width: 1921px) {
  .homepage .form__body .input-frame input {
    font-size: 0.72vw;
    height: 2.6vw;
    text-indent: 3.2vw;
  }
  .homepage .form__body .input-frame svg, .homepage .form__body .input-frame img {
    margin: auto 1vw;
    width: 1.25vw;
    height: 1.25vw;
  }
  .homepage .form__body ::placeholder {
    font-size: 0.72vw;
  }
}
.input-link.input-frame {
  position: relative;
}
.input-link.input-frame ::placeholder {
  color: rgba(255, 255, 255, 0.5333333333);
}
.input-link.input-frame .icon-frame {
  position: absolute;
  top: 0;
  width: auto;
  height: 100%;
  gap: 0.5rem;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .input-link.input-frame .icon-frame {
    padding: 0.55rem;
  }
}
.input-link.input-frame textarea {
  width: 100%;
  padding-right: 5rem;
  letter-spacing: 0.02rem;
}

.input-link.input-frame.input-textarea {
  display: flex;
}
.input-link.input-frame.input-textarea .icon-frame {
  align-items: flex-end;
}

.input-frame__input {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .input-link.input-frame .icon-frame img {
    width: auto;
  }
}
.input-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .input-checkbox {
    gap: 5px;
  }
}
.input-checkbox input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(-120deg, #A64FFF 13.05%, #6370E7 81.16%);
  border-radius: 6px;
  color: currentColor;
  cursor: pointer;
  display: grid;
  font: inherit;
  height: 20px;
  width: 20px;
  margin: 0;
  place-content: center;
  transform: translateY(-0.075em);
}
.input-checkbox input[type=checkbox]:after {
  content: "";
  background-color: rgba(36, 33, 41, 0.8941176471);
  height: 18px;
  width: 18px;
  border-radius: 6px;
  position: absolute;
  left: 1px;
  top: 1px;
}
.input-checkbox input[type=checkbox]:before {
  box-shadow: inset 1em 1em #A64FFF;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
  content: "";
  height: 13px;
  width: 13px;
  padding: 0px;
  transform: scale(0);
  transform-origin: bottom left;
  transition: transform 0.05s linear;
  z-index: 99;
}
.input-checkbox input[type=checkbox]:checked:before {
  transform: scale(1);
}

.input-checkbox.--alt input[type=checkbox] {
  background: rgba(255, 255, 255, 0.0666666667);
  border-radius: 6px;
  border-color: transparent;
  height: 32px;
  width: 40px;
  place-content: center;
  transform: translateY(-0.075em);
}
.input-checkbox.--alt input[type=checkbox]:after {
  content: "";
  background-color: #242129;
  height: 30px;
  width: 38px;
  left: 1px;
  top: 1px;
}
.input-checkbox.--alt input[type=checkbox]:before {
  box-shadow: inset 1em 1em rgba(255, 255, 255, 0.5333333333);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
  height: 16px;
  width: 16px;
}

.participant-request .input-checkbox input[type=checkbox] {
  background: transparent;
  border-radius: 2px;
}
.participant-request .input-checkbox input[type=checkbox]:after {
  content: "";
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.9333333333);
  border-radius: 2px;
  left: 0px;
  top: 1px;
}
.participant-request .input-checkbox input[type=checkbox]:before {
  box-shadow: inset 1em 1em rgba(255, 255, 255, 0.9333333333);
  height: 12px;
  width: 12px;
}

.option-radio {
  border: 1px solid rgba(114, 187, 241, 0.3764705882);
  border-radius: 5px;
  padding: 0.75rem;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .option-radio {
    padding: 0.55rem;
  }
}
.option-radio input[type=radio] {
  opacity: 0.1;
  appearance: none;
  background-color: rgba(114, 187, 241, 0.1254901961);
  position: absolute;
  padding: 0;
  z-index: 0;
  cursor: pointer;
  margin: 0;
  border: unset;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  transition: 0.16s ease;
}
.option-radio input[type=radio]:hover {
  background-color: rgba(114, 187, 241, 0.2509803922);
}
.option-radio input[type=radio]:checked {
  opacity: 0.3;
  background-color: rgba(114, 187, 241, 0.2509803922);
}

@media screen and (max-width: 800px) {
  .input-checkbox input[type=checkbox] {
    margin-top: 2.4px;
  }
}
.option-frame input[type=radio] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 1.25px solid #6370E7;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-content: center;
}
.option-frame input[type=radio]::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  background: linear-gradient(45deg, #A64FFF, #6370E7);
}
.option-frame input[type=radio]:checked::before {
  transform: scale(1);
}

.all-settings .option-frame__legend span {
  color: rgba(255, 255, 255, 0.5333333333);
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .all-settings .option-frame__legend span {
    font-size: 13px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.typecode-frame__body .box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .typecode-frame__body .box {
    gap: 5px;
  }
}
.typecode-frame__body .box input[type=number] {
  width: 48px;
  height: 48px;
  text-align: center;
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  border: 2px solid rgba(255, 255, 255, 0.0862745098);
  color: rgba(255, 255, 255, 0.9333333333);
}

label {
  font-size: 14px;
  font-family: "Open Sans Regular", sans-serif;
  color: rgba(255, 255, 255, 0.9333333333);
}
@media screen and (max-width: 800px) {
  label {
    font-size: 13px;
  }
}

.prejoin__left-panel .form__header label {
  font-size: 14px;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .prejoin__left-panel .form__header label {
    font-size: 13px;
  }
}

.preview-settings-modal label {
  cursor: pointer;
}
.preview-settings-modal label::first-letter {
  text-transform: uppercase;
}

.check-frame label {
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .prejoin__left-panel .form__header label {
    font-size: 13px;
  }
}
@media screen and (max-width: 800px) {
  .prejoin__left-panel .form__header label {
    font-size: 12px;
  }
  .preview-settings-modal label {
    font-size: 11px;
  }
}
@media screen and (min-width: 1921px) {
  .prejoin__left-panel .form__header label {
    font-size: 0.68vw;
  }
}
.square-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
}

.Collapsible table .square-pill, .Collapsible table .round-pill {
  width: 40px;
  height: 40px;
}

.square-pill {
  border-radius: 26px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2509803922), rgba(134, 89, 122, 0.2509803922));
  padding: 2px;
  overflow: visible;
  position: relative;
}
.square-pill__frame {
  padding: 1rem;
  background: linear-gradient(30deg, rgba(22, 32, 37, 0.768627451), rgba(19, 18, 31, 0.768627451));
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.square-pill .notification {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: linear-gradient(#FF8888, #F05F5F);
  transition: all 0.8s linear;
  top: -4px;
  z-index: 99;
  margin-right: -60%;
}
.square-pill svg {
  width: 28px;
  height: 28px;
}

.round-pill {
  cursor: pointer;
  border-radius: 50%;
  border: unset;
  user-select: none;
  display: flex;
}
.round-pill img {
  width: 64px;
  height: 64px;
  aspect-ratio: 1/1;
}
@media screen and (max-width: 1600px) {
  .round-pill img {
    width: 52px;
    height: 52px;
  }
}
@media screen and (max-width: 1024px) {
  .round-pill img {
    width: 48px;
    height: 48px;
  }
}
@media screen and (max-width: 800px) {
  .round-pill img {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 1921px) {
  .round-pill img {
    width: 3.2vw;
    height: 3.2vw;
  }
}

.round-pill.avatar img, .round-pill.avatar .macaroon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.participant-list .round-pill.avatar img, .participant-list .round-pill.avatar .macaroon {
  width: 40px;
  height: 40px;
}

.message__box .round-pill.avatar img {
  width: 20px;
  height: 20px;
}

.options__avatar .round-pill.avatar img {
  width: 64px;
  height: 64px;
  aspect-ratio: 1/1;
}
@media screen and (max-width: 1600px) {
  .options__avatar .round-pill.avatar img {
    width: 52px;
    height: 52px;
  }
}
@media screen and (max-width: 1024px) {
  .options__avatar .round-pill.avatar img {
    width: 48px;
    height: 48px;
  }
}
@media screen and (max-width: 800px) {
  .options__avatar .round-pill.avatar img {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 1921px) {
  .options__avatar .round-pill.avatar img {
    width: 3.2vw;
    height: 3.2vw;
  }
}

.avatar .round-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
}

.share-modal__clipped {
  position: fixed;
  bottom: 2%;
  transition: 0.4s linear all;
  z-index: 999;
}
.share-modal__clipped .pill {
  background: rgba(255, 255, 255, 0.0862745098);
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-radius: 32px;
  padding: 0.75rem 1.25rem;
}
.share-modal__clipped .pill p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.visual-effect-box .pill-carousel__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .visual-effect-box .pill-carousel__item {
    gap: 5px;
  }
}
.visual-effect-box .pill-carousel__item__content {
  width: 96px;
  height: 96px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  background-color: #2f2a38;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transition: 0.32s ease all;
  cursor: pointer;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: translateZ(0);
}
.visual-effect-box .pill-carousel__item input[type=file] {
  -webkit-appearance: unset;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.random-color__pill, .upload-profile__pill {
  width: 66px;
  height: 66px;
  background-color: #2f2a38;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  text-align: center;
  border-radius: 50%;
  text-transform: uppercase;
  text-shadow: 1px 1px 0px #242129;
  font-size: smaller;
  user-select: none;
  cursor: pointer;
}
.random-color__pill::before, .upload-profile__pill::before {
  content: "";
  position: absolute;
  width: 68px;
  height: 68px;
  background: linear-gradient(45deg, #A64FFF, #6370E7);
  z-index: -1;
  position: absolute;
  border-radius: 50%;
}

.random-color__pill {
  position: relative;
}

.upload-profile__pill {
  background: linear-gradient(-120deg, #823CCA, #4C57BB);
  width: 64px;
  height: 64px;
}
.upload-profile__pill input[type=file] {
  position: absolute;
  width: inherit;
  height: inherit;
  opacity: 0;
  cursor: pointer;
}
.upload-profile__pill::before {
  width: 68px;
  height: 68px;
}
.upload-profile__pill.--preview {
  background-image: url(assets/pictures/upload-profile-default.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 120%;
  cursor: initial;
}

.random-color__pill span {
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.palette {
  position: absolute;
  left: -116px;
  bottom: 0;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 1s;
  z-index: 99;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.palette.active {
  opacity: 1;
  left: 0px;
  animation: logoRotate 8s infinite;
}

.palette div {
  position: absolute;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: 0.6s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  transform: translate(100px, 0px);
  border: 1px solid #242129;
  margin: auto;
}

.palette.active #pal1 {
  transform: translateX(0%) translateY(-250%);
}
.palette.active #pal2 {
  transform: translateX(0%) translateY(250%);
}
.palette.active #pal3 {
  transform: translateX(250%) translateY(0%);
}
.palette.active #pal4 {
  transform: translateX(-250%) translateY(0%);
}
.palette.active #pal5 {
  transform: translateX(188%) translateY(188%);
}
.palette.active #pal6 {
  transform: translateX(188%) translateY(-188%);
}
.palette.active #pal7 {
  transform: translateX(-188%) translateY(-188%);
}
.palette.active #pal8 {
  transform: translateX(-188%) translateY(188%);
}

@media screen and (max-width: 1600px) {
  .share-modal__clipped .pill {
    padding: 0.65rem 1rem;
  }
  .random-color__pill {
    width: 64px;
    height: 64px;
    font-size: 12px;
  }
  .random-color__pill::before {
    width: 68px;
    height: 68px;
  }
}
@media screen and (max-width: 1024px) {
  .square-pill__frame {
    padding: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .square-pill {
    background: unset;
  }
  .square-pill__frame {
    background: linear-gradient(45deg, #A64FFF, #6370E7);
  }
  .round-pill {
    width: auto;
    height: auto;
  }
  .round-pill.avatar img {
    width: 32px;
    height: 32px;
    opacity: 1;
  }
  .basic-settings .round-pill {
    width: auto;
    height: auto;
  }
  .basic-settings .round-pill img {
    padding: 0;
  }
  .square-pill {
    border-radius: 50%;
    width: auto;
    height: auto;
  }
  .square-pill__frame {
    border-radius: 50%;
    padding: 0.75rem;
  }
  .square-pill__frame svg {
    height: 24px;
    width: 24px;
  }
  .Collapsible table .square-pill, .Collapsible table .round-pill {
    width: 32px;
    height: 32px;
  }
  .visual-effect-box .pill-carousel__item__content {
    width: 72px;
    height: 72px;
  }
  .random-color__pill, .upload-profile__pill {
    width: 56px;
    height: 56px;
    font-size: 10px;
  }
  .random-color__pill::before, .upload-profile__pill::before {
    width: 60px;
    height: 60px;
  }
}
@media screen and (min-width: 1921px) {
  .square-pill {
    width: 3vw;
    height: 3vw;
  }
  .square-pill svg {
    width: 40px;
    height: 40px;
  }
  .square-pill__frame {
    padding: 1.5rem;
  }
}
.logotype {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.logotype svg {
  display: flex;
  transition: 0.24s ease;
  width: 64px;
  height: 64px;
  aspect-ratio: 1/1;
}
@media screen and (max-width: 1600px) {
  .logotype svg {
    width: 52px;
    height: 52px;
  }
}
@media screen and (max-width: 1024px) {
  .logotype svg {
    width: 48px;
    height: 48px;
  }
}
@media screen and (max-width: 800px) {
  .logotype svg {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 1921px) {
  .logotype svg {
    width: 3.2vw;
    height: 3.2vw;
  }
}

@media screen and (max-width: 800px) {
  .logotype svg {
    animation: unset;
  }
}
.scroll-bar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.scroll-bar::-webkit-scrollbar {
  overflow: hidden;
}
.scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px transparent;
}
.scroll-bar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.0862745098);
  box-sizing: border-box; /* Inclure la bordure dans la taille */
}
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.031372549);
}

.scroll-bar {
  overflow: hidden;
}

*::-webkit-scrollbar {
  width: 0px;
}

.scroll-bar.invisible::-webkit-scrollbar {
  width: 0px;
}

/* Pour Mozilla Firefox */
body {
  scrollbar-color: rgba(255, 255, 255, 0.0705882353) rgba(255, 255, 255, 0.0156862745);
}

/* Désactiver l'élargissement du thumb lorsqu'il est survolé */
body::-moz-scrollbar-thumb:hover {
  min-width: inherit !important;
  max-width: inherit !important;
}

@media screen and (max-width: 800px) {
  .scroll-bar::-webkit-scrollbar {
    width: 4px;
  }
}
.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.0862745098);
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-radius: 5px;
  padding: 0.5rem;
}
.user-item__profil {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .user-item__profil {
    gap: 5px;
  }
}
.user-item__profil__picture {
  border-radius: 50%;
}
.user-item__is-to-add {
  background: rgba(255, 255, 255, 0.031372549);
}
.user-item__is-added {
  background: linear-gradient(135deg, rgba(54, 205, 207, 0.5) 0%, rgba(64, 224, 207, 0.5) 100%);
}

@media screen and (max-width: 800px) {
  .user-item__operate {
    background-color: plum;
    display: none;
  }
}
.exit-frame img {
  cursor: pointer;
  transform-origin: center;
}
.exit-frame img:hover {
  transform: scale(0.92);
}

.action-frame__mobile__separator,
.share-modal__separator, .meet-created-modal__separator {
  display: none;
}
.action-frame__mobile__separator hr,
.share-modal__separator hr, .meet-created-modal__separator hr {
  border: unset;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5333333333);
  width: 25%;
}

.bright-stroke {
  background-color: white !important;
  filter: brightness(8) !important;
}

.devroom__option-panel hr {
  height: 30px;
  width: 0px;
  border: unset;
  border-left: 1.5px solid rgba(255, 255, 255, 0.2);
}

.upload-frame__separator {
  display: flex;
  width: 100%;
}
.upload-frame__separator hr {
  width: 72%;
  border: unset;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5333333333);
}

.action-frame__mobile__separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24%;
  height: 48px;
  margin: auto;
}
.action-frame__mobile__separator hr {
  border: unset;
  width: 100%;
  height: 1px;
  cursor: pointer;
}
.action-frame hr {
  margin-left: 8px;
  margin-right: 8px;
}

.devroom .action-frame__mobile__bottom hr {
  height: 40px;
  border: unset;
  width: 1px;
  background: rgba(255, 255, 255, 0.5333333333);
}

@media screen and (max-width: 800px) {
  .share-modal__separator, .meet-created-modal__separator {
    display: flex;
    min-height: 48px;
    align-items: center;
  }
}
.prejoin .option-layer img {
  border-radius: 50%;
  border: 5px solid transparent;
}
.prejoin .option-layer span {
  display: flex;
  width: auto;
  height: auto;
  border-radius: 50%;
  border: 0px solid transparent;
  transform-origin: center;
  padding: 6px;
}
.prejoin .option-layer .no {
  padding: 6px;
  border: 0px solid transparent;
}
.prejoin .option-layer .regular {
  border: 1px solid rgba(114, 187, 241, 0.1960784314);
  padding: 5px;
}
.prejoin .option-layer .regular img {
  border: 5px solid rgba(114, 187, 241, 0.1960784314);
}
.prejoin .option-layer .medium {
  border: 2px solid rgba(114, 187, 241, 0.2823529412);
  padding: 4px;
}
.prejoin .option-layer .medium img {
  border: 5px solid rgba(114, 187, 241, 0.2823529412);
}
.prejoin .option-layer .high {
  border: 3px solid rgba(114, 187, 241, 0.6431372549);
  padding: 3px;
}
.prejoin .option-layer .high img {
  border: 5px solid rgba(114, 187, 241, 0.6431372549);
}

.devroom__mosaic__screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0rem;
  padding: 0;
  height: max-content;
  height: -moz-available;
  aspect-ratio: 16/9;
  width: 100%;
  background-color: #242129;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  user-select: none;
}
@-moz-document url-prefix() {
  .devroom__mosaic__screen {
    overflow: initial;
  }
}
.devroom__mosaic__screen .portrait {
  width: 100%;
  display: flex;
  height: 100%;
  margin: 0;
  margin-inline: unset;
  overflow: hidden;
}
.devroom__mosaic__screen .portrait img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.devroom__mosaic__screen__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  color: rgba(255, 255, 255, 0.9333333333);
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .devroom__mosaic__screen__overlay {
    padding: 0.55rem;
  }
}
.devroom__mosaic__screen__overlay.--bottom {
  align-items: flex-end;
  margin: auto auto 0;
  bottom: 0;
  height: auto;
}
.devroom__mosaic__screen__overlay.--bottom .container {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
.devroom__mosaic__screen__overlay.--bottom .container div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom__mosaic__screen__overlay.--bottom .container div {
    gap: 5px;
  }
}
.devroom__mosaic__screen.--talking {
  border: 4px solid #72BBF1;
}

.featured-frame .devroom__mosaic__screen {
  overflow: hidden;
  max-height: -webkit-fill-available;
  border: 4px solid #72f1c0;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .featured-frame .devroom__mosaic__screen {
    height: 100%;
    max-height: -webkit-fill-available;
    min-height: 100%;
    border-width: 3px;
  }
  .devroom__mosaic__screen.--talking {
    border-width: 3px;
  }
  .devroom__mosaic__screen .portrait img {
    border-radius: 6px;
  }
}
.Alert {
  width: 256px;
  z-index: 10;
  top: 0;
  margin: auto;
  padding: 16px;
  border: 1px solid black;
  background-color: white;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3);
  margin-top: 48px;
  border-radius: 4px;
  font-family: "Courier New", Courier, monospace;
}

.Alert button {
  padding: 4px;
  border-radius: 3px;
  border: none;
  width: 72px;
  font-weight: bold;
}

button#ok {
  background-color: blue;
  color: white;
}

button#ok:hover {
  background-color: rgb(47, 47, 255);
}

.Alert .Actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 0px 0px 0px;
}

.Alert h1 {
  font-size: 21px;
}

.Alert h3 {
  font-size: 12px;
}

.Backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  user-select: none;
}

.Collapsible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.Collapsible__contentInner {
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.Collapsible__trigger {
  display: block;
  position: relative;
  padding-left: 0vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all ease 300ms;
}
.Collapsible__trigger:after {
  content: "";
  position: absolute;
  background-image: url("./assets/icons/arrow-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 28px;
  right: 0vw;
  top: 0;
  display: block;
  transition: transform 100ms;
}
.Collapsible__trigger.is-open:after {
  transform: scaleY(-1);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.Collapsible__trigger {
  margin-block-end: 0;
  margin-block-start: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.stats-panel .Collapsible__trigger {
  justify-content: center;
}

.stats-panel .Collapsible__contentInner,
.stats-panel .Collapsible__trigger {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.stats-panel .Collapsible__contentInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .stats-panel .Collapsible__contentInner {
    gap: 5px;
  }
}

.is-open + .Collapsible__contentOuter {
  overflow: visible !important;
}

@media screen and (max-width: 800px) {
  .Collapsible__trigger:after {
    background-size: contain;
    width: 24px;
  }
  .Collapsible__trigger {
    padding-top: unset;
    padding-bottom: unset;
    padding-right: unset;
    height: 48px;
  }
}
table, td, tr, caption {
  font-family: "Open Sans Regular", sans-serif;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  table, td, tr, caption {
    font-size: 13px;
  }
}

.basic-settings caption {
  caption-side: bottom;
  text-align: start;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media screen and (max-width: 1024px) {
  .basic-settings caption {
    padding: 0.55rem;
  }
}
.basic-settings table {
  width: 100%;
  margin: auto;
}
.basic-settings table td {
  text-align: center;
  padding: 0.5rem 0;
}
.basic-settings table td:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.basic-settings table td:nth-child(1) span {
  font-family: "Open Sans Bold", sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
  overflow: hidden;
  width: 200px;
}
.basic-settings table th {
  color: rgba(255, 255, 255, 0.5333333333);
  font-weight: initial;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .basic-settings table th {
    font-size: 13px;
  }
}
.basic-settings table tbody tr td:nth-child(2),
.basic-settings table tbody tr td:nth-child(3) {
  width: 14%;
}

@media screen and (max-width: 1600px) {
  .basic-settings table th {
    font-size: 14px;
  }
}
@media screen and (max-width: 1600px) and (max-width: 800px) {
  .basic-settings table th {
    font-size: 13px;
  }
}
@media screen and (max-width: 800px) {
  .basic-settings table th {
    font-size: 12px;
  }
}
@media screen and (min-width: 1921px) {
  .basic-settings table td:nth-child(1) {
    gap: 1vw;
  }
}
.prejoin .greetings-box__mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .prejoin__left-panel .greetings-box {
    display: none;
  }
  .prejoin .greetings-box__mobile {
    display: block;
  }
}
.input-box {
  font-family: "Open Sans Regular", sans-serif;
  width: auto;
}
.input-box__value {
  padding: 0.75rem;
  padding: 0.65rem;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  background-color: #27252b;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  color: rgba(255, 255, 255, 0.9333333333);
  min-width: 160px;
}
@media screen and (max-width: 1024px) {
  .input-box__value {
    padding: 0.55rem;
  }
}
.input-box__value span {
  display: flex;
}
.input-box__options {
  position: relative;
  height: auto;
  min-height: auto;
  width: 100%;
}
.input-box__options ul {
  position: absolute;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-top: 0.75rem;
  z-index: 99;
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
  list-style-type: none;
  padding-inline-start: 0;
  min-width: 220px;
  text-align: start;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-radius: 5px;
  background: #27252b;
}
.input-box__options ul li {
  padding: 0.75rem;
  font-size: 14px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.9333333333);
}
@media screen and (max-width: 1024px) {
  .input-box__options ul li {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .input-box__options ul li {
    font-size: 13px;
  }
}
.input-box__options ul li:hover {
  background: #242129;
}

.audio-video-box .input-box__options ul {
  overflow: hidden;
}

.preview-settings-modal .input-box__value {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.0705882353), 0px 1px 2px 2px rgba(0, 0, 0, 0.0862745098);
}

@media screen and (max-width: 800px) {
  .input-box__options ul {
    min-width: 160px;
    margin-top: 0.5rem;
  }
}
.typecode-frame__footer .notif-frame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .typecode-frame__footer .notif-frame {
    gap: 5px;
  }
}
.typecode-frame__footer .notif-frame p {
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .typecode-frame__footer .notif-frame p {
    font-size: 13px;
  }
}
.typecode-frame__footer .notif-frame.success p {
  background: -webkit-linear-gradient(-20deg, #3696CF, #40E0CF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.typecode-frame__footer .notif-frame.danger p {
  background: -webkit-linear-gradient(-20deg, #FF8888, #F05F5F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#participant-panel-notif.dot {
  position: absolute;
}

.dot {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.dot.--danger {
  background: radial-gradient(at center, #FF8888, #F05F5F);
  right: 2px;
  top: -2px;
}

.macaroon-box__frame {
  background-color: goldenrod;
  width: 25%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.devroom__mosaic .macaroon-box {
  height: auto;
}
.devroom__mosaic .macaroon-box__frame {
  height: auto;
  min-width: 6.8vw;
}
.devroom__mosaic .macaroon-box span {
  font-size: 2.6vw;
  text-indent: 8px;
}

.devroom__mosaic.--twenty-participants .devroom__mosaic__screen .macaroon-box__frame {
  min-width: 4.4vw;
}
.devroom__mosaic.--twenty-participants .devroom__mosaic__screen .macaroon-box span {
  font-size: 2vw;
}

.featured-frame .devroom__mosaic__screen .macaroon-box__frame {
  width: 13vw;
  height: 13vw;
}

.devroom__body__main.--flex-layout .devroom__mosaic .macaroon-box__frame {
  min-width: 5.6vw;
}

@media screen and (max-width: 1600px) {
  .devroom__mosaic .macaroon-box__frame {
    min-width: 7.2vw;
  }
}
@media screen and (max-width: 1024px) {
  .macaroon-box__frame {
    width: 144px;
    height: 144px;
  }
  .devroom__mosaic .macaroon-box__frame {
    min-width: 8vw;
    height: initial;
    width: initial;
  }
  .devroom__mosaic .macaroon-box__frame span {
    text-indent: 2px;
  }
  .featured-frame .devroom__mosaic__screen .macaroon-box__frame {
    width: 140px;
    height: 140px;
  }
}
@media screen and (max-width: 800px) {
  .devroom__mosaic .macaroon-box__frame {
    min-width: 12vw;
  }
  .devroom__body__main.--flex-layout .devroom__mosaic .macaroon-box__frame {
    min-width: 9.6vw;
  }
  .devroom__mosaic.--twenty-participants .devroom__mosaic__screen .macaroon-box__frame {
    min-width: 12vw;
  }
  .devroom__mosaic.--twenty-participants .devroom__mosaic__screen .macaroon-box span {
    font-size: 3vw;
  }
}
@media screen and (min-width: 1920px) {
  .macaroon-box__frame {
    width: 20%;
  }
}
.pill-carousel__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.pill-carousel__controls .prev-button, .pill-carousel__controls .next-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-120deg, #A64FFF, #6370E7);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 5px;
  padding: 0;
  height: 24px;
  width: 24px;
}
.pill-carousel__controls .prev-button svg, .pill-carousel__controls .next-button svg {
  width: 16px;
  height: 16px;
}

.modale-setup__body__heading,
.chat-panel__heading,
.participant-panel__heading,
.screen-layout-panel__heading,
.visual-effects-panel__heading,
.stats-panel__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-items: center;
}
.modale-setup__body__heading .exit-frame,
.chat-panel__heading .exit-frame,
.participant-panel__heading .exit-frame,
.screen-layout-panel__heading .exit-frame,
.visual-effects-panel__heading .exit-frame,
.stats-panel__heading .exit-frame {
  display: flex;
}
.modale-setup__body__heading .exit-frame img,
.chat-panel__heading .exit-frame img,
.participant-panel__heading .exit-frame img,
.screen-layout-panel__heading .exit-frame img,
.visual-effects-panel__heading .exit-frame img,
.stats-panel__heading .exit-frame img {
  cursor: pointer;
}

.chat-panel .message__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.chat-panel .message__box {
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 15px;
  gap: 10px;
  width: auto;
  max-width: 70%;
}
.chat-panel .message__box__author {
  color: rgba(255, 255, 255, 0.5333333333);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.chat-panel .message__box__author span {
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .chat-panel .message__box__author span {
    font-size: 13px;
  }
}
.chat-panel .message__box__author .round-pill span {
  font-size: 10px;
}
.chat-panel .message__box__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .chat-panel .message__box__body {
    gap: 5px;
  }
}
.chat-panel .message__box__heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.chat-panel .message__box__options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .chat-panel .message__box__options {
    gap: 5px;
  }
}
.chat-panel .message__box__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5333333333);
  font-size: smaller;
}
.chat-panel .message__row {
  gap: 0.5rem;
}
.chat-panel .message__row .sending-time span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5333333333);
}
.chat-panel .message__row.--right {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
}
.chat-panel .message__row.--right .message__box {
  background-color: rgba(255, 255, 255, 0.1333333333);
  border-radius: 16px 16px 4px 16px;
}
.chat-panel .message__row.--right .message__info {
  align-items: flex-end;
}
.chat-panel .message__row.--right.--following .message__box {
  border-radius: 16px 4px 4px 16px;
}
.chat-panel .message__row.--right.--last .message__box {
  border-radius: 16px 4px 16px 16px;
}
.chat-panel .message__row.--left {
  justify-content: flex-start;
}
.chat-panel .message__row.--left .message__box {
  background-color: rgba(255, 255, 255, 0.0666666667);
  border-radius: 16px 16px 16px 4px;
}
.chat-panel .message__row.--left.--following .message__box {
  border-radius: 4px 16px 16px 4px;
}
.chat-panel .message__row.--left.--last .message__box {
  border-radius: 4px 16px 16px 16px;
}
.chat-panel .message__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: none;
}
.chat-panel .message__info .options {
  display: flex;
}
.chat-panel .message__row.--sondage .message__box {
  background: linear-gradient(rgba(114, 187, 241, 0.0862745098), rgba(75, 144, 226, 0.0862745098));
}
.chat-panel .message__box.--sondage {
  min-width: 70%;
}
.chat-panel .message__row:hover .message__info {
  display: flex;
}

.chat-panel .progress-upload {
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr auto;
  gap: 0.75rem;
  align-items: center;
}
.chat-panel .progress-upload__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.5rem;
}
.chat-panel .progress-upload__picto, .chat-panel .progress-upload__cross {
  display: flex;
}
.chat-panel .progress-upload__cross img {
  border: 1px solid rgba(255, 255, 255, 0.9333333333);
  border-radius: 50%;
  padding: 0.16rem;
}

.progress-upload-bar {
  display: flex;
  position: relative;
}
.progress-upload-bar__value {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.progress-upload-bar span {
  font-size: 9px;
  line-height: 0;
  height: -webkit-fill-available;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
  width: 250px;
  height: 6px;
}

progress[value]::-webkit-progress-bar {
  border-radius: 12px;
  height: 6px;
  overflow: hidden;
  background: linear-gradient(45deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
  border: 1px solid rgba(255, 255, 255, 0.0666666667);
}

progress[value]::-webkit-progress-value {
  background-color: #A64FFF;
}

.pill-stats {
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 5px;
  position: relative;
}
.pill-stats__content {
  position: static;
  padding: 0.75rem;
  background: linear-gradient(90deg, rgba(114, 187, 241, 0.6431372549), rgba(75, 144, 226, 0.6431372549));
  display: flex;
}
@media screen and (max-width: 1024px) {
  .pill-stats__content {
    padding: 0.55rem;
  }
}
.pill-stats__content span:nth-of-type(2) {
  position: absolute;
  right: 0.75rem;
}

.action-frame span.tooltip,
.devroom__option-panel .info-box .tooltip,
.tooltip {
  position: absolute;
  font-size: 11px;
  padding: 0.1rem;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  z-index: 99;
  background-color: rgba(36, 33, 41, 0.2823529412);
  color: rgba(255, 255, 255, 0.5333333333);
  border-radius: 4px;
  width: auto;
  text-align: center;
}

.tooltip::first-letter {
  text-transform: uppercase;
}

@media screen and (min-width: 1920px) {
  .tooltip {
    font-size: 0.58vw;
  }
}
.tab {
  display: flex;
  justify-content: space-around;
  height: 2rem;
}
.tab > * {
  flex: 1;
  height: 100%;
  line-height: 1rem;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.tab__no-active {
  font-family: "Open Sans Regular", sans-serif;
  color: rgba(255, 255, 255, 0.5333333333);
  border-image-source: linear-gradient(240.94deg, rgba(166, 79, 255, 0.2) 13.05%, rgba(99, 112, 231, 0.2) 81.16%);
}
.tab__active {
  font-family: "Open Sans Bold", sans-serif;
  color: #FFFFFF;
  border-image-source: linear-gradient(-120deg, #A64FFF 13.05%, #6370E7 81.16%);
}

@media screen and (max-width: 800px) {
  .tab {
    height: 3rem;
  }
}
.bg-panel {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}
.bg-panel__left, .bg-panel__right {
  position: absolute;
}
.bg-panel__left .ornament-frame, .bg-panel__right .ornament-frame {
  width: auto;
  display: flex;
}
.bg-panel__left .ornament-frame svg, .bg-panel__right .ornament-frame svg {
  opacity: 0.72;
  width: 42vw;
  height: auto;
}
.bg-panel__left {
  bottom: -20%;
  left: 0%;
  transform: rotate(36deg) translateX(-24%);
}
.bg-panel__right {
  right: -4%;
  top: 6%;
  width: 42vw;
  height: 42vw;
  animation: rotatingCircles 48s ease infinite alternate;
}
.bg-panel__right .ornament-frame {
  flex-direction: column;
}
.bg-panel__right .ornament-frame svg {
  width: auto;
  height: auto;
}
.bg-panel__right .ornament-frame svg:nth-child(2) {
  transform: scaleY(-1) translateY(-20%);
}

@media screen and (max-width: 800px) {
  .bg-panel__left {
    bottom: -4%;
    left: -4%;
  }
  .bg-panel__left .ornament-frame svg {
    width: 104vw;
  }
  .bg-panel__right {
    display: none;
  }
}
.navbar {
  position: absolute;
  display: flex;
  height: 100%;
  padding: 1.5rem;
}
.navbar__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 16px;
  width: auto;
  background-color: rgba(36, 33, 41, 0.7215686275);
  height: 100%;
  padding: 2.5rem 1rem;
  width: min-content;
}
.navbar__body .options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.language li {
  color: white;
}

@media screen and (max-width: 1600px) {
  .navbar {
    padding: 1.25rem;
  }
  .navbar__body {
    padding: 2rem 0.75rem;
  }
}
@media screen and (max-width: 1024px) {
  .navbar {
    padding: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .navbar {
    width: 100%;
    height: auto;
    padding: 0;
  }
  .navbar__body {
    width: 100%;
    flex-direction: row;
    padding: 1.5rem 1.5rem;
    height: auto;
    border-radius: unset;
    border: unset;
    background: unset;
    box-shadow: unset;
  }
  .navbar__body .options {
    width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1921px) {
  .navbar__body {
    padding: 2.4vw 1vw;
  }
  .navbar__body .logotype svg {
    width: 3.2vw;
    height: 3.2vw;
  }
}
.loader {
  background: linear-gradient(-130deg, #5F3C7A -20%, #3B3656 16%, #292832 72%, #212120 100%);
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.large-modal {
  position: fixed;
  inset: 0px;
  backdrop-filter: brightness(0.72) contrast(0.96);
  transition: 0.16s ease all;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.large-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  background-color: #242129;
  border-radius: 16px;
  overflow: auto;
  outline: none;
  padding: 1.5rem;
  gap: 1rem;
}
.large-modal .heading-frame {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .large-modal .heading-frame {
    gap: 5px;
  }
}

.meet-created-modal,
.preview-settings-modal {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transform-origin: center;
}
.meet-created-modal__container,
.preview-settings-modal__container {
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  transition: 0.1s ease;
  transform: scale(0.8);
}
@media screen and (max-width: 800px) {
  .meet-created-modal__container,
  .preview-settings-modal__container {
    gap: 5px;
  }
}
.meet-created-modal__heading,
.preview-settings-modal__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meet-created-modal__heading .exit-frame,
.preview-settings-modal__heading .exit-frame {
  display: flex;
}

.preview-settings-modal.show .preview-settings-modal__container,
.meet-created-modal.show .meet-created-modal__container {
  transform: scale(1);
}

.meet-created-modal.show,
.preview-settings-modal.show {
  display: flex;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 1600px) {
  .meet-created-modal__container {
    width: 40%;
  }
}
.share-modal {
  opacity: 0;
  visibility: hidden;
}
.share-modal__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.share-modal__link {
  display: flex;
  padding: 0.75rem;
  gap: 10px;
  backdrop-filter: brightness(0.92) contrast(0.92);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 6.4px;
}
@media screen and (max-width: 1024px) {
  .share-modal__link {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .share-modal__link {
    gap: 5px;
  }
}
.share-modal__link__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.share-modal__container {
  transform-origin: center;
  transform: translateY(25%) scale(0.8);
  transition: 0.24s linear;
}

.share-modal.show {
  opacity: 1;
  visibility: visible;
}

.share-modal.show .share-modal__container {
  transform: scale(1) translateY(0%);
}

.share-modal__link__img {
  width: 56px;
}
.share-modal__icon-list {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .share-modal__icon-list {
    gap: 5px;
  }
}
.share-modal__icon-list .link-icon-wrapper {
  position: relative;
}
.share-modal__icon-list .link-icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 1rem;
  min-width: 180px;
  cursor: pointer;
  background: linear-gradient(rgba(34, 48, 57, 0.1254901961), rgba(58, 54, 85, 0.1254901961));
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
}
@media screen and (max-width: 800px) {
  .share-modal__icon-list .link-icon {
    gap: 5px;
  }
}
.share-modal__icon-list .link-icon:hover {
  background: linear-gradient(180deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #823CCA, #4C57BB);
}
.share-modal__icon-list .link-icon.is-disabled {
  opacity: 0.3;
}
.share-modal__icon-list .link-icon.is-disabled:hover {
  background: gray 0.2;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #823CCA, #4C57BB);
}
.share-modal__icon-list .link-icon-info {
  cursor: not-allowed;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.share-modal__container.large-modal__container {
  padding: 2.5rem;
}

@media screen and (max-width: 1600px) {
  .share-modal__icon-list .link-icon {
    min-width: 160px;
  }
  .share-modal__container.large-modal__container {
    padding: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .share-modal {
    align-items: flex-end;
  }
  .share-modal__container {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.0862745098);
    border-radius: 20px 20px 0px 0px;
  }
  .share-modal__heading {
    display: none;
  }
  .share-modal__icon-list {
    grid-template-columns: repeat(4, 1fr);
  }
  .share-modal__icon-list .link-icon {
    min-width: 72px;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem;
  }
  .share-modal__link__img {
    width: 40px;
  }
  .share-modal.large-modal {
    align-items: flex-end;
  }
  .share-modal__container.large-modal__container {
    border-radius: 16px 16px 0px 0px;
    padding: 1rem;
  }
}
@media screen and (min-width: 1921px) {
  .share-modal__icon-list .link-icon {
    min-width: 7.2vw;
  }
}
.contacts-modal__container {
  min-width: 50%;
}
.contacts-modal__heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.contacts-modal__list {
  width: 100%;
  display: flex;
}
.contacts-modal__list > * {
  border: 1.5px solid rgba(255, 255, 255, 0.062745098);
  height: 380px;
}
.contacts-modal__list__search-letter {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 48px;
}
.contacts-modal__list__search-letter span {
  font-size: 16px;
}
.contacts-modal__list__sources, .contacts-modal__list__destination {
  flex: 1;
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .contacts-modal__list__sources, .contacts-modal__list__destination {
    gap: 5px;
  }
}
.contacts-modal__list__sources__list, .contacts-modal__list__destination__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 267px;
  overflow-y: auto;
  gap: 0.5rem;
  padding-right: 0.75rem;
}

.overlay-contacts-modal {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.062745098);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  .contacts-modal__container {
    width: 56%;
    min-width: 780px;
  }
}
@media screen and (max-width: 800px) {
  .contacts-modal {
    background: #242129;
    padding: 0rem;
  }
  .contacts-modal__heading, .contacts-modal__button-frame-top, .contacts-modal__list__destination, .contacts-modal__list__sources__header {
    display: none;
  }
  .contacts-modal__link__img {
    width: 40px;
  }
  .contacts-modal__list__search-letter, .contacts-modal__list__sources, .contacts-modal__list__sources__list {
    height: 440px;
  }
  .contacts-modal__list__sources__list {
    margin: 0;
  }
  .contacts-modal__container {
    width: 56%;
    min-width: 100%;
  }
  .contacts-modal__button-frame-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .contacts-modal.large-modal {
    align-items: flex-start;
  }
  .contacts-modal__container.large-modal__container {
    border: unset;
    border-radius: unset;
    background-color: unset;
  }
}
.form__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.form__row .input-frame textarea {
  width: 100%;
}
.form__row__block {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.form__row__icon {
  padding-top: 6px;
}

.form__body .input-frame {
  background: linear-gradient(-60deg, rgba(58, 54, 85, 0.2823529412), rgba(34, 48, 57, 0.3921568627) 100%);
  font-family: "Open Sans Regular", sans-serif;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
}

#guest-row.form__row {
  align-items: center;
}

.homepage .form__body .input-frame, .prejoin .form__body .input-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  width: 440px;
}

.prejoin .form__body .input-frame {
  width: 100%;
}

@media screen and (min-width: 1920px) {
  .form__row {
    gap: 1vw;
  }
}
.basic-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .basic-settings {
    gap: 5px;
  }
}
.basic-settings .input-frame {
  display: flex;
  width: 100%;
}
.basic-settings .input-frame__input {
  width: 100%;
}
.basic-settings .icon-frame {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin-left: -20%;
}
.basic-settings .form {
  display: grid;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .basic-settings .form {
    gap: 5px;
  }
}
.basic-settings .form__row:nth-child(1) .input-frame {
  width: 84%;
}
.basic-settings .form__row:nth-child(4) .input-frame {
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .basic-settings .form__row:nth-child(4) .input-frame {
    gap: 5px;
  }
}
.basic-settings .form__row:nth-child(4) .input-frame > div {
  width: 100%;
}
.basic-settings .form__row:nth-child(3) .grid__row:nth-child(1) {
  width: 100%;
}
.basic-settings .form__row:nth-child(3) .grid__row:nth-child(1) .form__row__block {
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .basic-settings .form__row:nth-child(3) .grid__row:nth-child(1) .form__row__block {
    gap: 5px;
  }
}
.basic-settings .form__row__gap .input-frame {
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .basic-settings .form__row__gap .input-frame {
    gap: 5px;
  }
}
.basic-settings .form__row__gap .input-frame > div {
  width: 100%;
  display: flex;
}
.basic-settings .grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .basic-settings .grid {
    gap: 5px;
  }
}
.basic-settings .grid__row:nth-child(1) {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .basic-settings .grid__row:nth-child(1) {
    gap: 5px;
  }
}
.basic-settings .grid__row:nth-child(2) {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .basic-settings .grid__row:nth-child(2) {
    gap: 5px;
  }
}

.basic-settings .grid__row:nth-child(2) .form__row__block {
  width: 50%;
}
.basic-settings .grid__row:nth-child(2) .form__row__block select {
  width: 100%;
}

.advanced-settings .form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .advanced-settings .form {
    gap: 5px;
  }
}
.advanced-settings .form__row .input__frame {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .advanced-settings .form__row .input__frame {
    gap: 5px;
  }
}
.advanced-settings .form__row .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25rem;
}

@media screen and (max-width: 1600px) {
  .basic-settings .grid__row:nth-child(1) {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 800px) {
  .basic-settings .form__row:nth-child(1) .input-frame {
    width: 100%;
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .basic-settings .form__row:nth-child(1) .icon-frame {
    margin: auto;
    width: 100%;
    display: flex;
    gap: 0.75rem;
  }
  .basic-settings .form__row:nth-child(1) .icon-frame img {
    padding: 0.5rem;
    width: 100%;
    height: 36px;
    border-radius: 5px;
    background: linear-gradient(-120deg, rgba(166, 79, 255, 0.2509803922), rgba(99, 112, 231, 0.2509803922));
    opacity: 1;
  }
  .basic-settings .form__row:nth-child(1) .icon-frame img:hover {
    background: linear-gradient(-120deg, #823CCA, #4C57BB);
  }
  .basic-settings .form__row__icon {
    display: none;
  }
  .basic-settings .form__row:nth-child(3) .grid__row:nth-child(1) .form__row__block {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .basic-settings .grid__row:nth-child(2) {
    flex-direction: column;
  }
  .basic-settings .grid__row:nth-child(2) .form__row__block {
    width: 100%;
  }
}
@media screen and (min-width: 1921px) {
  .advanced-settings .form,
  .basic-settings .form {
    gap: 1vw;
  }
}
.audio-video-box, .visual-effect-box, .more-options-box {
  color: rgba(255, 255, 255, 0.9333333333);
  font-family: "Open Sans Regular", sans-serif;
}

.audio-video-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .audio-video-box {
    gap: 5px;
  }
}
.audio-video-box .screen-frame {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .audio-video-box .screen-frame {
    gap: 5px;
  }
}
.audio-video-box .screen-frame .video-box {
  min-height: 180px;
  aspect-ratio: auto;
}
.audio-video-box .screen-frame__right .video-box {
  background: unset;
  background-color: #212120;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.audio-video-box .screen-frame__right .video-box::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  opacity: 0.6;
  background-image: url(assets/icons/headphones.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1600px) {
  .audio-video-box .screen-frame .video-box {
    min-height: 170px;
  }
}
@media screen and (max-width: 800px) {
  .preview-settings-modal__body__right .screen-frame .video-box {
    min-height: 24vw;
    width: auto;
  }
}
.contacts-modal__container {
  min-width: 50%;
}
.contacts-modal__heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.contacts-modal__list {
  width: 100%;
  display: flex;
}
.contacts-modal__list > * {
  border: 1.5px solid rgba(255, 255, 255, 0.062745098);
  height: 380px;
}
.contacts-modal__list__search-letter {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 48px;
}
.contacts-modal__list__search-letter span {
  font-size: 16px;
}
.contacts-modal__list__sources, .contacts-modal__list__destination {
  flex: 1;
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .contacts-modal__list__sources, .contacts-modal__list__destination {
    gap: 5px;
  }
}
.contacts-modal__list__sources__list, .contacts-modal__list__destination__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 267px;
  overflow-y: auto;
  gap: 0.5rem;
  padding-right: 0.75rem;
}

.overlay-contacts-modal {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.062745098);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  .contacts-modal__container {
    width: 56%;
    min-width: 780px;
  }
}
@media screen and (max-width: 800px) {
  .contacts-modal {
    background: #242129;
    padding: 0rem;
  }
  .contacts-modal__heading, .contacts-modal__button-frame-top, .contacts-modal__list__destination, .contacts-modal__list__sources__header {
    display: none;
  }
  .contacts-modal__link__img {
    width: 40px;
  }
  .contacts-modal__list__search-letter, .contacts-modal__list__sources, .contacts-modal__list__sources__list {
    height: 440px;
  }
  .contacts-modal__list__sources__list {
    margin: 0;
  }
  .contacts-modal__container {
    width: 56%;
    min-width: 100%;
  }
  .contacts-modal__button-frame-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .contacts-modal.large-modal {
    align-items: flex-start;
  }
  .contacts-modal__container.large-modal__container {
    border: unset;
    border-radius: unset;
    background-color: unset;
  }
}
.modale-setup, .modale-setup__body {
  transition: 0.84s ease;
}

.modale-setup {
  color: rgba(255, 255, 255, 0.9333333333);
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100;
  left: 0;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  backdrop-filter: brightness(0.56) contrast(0.92);
  z-index: 999;
  transition: all 0.3s ease;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}
.modale-setup__body {
  overflow: hidden;
  transform: translate(100%, 0%);
  background-color: #242129;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  width: 50%;
  height: 100%;
  padding: 2rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2rem;
  min-width: 660px;
  justify-content: space-between;
}
.modale-setup__body__frame {
  background-color: #242129;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.modale-setup__body .btn-frame {
  gap: 1rem;
  display: flex;
  flex-direction: row;
}
.modale-setup__body__main-frame {
  overflow-y: overlay;
}
.modale-setup__accordeon__frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .modale-setup__accordeon__frame {
    gap: 5px;
  }
}

.modale-setup.show {
  visibility: visible;
  opacity: 1;
}

.modale-setup.show .modale-setup__body {
  transform: translate(0%, 0%);
  overflow: overlay;
}

@media screen and (max-width: 800px) {
  .modale-setup.show {
    z-index: 9999;
    height: 100vh;
    overflow: hidden;
  }
  .modale-setup {
    padding: 1rem;
  }
  .modale-setup__body {
    width: 100%;
    background-color: rgb(36, 33, 41);
    padding: 1.25rem;
    transform-origin: center;
    transform: translate(0%, 0%) scale(0.8);
    margin-top: 22%;
    height: 72%;
    min-width: unset;
  }
  .modale-setup__body__frame {
    padding: 0rem;
    padding-bottom: 1rem;
    border: unset;
    border-bottom: 1px solid rgba(255, 255, 255, 0.062745098);
    box-shadow: unset;
    border-radius: 0;
  }
  .modale-setup__body__frame:nth-child(4) {
    padding: 0rem;
    border: unset;
  }
  .modale-setup__body .btn-frame {
    flex-direction: column;
  }
  .modale-setup__body__main-frame {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
  }
}
@media screen and (max-width: 800px) and (max-width: 800px) {
  .modale-setup__body__main-frame {
    gap: 5px;
  }
}
@media screen and (min-width: 1921px) {
  .modale-setup__body {
    width: 48%;
  }
  .modale-setup__body__frame {
    padding: 1.5vw;
  }
  .modale-setup__body .btn-frame {
    gap: 1vw;
  }
}
.share-modal {
  opacity: 0;
  visibility: hidden;
}
.share-modal__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.share-modal__link {
  display: flex;
  padding: 0.75rem;
  gap: 10px;
  backdrop-filter: brightness(0.92) contrast(0.92);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 6.4px;
}
@media screen and (max-width: 1024px) {
  .share-modal__link {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .share-modal__link {
    gap: 5px;
  }
}
.share-modal__link__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.share-modal__container {
  transform-origin: center;
  transform: translateY(25%) scale(0.8);
  transition: 0.24s linear;
}

.share-modal.show {
  opacity: 1;
  visibility: visible;
}

.share-modal.show .share-modal__container {
  transform: scale(1) translateY(0%);
}

.share-modal__link__img {
  width: 56px;
}
.share-modal__icon-list {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .share-modal__icon-list {
    gap: 5px;
  }
}
.share-modal__icon-list .link-icon-wrapper {
  position: relative;
}
.share-modal__icon-list .link-icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 1rem;
  min-width: 180px;
  cursor: pointer;
  background: linear-gradient(rgba(34, 48, 57, 0.1254901961), rgba(58, 54, 85, 0.1254901961));
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
}
@media screen and (max-width: 800px) {
  .share-modal__icon-list .link-icon {
    gap: 5px;
  }
}
.share-modal__icon-list .link-icon:hover {
  background: linear-gradient(180deg, rgba(110, 48, 173, 0.2509803922), rgba(68, 78, 165, 0.2509803922));
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #823CCA, #4C57BB);
}
.share-modal__icon-list .link-icon.is-disabled {
  opacity: 0.3;
}
.share-modal__icon-list .link-icon.is-disabled:hover {
  background: gray 0.2;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #823CCA, #4C57BB);
}
.share-modal__icon-list .link-icon-info {
  cursor: not-allowed;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.share-modal__container.large-modal__container {
  padding: 2.5rem;
}

@media screen and (max-width: 1600px) {
  .share-modal__icon-list .link-icon {
    min-width: 160px;
  }
  .share-modal__container.large-modal__container {
    padding: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .share-modal {
    align-items: flex-end;
  }
  .share-modal__container {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.0862745098);
    border-radius: 20px 20px 0px 0px;
  }
  .share-modal__heading {
    display: none;
  }
  .share-modal__icon-list {
    grid-template-columns: repeat(4, 1fr);
  }
  .share-modal__icon-list .link-icon {
    min-width: 72px;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem;
  }
  .share-modal__link__img {
    width: 40px;
  }
  .share-modal.large-modal {
    align-items: flex-end;
  }
  .share-modal__container.large-modal__container {
    border-radius: 16px 16px 0px 0px;
    padding: 1rem;
  }
}
@media screen and (min-width: 1921px) {
  .share-modal__icon-list .link-icon {
    min-width: 7.2vw;
  }
}
.overlay-user-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  backdrop-filter: brightness(0.64) contrast(0.96);
  transition: 0.48s ease all;
  z-index: 9999;
}

.user-modal {
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  background-color: #242129;
  border-radius: 10px;
  overflow: auto;
  outline: none;
  padding: 1.5rem;
  width: 350px;
  position: absolute;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.user-modal__avatar img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 800px) {
  .user-modal {
    top: 100%;
    right: 10px;
  }
}
@media screen and (min-width: 800px) {
  .user-modal {
    left: 100%;
    bottom: 40px;
  }
}
.large-modal {
  position: fixed;
  inset: 0px;
  backdrop-filter: brightness(0.72) contrast(0.96);
  transition: 0.16s ease all;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.large-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  background-color: #242129;
  border-radius: 16px;
  overflow: auto;
  outline: none;
  padding: 1.5rem;
  gap: 1rem;
}
.large-modal .heading-frame {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .large-modal .heading-frame {
    gap: 5px;
  }
}

.meet-created-modal,
.preview-settings-modal {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transform-origin: center;
}
.meet-created-modal__container,
.preview-settings-modal__container {
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  transition: 0.1s ease;
  transform: scale(0.8);
}
@media screen and (max-width: 800px) {
  .meet-created-modal__container,
  .preview-settings-modal__container {
    gap: 5px;
  }
}
.meet-created-modal__heading,
.preview-settings-modal__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meet-created-modal__heading .exit-frame,
.preview-settings-modal__heading .exit-frame {
  display: flex;
}

.preview-settings-modal.show .preview-settings-modal__container,
.meet-created-modal.show .meet-created-modal__container {
  transform: scale(1);
}

.meet-created-modal.show,
.preview-settings-modal.show {
  display: flex;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 1600px) {
  .meet-created-modal__container {
    width: 40%;
  }
}
/* todo refacto  */
.HourPicker {
  width: 100%;
  flex-direction: column;
}
.HourPicker__container ul {
  list-style-type: none;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(12, 1fr);
  padding-inline-start: 0;
  margin-block-end: 0.5rem;
  margin-block-start: 0.5rem;
}
.HourPicker__container ul li {
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  width: 100%;
  padding: 0.5rem;
  font-family: "Open Sans Regular", sans-serif;
  font-size: smaller;
}
.HourPicker__container ul li:hover {
  background-color: rgba(99, 112, 231, 0.6431372549);
}
.HourPicker__container ul .active {
  background-color: #6370E7;
}
.HourPicker__container__span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.HourPicker__container:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
  .HourPicker__container:nth-child(1) {
    gap: 5px;
  }
}

.calendar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .calendar {
    gap: 5px;
  }
}
.calendar__body {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .calendar__body {
    gap: 5px;
  }
}
.calendar__body .grid-box ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.calendar__month-select {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 1600px) {
  .HourPicker__container ul {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .HourPicker input {
    width: 40%;
  }
  .HourPicker__container ul {
    grid-template-columns: repeat(5, 1fr);
  }
  .HourPicker__container:nth-child(1) {
    justify-content: flex-start;
    gap: 0.5rem;
  }
}
.meet-created-modal__content {
  min-height: 120px;
  align-items: center;
  display: flex;
}
.meet-created-modal__footer .btn-frame {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .meet-created-modal__footer .btn-frame {
    gap: 5px;
  }
}

@media screen and (max-width: 800px) {
  .meet-created-modal__container {
    width: auto;
  }
  .meet-created-modal.large-modal {
    align-items: flex-end;
  }
  .meet-created-modal__container.large-modal__container {
    border-radius: 16px 16px 0px 0px;
  }
}
.preview-settings-modal__container {
  width: auto;
  position: relative;
  overflow: visible;
  gap: 1.5rem;
}
.preview-settings-modal__body {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.preview-settings-modal__body__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.preview-settings-modal__body__left .btn-frame {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 230px;
}
@media screen and (max-width: 800px) {
  .preview-settings-modal__body__left .btn-frame {
    gap: 5px;
  }
}
.preview-settings-modal__body__right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  position: relative;
  min-height: 580px;
  width: 680px;
}
@media screen and (max-width: 800px) {
  .preview-settings-modal__body__right {
    gap: 5px;
  }
}
.preview-settings-modal__body__right .audio-video-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  position: relative;
}
@media screen and (max-width: 800px) {
  .preview-settings-modal__body__right .audio-video-box {
    gap: 5px;
  }
}
.preview-settings-modal__body__right .option-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.65rem;
  gap: 1rem;
}

.audio-video-box .input-frame, .more-options-box .input-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .audio-video-box .input-frame, .more-options-box .input-frame {
    gap: 5px;
  }
}
.audio-video-box .input-frame__single, .audio-video-box .input-frame__double, .more-options-box .input-frame__single, .more-options-box .input-frame__double {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.6rem;
}
.audio-video-box .input-frame__single__row, .audio-video-box .input-frame__double__row, .more-options-box .input-frame__single__row, .more-options-box .input-frame__double__row {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .audio-video-box .input-frame__single__row, .audio-video-box .input-frame__double__row, .more-options-box .input-frame__single__row, .more-options-box .input-frame__double__row {
    gap: 5px;
  }
}

@media screen and (max-width: 1600px) {
  .preview-settings-modal__body__right {
    min-height: 510px;
    width: 560px;
  }
  .preview-settings-modal {
    overflow-y: auto;
    padding: 2%;
  }
  .preview-settings-modal__container {
    margin: auto;
  }
}
@media screen and (max-width: 1200px) {
  .preview-settings-modal__container {
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 1024px) {
  .preview-settings-modal__body__right {
    width: auto;
  }
}
@media screen and (max-width: 800px) {
  .preview-settings-modal {
    overflow-y: initial;
    padding: initial;
  }
  .preview-settings-modal__container {
    width: auto;
  }
  .preview-settings-modal__body__right {
    min-height: auto;
  }
  .preview-settings-modal {
    height: inherit;
    padding: 1.5rem 1rem;
  }
  .preview-settings-modal__container {
    overflow-y: auto;
    height: 100%;
  }
  .preview-settings-modal__body {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .preview-settings-modal__body__right {
    min-height: 480px;
  }
  .audio-video-box .input-frame__double, .audio-video-box .input-frame__single, .more-options-box .input-frame__double, .more-options-box .input-frame__single {
    gap: 0.5rem;
  }
  .audio-video-box .input-frame__double__row, .more-options-box .input-frame__double__row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}
@media screen and (min-width: 1921px) {
  .preview-settings-modal__body {
    grid-template-columns: auto 1fr;
  }
}
.preview-send-anim-modale {
  position: absolute;
  top: -140px;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 0.75rem;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (max-width: 1024px) {
  .preview-send-anim-modale {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .preview-send-anim-modale {
    gap: 5px;
  }
}
.preview-send-anim-modale__body .animated-svg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .preview-send-anim-modale__body .animated-svg {
    gap: 5px;
  }
}
.preview-send-anim-modale__body .animated-svg #test-mario {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s ease-in;
}
@media screen and (max-width: 1024px) {
  .preview-send-anim-modale__body .animated-svg #test-mario {
    padding: 0.55rem;
  }
}
.preview-send-anim-modale__body .animated-svg #test-mario:hover {
  background-color: rgba(79, 255, 196, 0.2509803922);
}
.preview-send-anim-modale__body .animated-svg__item {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s ease-in;
}
@media screen and (max-width: 1024px) {
  .preview-send-anim-modale__body .animated-svg__item {
    padding: 0.55rem;
  }
}
.preview-send-anim-modale__body .animated-svg__item svg {
  position: relative;
  transform-origin: center;
}
.preview-send-anim-modale__body .animated-svg__item:hover {
  background-color: rgba(166, 79, 255, 0.2509803922);
  transform: scale(1.16);
}
.preview-send-anim-modale__body .animated-svg #heart:hover {
  background-color: rgba(255, 79, 108, 0.2509803922);
}
.preview-send-anim-modale__body .animated-svg #heart:hover svg {
  animation: pulse 1.2s linear forwards infinite;
}
.preview-send-anim-modale__body .animated-svg #thumbs-up:hover {
  background-color: rgba(79, 255, 196, 0.2509803922);
}
.preview-send-anim-modale__body .animated-svg #thumbs-down:hover {
  background-color: rgba(255, 223, 79, 0.2509803922);
}
.preview-send-anim-modale__frame {
  padding: 0 0 0.5rem;
}

#clapping-hands:hover svg {
  animation: pulse 1.2s linear forwards infinite;
}

.clapping-hands {
  animation: clappingHands 2s linear forwards;
}

.clone {
  position: absolute;
  transition: transform 1s ease-in-out;
}

.thumbs-up {
  animation: rotateThumbs ease 3s forwards;
}

.thumbs-down {
  animation: thumbsDown ease-out 4s forwards;
}

.room-not-found {
  background: linear-gradient(-130deg, #5F3C7A -20%, #3B3656 16%, #292832 72%, #212120 100%);
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}
.room-not-found__body {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 100vh;
}
.room-not-found__body__container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .room-not-found__body__container {
    gap: 5px;
  }
}
.room-not-found__body__footer {
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .room-not-found__body__footer {
    padding: 0.55rem;
  }
}

.prejoin__left-panel__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
}
.prejoin__left-panel__body .notif-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
}
.prejoin__left-panel__body .notif-box__body .form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
}
.prejoin__left-panel__body .notif-box__body .form__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
}
.prejoin__left-panel__body .notif-box__body .form__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  gap: 1rem;
}
@media screen and (max-width: 1024px) {
  .prejoin__left-panel__body .notif-box__body .form__header {
    padding: 0.55rem;
  }
}
.prejoin__left-panel__body .logo-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  transform-origin: center;
  animation: logoRotate 1s ease-out infinite;
}
@media screen and (max-width: 1024px) {
  .prejoin__left-panel__body .logo-box {
    padding: 0.55rem;
  }
}

@media screen and (max-width: 1024px) {
  .prejoin__left-panel__header {
    display: none;
  }
}
.video-box {
  display: flex;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  height: 100%;
}
.video-box input {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.macaroon-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.prejoin__right-panel__video-frame .option-layer {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  padding: 1rem;
}
.prejoin__right-panel__video-frame .option-layer span {
  padding: 6px;
}
.prejoin__right-panel__video-frame .option-layer:nth-of-type(2) {
  align-items: flex-start;
}

.custom-screen-frame {
  display: flex;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
}
.custom-screen-frame img {
  aspect-ratio: 1.9;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.visual-effect-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
}
@media screen and (max-width: 800px) {
  .visual-effect-box {
    gap: 10px;
  }
}
.visual-effect-box__body {
  display: flex;
}
.visual-effect-box__footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
}
.visual-effect-box__footer .btn-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.visual-effect-box__footer .pill-carousel__frame {
  display: flex;
  gap: 10px;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .visual-effect-box__footer .pill-carousel__frame {
    gap: 5px;
  }
}

.more-options-box {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  z-index: 9;
}
@media screen and (max-width: 800px) {
  .more-options-box {
    gap: 5px;
  }
}
.more-options-box .random-color__pill {
  margin-top: 0.6rem;
}
.more-options-box .upload-profile__box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .more-options-box .upload-profile__box {
    gap: 5px;
  }
}

.devroom__mosaic {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24%, 1fr));
  flex-wrap: wrap;
  justify-content: center;
  place-items: center;
  place-content: baseline;
  overflow: overlay;
  min-height: 40vw;
  width: 100%;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom__mosaic {
    gap: 5px;
  }
}
.devroom__mosaic.--two-participants {
  grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
}
.devroom__mosaic.--two-participants .devroom__mosaic__screen {
  height: auto;
}
.devroom__mosaic.--six-participants {
  grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
}
.devroom__mosaic.--four-participants {
  grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
}
.devroom__mosaic.--four-participants .devroom__mosaic__screen {
  aspect-ratio: 16/9;
}
.devroom__mosaic.--four-participants .devroom__mosaic__screen {
  width: 100%;
  height: -webkit-fill-available;
}
.devroom__mosaic.--three-participants .devroom__mosaic__screen:nth-child(3) {
  grid-column-start: span 2;
  width: 50%;
}
.devroom__mosaic.--three-participants .devroom__mosaic__screen {
  aspect-ratio: 16/9;
}
.devroom__mosaic.--single-participant .devroom__mosaic__screen {
  height: 100%;
  aspect-ratio: 16/9;
}
.devroom__mosaic.--nine-participants {
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
}
.devroom__mosaic.--twenty-participants {
  grid-template-columns: repeat(auto-fit, minmax(17%, 1fr));
}
.devroom__mosaic.--twenty-four-participants {
  grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
}
.devroom .featured-frame {
  overflow: hidden;
  flex-basis: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: none;
  color: rgba(255, 255, 255, 0.5333333333);
}
.devroom .featured-frame.flex {
  display: flex;
}

.devroom__mosaic.--single-participant,
.devroom__mosaic.--two-participants {
  place-content: initial;
}

.devroom__body__main.--flex-layout .devroom__mosaic {
  grid-template-columns: repeat(auto-fit, minmax(99%, 1fr));
  flex-basis: 20%;
  place-items: flex-start;
  place-content: baseline;
}
.devroom__body__main.--flex-layout .devroom__mosaic .devroom__mosaic__screen {
  min-height: 220px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .devroom__mosaic, .devroom__mosaic.--six-participants, .devroom__mosaic.--nine-participants, .devroom__mosaic.--twenty-participants, .devroom__mosaic.--twenty-four-participants {
    place-content: baseline;
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
    min-height: unset;
  }
  .devroom__mosaic__screen, .devroom__mosaic.--six-participants__screen, .devroom__mosaic.--nine-participants__screen, .devroom__mosaic.--twenty-participants__screen, .devroom__mosaic.--twenty-four-participants__screen {
    height: 100%;
    min-height: 26vw;
  }
  .devroom__mosaic.--four-participants .devroom__mosaic__screen {
    width: 100%;
    height: 100%;
  }
  .devroom__body__main.--flex-layout .devroom__mosaic .devroom__mosaic__screen {
    min-height: 30vw;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .devroom__mosaic {
      place-content: flex-start;
      position: -webkit-sticky;
    }
    .devroom__body__main.--flex-layout .devroom__mosaic .devroom__mosaic__screen,
    .devroom__body__main .devroom__mosaic .devroom__mosaic__screen {
      min-height: 32vw;
    }
    .devroom__body__main.--flex-layout .devroom__mosaic {
      max-height: fit-content;
    }
  }
  .devroom__mosaic {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }
  .devroom__mosaic__screen {
    -webkit-overflow-scrolling: auto;
  }
  .devroom .featured-frame {
    flex-basis: 100%;
    max-height: 68vw;
    min-height: 68vw;
  }
  .devroom__body__main.--flex-layout .devroom__mosaic {
    flex-basis: unset;
    overflow-y: overlay;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
}
.devroom .btn-frame#test {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  gap: 0;
  width: auto;
  top: 0;
  left: 0;
  position: fixed;
  height: auto;
  margin: auto;
  transform: translate(calc(50vw - 200px), 0);
}
@media screen and (max-width: 800px) {
  .devroom .btn-frame#test {
    gap: 5px;
  }
}
.devroom .btn-frame#test .btn-regular {
  background: linear-gradient(135deg, rgba(54, 205, 207, 0.5) 0%, rgba(64, 224, 207, 0.5) 100%);
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 5px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: unset;
  padding: 0.5rem;
  width: 200px;
  margin: auto;
}
.devroom .btn-frame#test.hidden {
  display: none;
}
.devroom__option-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 10px;
  min-height: max-content;
}
.devroom__option-panel__left {
  display: flex;
  gap: 16px;
  color: rgba(255, 255, 255, 0.9333333333);
}
.devroom__option-panel__left .info-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}
.devroom__option-panel__left .info-box__heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.devroom__option-panel__left .info-box #meet-info {
  display: flex;
  position: relative;
}
.devroom__option-panel__left .info-box #meet-info svg {
  cursor: pointer;
}
.devroom__option-panel__center .action-frame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom__option-panel__center .action-frame {
    gap: 5px;
  }
}
.devroom__option-panel__right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: rgba(255, 255, 255, 0.9333333333);
}
@media screen and (max-width: 800px) {
  .devroom__option-panel__right {
    gap: 10px;
  }
}
.devroom__option-panel__right .action-frame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 0.65rem;
}
.devroom__option-panel .laptop-options {
  position: absolute;
  top: -8rem;
  right: 0;
  z-index: 999;
}
.devroom__option-panel .laptop-options__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25rem;
}

@media screen and (max-width: 800px) {
  .devroom__option-panel {
    position: fixed;
    background: #242129;
    justify-content: center;
    bottom: 0;
    left: 0;
    padding: 0.75rem;
    border-top: 1px solid rgba(255, 255, 255, 0.062745098);
    width: 100%;
    height: auto;
    padding: 1rem;
  }
}
@media screen and (max-width: 800px) and (max-width: 1024px) {
  .devroom__option-panel {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .devroom__option-panel__center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
  .devroom__option-panel__center .action-frame #upload-folder {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .devroom__option-panel .btn-frame#test {
    width: -webkit-fill-available;
    transform: initial;
  }
}
@media screen and (max-width: 800px) {
  .devroom__option-panel__right {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .devroom__option-panel .action-frame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .devroom .laptop-options {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .devroom__option-panel__left {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .devroom__option-panel.--expanded {
    border-radius: 24px 24px 0 0;
  }
}
@media screen and (max-width: 800px) {
  .devroom .action-frame__mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    flex-wrap: wrap;
    color: rgba(255, 255, 255, 0.9333333333);
  }
}
@media screen and (max-width: 800px) and (max-width: 800px) {
  .devroom .action-frame__mobile {
    gap: 5px;
  }
}
@media screen and (max-width: 800px) {
  .devroom .action-frame__mobile__options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }
  .devroom .action-frame__mobile__options li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.062745098);
  }
  .devroom .action-frame__mobile__options li:nth-last-child(2) {
    border: unset;
  }
  .devroom .action-frame__mobile__options li:last-child {
    border: unset;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 800px) {
  .devroom .action-frame__mobile__top, .devroom .action-frame__mobile__bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .devroom .action-frame__mobile__bottom {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 800px) {
  .participant-fixed-option-box .action-frame__mobile__options li:nth-last-child(2) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.062745098);
  }
  .participant-fixed-option-box .action-frame__mobile__options li:last-child {
    border: unset;
    justify-content: initial;
    color: rgba(255, 255, 255, 0.9333333333);
  }
}
.meeting-room-info {
  position: absolute;
  transform: translate(-36.5%, -110%) scale(0.2);
  min-width: 430px;
  padding: 15px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-image: linear-gradient(to bottom, #A64FFF, transparent, #6370E7), linear-gradient(110deg, #6370E7, #6370E7, #A64FFF);
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  transition: 0.2s ease-in;
  transform-origin: top 160px;
  opacity: 0;
  z-index: 99;
  visibility: hidden;
}
@media screen and (max-width: 800px) {
  .meeting-room-info {
    gap: 5px;
  }
}
.meeting-room-info__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.meeting-room-info__triangle {
  opacity: 0.99;
  transform: translate(160px, 100%);
  position: absolute;
  bottom: 1px;
  left: 0;
}
.meeting-room-info__triangle > div {
  background: #6370E7;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  width: 16px;
  height: 10px;
}

.meeting-room-info.--show {
  opacity: 1;
  visibility: visible;
  transform: translate(-36.5%, -110%) scale(1);
}

@media screen and (max-width: 1600px) {
  .meeting-room-info {
    min-width: 340px;
  }
  .meeting-room-info.--show {
    transform: translate(-26.5%, -120%) scale(1);
  }
  .meeting-room-info__triangle {
    transform: translate(94px, 100%);
  }
}
@media screen and (max-width: 800px) {
  .meeting-room-info {
    min-width: 330px;
    transform-origin: 100px 200px;
  }
  .meeting-room-info__triangle {
    display: none;
  }
  .meeting-room-info.--show {
    transform: translate(-40%, 36%) scale(1);
  }
  .top-bar-mobile .info-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}
@media screen and (max-width: 800px) and (max-width: 800px) {
  .top-bar-mobile .info-box {
    gap: 5px;
  }
}
@media screen and (max-width: 800px) {
  .top-bar-mobile #meet-info {
    display: flex;
  }
}
.devroom .chat-panel {
  width: 100%;
  color: rgba(255, 255, 255, 0.9333333333);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  gap: 20px;
  border-radius: 12px;
  overflow: hidden;
  padding: 1rem;
}
@media screen and (max-width: 800px) {
  .devroom .chat-panel {
    gap: 10px;
  }
}
.devroom .chat-panel__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .devroom .chat-panel__body {
    gap: 5px;
  }
}
.devroom .chat-panel__searchbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.75rem;
}
.devroom .chat-panel__searchbar__frame {
  gap: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}
.devroom .chat-panel__option-panel {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom .chat-panel__option-panel {
    gap: 5px;
  }
}
.devroom .chat-panel__dialog-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25rem;
  position: relative;
  overflow: overlay;
  max-height: 60vh;
  justify-content: flex-end;
  height: 100%;
}
.devroom .chat-panel__dialog-action {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  position: relative;
  bottom: 0;
}
@media screen and (max-width: 800px) {
  .devroom .chat-panel__dialog-action {
    gap: 5px;
  }
}
.devroom .chat-panel__sending-file {
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  background-color: #242129;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  z-index: 99;
  padding: 1rem;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (max-width: 1024px) {
  .devroom .chat-panel__sending-file {
    padding: 0.55rem;
  }
}
.devroom .chat-panel__sending-file__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.devroom .chat-panel__sending-file__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1.5rem;
}

@media screen and (max-width: 800px) {
  .devroom .chat-panel {
    padding: 1.5rem;
    border: unset;
  }
  .devroom .chat-panel__dialog-frame {
    min-height: 400px;
  }
  .devroom .chat-panel__dialog-action {
    width: auto;
  }
  @supports (-webkit-touch-callout: none) {
    .devroom .chat-panel {
      height: 79.5dvh;
    }
    .devroom .chat-panel__dialog-frame {
      min-height: auto;
    }
  }
}
.devroom .participant-panel,
.devroom .screen-layout-panel,
.devroom .visual-effects-panel {
  width: 100%;
  color: rgba(255, 255, 255, 0.9333333333);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  gap: 20px;
  border-radius: 12px;
  padding: 1rem;
}
@media screen and (max-width: 800px) {
  .devroom .participant-panel,
  .devroom .screen-layout-panel,
  .devroom .visual-effects-panel {
    gap: 10px;
  }
}
.devroom .participant-panel__body,
.devroom .screen-layout-panel__body,
.devroom .visual-effects-panel__body {
  overflow: overlay;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom .participant-panel__body,
  .devroom .screen-layout-panel__body,
  .devroom .visual-effects-panel__body {
    gap: 5px;
  }
}
.devroom .participant-panel__body .search-frame,
.devroom .screen-layout-panel__body .search-frame,
.devroom .visual-effects-panel__body .search-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom .participant-panel__body .search-frame,
  .devroom .screen-layout-panel__body .search-frame,
  .devroom .visual-effects-panel__body .search-frame {
    gap: 5px;
  }
}
.devroom .participant-panel__frame,
.devroom .screen-layout-panel__frame,
.devroom .visual-effects-panel__frame {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .devroom .participant-panel__frame,
  .devroom .screen-layout-panel__frame,
  .devroom .visual-effects-panel__frame {
    padding: 0.55rem;
  }
}
.devroom .participant-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.75rem;
  margin-top: 10px;
}
.devroom .participant-list__item {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 0.75rem;
  flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
  .devroom .participant-list__item {
    gap: 5px;
  }
}
@media screen and (max-width: 1024px) {
  .devroom .participant-list__item {
    padding: 0.55rem;
  }
}
.devroom .participant-list__item__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 50%;
  flex-basis: 60%;
}
.devroom .participant-list__item__options .option-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.devroom .participant-list__item.--talking {
  border: 2px solid rgba(114, 187, 241, 0.5882352941);
  background-color: rgba(114, 187, 241, 0.1019607843);
}
.devroom .participant-list__item.--sharing {
  border: 2px solid rgba(255, 207, 79, 0.768627451);
  background: linear-gradient(-120deg, rgba(255, 207, 79, 0.0862745098), rgba(255, 155, 74, 0.0862745098));
}
.devroom .participant-list__avatar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.devroom .screen-layout-panel__body {
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .devroom .participant-panel,
  .devroom .visual-effects-panel,
  .devroom .screen-layout-panel {
    border-radius: 0;
    border: unset;
  }
  @supports (-webkit-touch-callout: none) {
    .devroom .screen-layout-panel,
    .devroom .visual-effects-panel,
    .devroom .participant-panel {
      height: 79.5dvh;
    }
  }
}
.chat-panel__upload-frame {
  position: absolute;
  top: -440px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.chat-panel__upload-frame__body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 1.5rem;
}
@media screen and (max-width: 800px) {
  .chat-panel__upload-frame__body {
    gap: 5px;
  }
}
@media screen and (max-width: 1600px) {
  .chat-panel__upload-frame__body {
    padding: 1.2rem;
  }
}
@media screen and (max-width: 1200px) {
  .chat-panel__upload-frame__body {
    padding: 0.9rem;
  }
}
.chat-panel .upload-frame__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.chat-panel .upload-frame__content {
  flex: 1;
  border: 2px dashed rgba(254, 254, 254, 0.337254902);
  border-radius: 8px;
  gap: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chat-panel .upload-frame__content__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 800px) {
  .chat-panel .upload-frame__content__top {
    gap: 10px;
  }
}
.chat-panel .upload-frame__content__bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 800px) {
  .chat-panel__upload-frame {
    top: -400px;
  }
}
.external-dialog-frame {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.9333333333);
  z-index: 9;
  pointer-events: none;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.external-dialog-frame__body {
  pointer-events: auto;
  background: unset;
  outline: none;
  border: unset;
  top: 10%;
  left: 1%;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 1.5rem;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  box-shadow: unset;
  border-radius: unset;
  user-select: auto;
  cursor: grab;
}
@media screen and (max-width: 800px) {
  .external-dialog-frame__body {
    gap: 5px;
  }
}
.external-dialog-frame__body button {
  padding: 0.75rem;
  background: indigo;
}
@media screen and (max-width: 1024px) {
  .external-dialog-frame__body button {
    padding: 0.55rem;
  }
}
.external-dialog-frame__body textarea {
  padding: 0.75rem;
  background: #242129;
  color: rgba(255, 255, 255, 0.9333333333);
}
@media screen and (max-width: 1024px) {
  .external-dialog-frame__body textarea {
    padding: 0.55rem;
  }
}

.external-dialog-frame.active {
  pointer-events: auto;
  background-color: rgba(100, 0, 255, 0.1); /* Exemple : fond semi-transparent bleu */
}

.external-dialog-frame.active .external-dialog-frame__body {
  opacity: 0.5;
}

.external-box {
  position: absolute;
  left: 1rem;
  background-color: #242129;
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 0.75rem;
  width: 100%;
  margin: auto;
  left: 0;
  top: 0;
  height: 100%;
}
@media screen and (max-width: 800px) {
  .external-box {
    gap: 5px;
  }
}
@media screen and (max-width: 1024px) {
  .external-box {
    padding: 0.55rem;
  }
}

#smiley {
  position: relative;
}

.emoji-library {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 1.5rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  left: 0;
  top: -500px;
  height: 480px;
  overflow-y: overlay;
  gap: 1rem;
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .emoji-library {
    padding: 1.2rem;
  }
}
@media screen and (max-width: 1200px) {
  .emoji-library {
    padding: 0.9rem;
  }
}
.emoji-library span {
  font-family: "EmojiMart";
}
.emoji-library__grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
  place-items: center;
  gap: 6px;
}
.emoji-library__grid span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  font-size: 22px;
  aspect-ratio: 1/1;
  padding-top: 8px;
  border-radius: 50%;
}
.emoji-library__grid span:hover {
  background-color: rgba(166, 79, 255, 0.5019607843);
}
.emoji-library__last-used {
  border: 1px solid rgba(255, 255, 255, 0.0862745098);
  border-radius: 6px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.emoji-library__last-used .heading {
  position: absolute;
  top: -0.75rem;
  left: 0.5rem;
  background: #242129;
  padding: 0.25rem 1rem;
}
.emoji-library__last-used .frame {
  padding: 1rem;
}
.emoji-library__last-used .frame span {
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  @supports (-webkit-touch-callout: none) {
    .emoji-library {
      height: 280px;
      top: -300px;
    }
  }
}
.top-info-box {
  position: absolute;
  left: 0;
  top: 0;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  z-index: 9;
}
@media screen and (max-width: 800px) {
  .top-info-box {
    gap: 5px;
  }
}

.someone-raising-hand {
  color: #fff;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.062745098);
  width: min-content;
  background: rgba(36, 33, 41, 0.9);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  background: rgba(36, 33, 41, 0.6);
  padding: 0.75rem;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (max-width: 1024px) {
  .someone-raising-hand {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .someone-raising-hand {
    gap: 5px;
  }
}

.participant-request {
  font-family: "Open Sans Regular", sans-serif;
  border: 2px solid rgba(255, 255, 255, 0.062745098);
  border-radius: 10px;
  background: rgba(36, 33, 41, 0.9);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  background: rgba(36, 33, 41, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 340px;
  padding: 1rem;
  gap: 1rem;
}
.participant-request__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .participant-request__header {
    gap: 5px;
  }
}
.participant-request__header.--left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}
.participant-request .myselect {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25rem;
  color: rgba(255, 255, 255, 0.9333333333);
}
.participant-request .myselect__bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2666666667);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .participant-request .myselect__bar {
    gap: 5px;
  }
}
@media screen and (max-width: 1024px) {
  .participant-request .myselect__bar {
    padding: 0.55rem;
  }
}
.participant-request .myselect__bar:hover {
  background: rgba(255, 255, 255, 0.1333333333);
}
.participant-request .myselect__bar__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .participant-request .myselect__bar__content {
    gap: 5px;
  }
}
.participant-request .myselect__bar__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.participant-request .myselect__bar__toggle-arrow {
  display: flex;
}
.participant-request .myselect__legend {
  color: rgba(255, 255, 255, 0.5333333333);
}
.participant-request .myselect__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.participant-request .myselect__body ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .participant-request .myselect__body ul {
    gap: 5px;
  }
}
.participant-request .myselect__body ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem;
  width: 100%;
}
.participant-request .myselect__body ul li:hover {
  background-color: rgba(255, 255, 255, 0.1333333333);
}
.participant-request__footer .btn-frame {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .participant-request__footer .btn-frame {
    gap: 5px;
  }
}

.participant-option-box {
  position: absolute;
  bottom: 0.75rem;
  left: 2.5rem;
  background: rgba(36, 33, 41, 0.9);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  padding: 0.4rem;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  z-index: 99;
}
.participant-option-box ul li {
  color: rgba(255, 255, 255, 0.5333333333);
}

.devroom__mosaic__screen__overlay.--bottom .container .participant-fixed-option-box {
  position: fixed;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  width: 100%;
  left: 0;
  border-radius: 24px 24px 0 0;
  padding: 1rem;
  min-height: 24vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  bottom: 0;
  z-index: 999;
}
.devroom__mosaic__screen__overlay.--bottom .container .participant-fixed-option-box .action-frame__mobile__options li img {
  opacity: 1;
}

.top-bar-mobile {
  padding: 1rem;
  background-color: #242129;
  height: 72px;
}
.top-bar-mobile__body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.9333333333);
}
.top-bar-mobile__body hr {
  display: flex;
  height: 24px;
  border: unset;
  width: 1px;
  background: rgba(255, 255, 255, 0.9333333333);
}
.top-bar-mobile__body .info-box img {
  width: 20px;
}
.top-bar-mobile__left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.top-bar-mobile__right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
}

@supports (-webkit-touch-callout: none) {
  .top-bar-mobile {
    height: 10dvh;
  }
}
.devroom .stats-panel {
  width: 100%;
  color: rgba(255, 255, 255, 0.9333333333);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  gap: 20px;
  padding: 1rem;
}
@media screen and (max-width: 800px) {
  .devroom .stats-panel {
    gap: 10px;
  }
}
.devroom .stats-panel__body {
  overflow: overlay;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom .stats-panel__body {
    gap: 5px;
  }
}
.devroom .stats-panel__body .Collapsible {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  background: rgba(255, 255, 255, 0.0196078431);
  padding: 1rem;
}
.devroom .stats-panel__frame {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .devroom .stats-panel__frame {
    padding: 0.55rem;
  }
}

@media screen and (max-width: 800px) {
  .devroom .stats-panel {
    border-radius: unset;
    border: unset;
  }
  @supports (-webkit-touch-callout: none) {
    .devroom .stats-panel {
      height: 79.5dvh;
    }
  }
}
.stats-panel__participant-list table {
  border-spacing: 0 0.32rem;
  width: 100%;
}
.stats-panel__participant-list table tr td {
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .stats-panel__participant-list table tr td {
    padding: 0.55rem;
  }
}
.stats-panel__participant-list table thead tr td {
  padding: 0.5rem 0;
  color: rgba(255, 255, 255, 0.5333333333);
}
.stats-panel__participant-list table thead tr td:nth-child(2) {
  text-align: end;
  width: 40%;
}
.stats-panel__participant-list table thead tr td::first-letter {
  text-transform: uppercase;
}
.stats-panel__participant-list table tbody {
  gap: 0.5rem;
}
.stats-panel__participant-list table tbody tr td {
  background-color: rgba(255, 255, 255, 0.0862745098);
}
.stats-panel__participant-list table tbody tr td:nth-child(1) {
  border-radius: 6px 0 0 6px;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-right: unset;
}
.stats-panel__participant-list table tbody tr td:last-child {
  border-radius: 0px 6px 6px 0;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  border-left: unset;
  text-align: end;
}

.record-modal {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9333333333);
  top: -330px;
  left: calc(50% - 200px);
  min-height: 240px;
  width: 400px;
  padding: 1.5rem;
  z-index: 99;
}
.record-modal__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.record-modal__header .heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.record-modal__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 5px;
}

.record-modal.--end,
.record-modal.--start {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 40px;
}

@media screen and (max-width: 1600px) {
  .record-modal.--end,
  .record-modal.--start {
    padding: 1rem;
    top: -300px;
  }
}
@media screen and (max-width: 800px) {
  .record-modal.--end,
  .record-modal.--start {
    width: 340px;
    left: calc(50% - 170px);
    top: -310px;
  }
}
.meeting-info-modale {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999;
  left: 0;
}
.meeting-info-modale__frame {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 40px;
  border-radius: 10px;
  padding: 20px;
}
.meeting-info-modale__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.meeting-info-modale__heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .meeting-info-modale__heading {
    gap: 5px;
  }
}
.meeting-info-modale__close {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.meeting-info-modale .shortcuts-box__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.4rem;
  overflow-y: scroll;
  max-height: 288px;
}
.meeting-info-modale .shortcuts-box__item {
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  border-radius: 6px;
  border: unset;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  background-color: rgba(255, 255, 255, 0.0862745098);
}
.meeting-info-modale__body.duo-tab {
  min-height: 290px;
}

.meeting-info-modale {
  backdrop-filter: blur(2px) grayscale(0.4) brightness(0.25);
}

.meeting-info-modale__frame {
  backdrop-filter: none;
}

.meeting-details-box__frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
}
@media screen and (max-width: 800px) {
  .meeting-details-box__frame {
    gap: 10px;
  }
}

.meeting-details-box__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .meeting-details-box__container {
    gap: 5px;
  }
}

.meeting-details-box__container__content .input-link.input-frame input, .meeting-details-box__container__content .input-link.input-frame textarea {
  background: rgba(255, 255, 255, 0.031372549);
}

@media screen and (max-width: 800px) {
  .meeting-info-modale__frame {
    padding: 1rem;
  }
  .meeting-info-modale__body.duo-tab {
    max-height: 320px;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}
.all-settings {
  position: fixed;
  left: 0;
  top: 0;
  color: rgba(255, 255, 255, 0.9333333333);
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px) grayscale(0.16) brightness(0.4);
}
.all-settings__frame {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  border-radius: 20px;
  box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  backdrop-filter: none;
  min-width: 640px;
  min-height: 560px;
  gap: 1.5rem;
  padding: 1.5rem;
}
.all-settings__header, .all-settings__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.all-settings__heading {
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .all-settings__heading {
    gap: 5px;
  }
}

.all-settings__frame.--ms-settings {
  min-height: 540px;
  max-height: 540px;
}

.all-settings__frame.--ms-settings .duo-tab__content {
  max-height: 400px;
  overflow-y: scroll;
}

.all-settings__frame.--ms-settings .duo-tab {
  max-height: 360px;
  overflow-y: scroll;
}

.duo-tab {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  gap: 20px;
}
@media screen and (max-width: 800px) {
  .duo-tab {
    gap: 10px;
  }
}
.duo-tab__option .btn-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 230px;
  gap: 0.32rem;
}
.duo-tab__content {
  min-width: 400px;
  overflow-y: overlay;
}

.all-settings .duo-tab__option .btn-frame {
  min-width: 280px;
}
.all-settings .duo-tab__content {
  overflow: visible;
  position: relative;
  z-index: 99;
  min-width: 560px;
  max-width: 560px;
  height: 520px;
}

@media screen and (max-width: 1600px) {
  .all-settings .duo-tab__content {
    min-width: 540px;
    max-width: 540px;
    height: 480px;
  }
}
@media screen and (max-width: 800px) {
  .duo-tab {
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }
  .duo-tab__content {
    min-width: 310px;
  }
}
.tab-content__block {
  border: 2px solid red;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  padding: 0.75rem;
}
@media screen and (max-width: 1024px) {
  .tab-content__block {
    padding: 0.55rem;
  }
}
.tab-content__block .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  position: relative;
  padding: 1rem 0rem;
}
@media screen and (max-width: 800px) {
  .tab-content__block .progress-bar {
    gap: 5px;
  }
}
.tab-content__block .progress-bar__legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.tab-content__block .progress-bar__legend__right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  text-align: end;
}
.tab-content__block .progress-bar__bar {
  position: relative;
}
.tab-content__block .progress-bar__grid {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 48px;
  font-size: 12px;
}
.tab-content__block .progress-bar__grid div {
  display: flex;
  color: rgba(255, 255, 255, 0.5333333333);
}
.tab-content .--more-options .tab-content__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .tab-content .--more-options .tab-content__body {
    gap: 5px;
  }
}
.tab-content .--more-options .input-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .tab-content .--more-options .input-frame {
    gap: 5px;
  }
}
.tab-content .--more-options .input-frame__single {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.5rem;
}
.tab-content .--more-options .check-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .tab-content .--more-options .check-frame {
    gap: 5px;
  }
}
.tab-content .--more-options .check-frame__option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .tab-content .--more-options .check-frame__option {
    gap: 5px;
  }
}

.--screen-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .--screen-layout {
    gap: 5px;
  }
}
.--screen-layout .btn-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
.--screen-layout .tab-content__block {
  padding: 1rem 1rem 2rem;
}
.--screen-layout .tab-content__frame.--top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.--screen-layout .tab-content__frame .grid-range {
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.1fr;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .--screen-layout .tab-content__frame .grid-range {
    gap: 5px;
  }
}

.tab-content__block.--admin .option-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 0.75rem;
  gap: 10px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .tab-content__block.--admin .option-frame {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .tab-content__block.--admin .option-frame {
    gap: 5px;
  }
}
.tab-content__block.--admin .option-frame:hover {
  background: linear-gradient(to left, rgba(166, 79, 255, 0.062745098), rgba(99, 112, 231, 0.062745098));
}
.tab-content__block.--admin .option-frame__left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .tab-content__block.--admin .option-frame__left {
    gap: 5px;
  }
}
.tab-content__block.--admin .option-frame__right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tab-content .audio-video-box .option-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0.75rem;
  gap: 10px;
}
@media screen and (max-width: 1024px) {
  .tab-content .audio-video-box .option-frame {
    padding: 0.55rem;
  }
}
@media screen and (max-width: 800px) {
  .tab-content .audio-video-box .option-frame {
    gap: 5px;
  }
}

.homepage {
  background: linear-gradient(-130deg, #5F3C7A -20%, #3B3656 16%, #292832 72%, #212120 100%);
  overflow: hidden;
  min-height: 100vh;
  position: relative;
}
.homepage__body {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 100vh;
}
.homepage__body__container {
  width: auto;
  min-height: 600px;
  padding-left: 12.5%;
  padding-top: 3.8%;
  padding-bottom: 4%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3.4vw;
}
.homepage__body__container > div {
  opacity: 0;
}
.homepage .form__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  gap: 1rem;
}

.homepage__body .button-frame {
  display: grid;
  grid-template-columns: 1fr 0.85fr;
  width: 440px;
  gap: 1rem;
}

@media screen and (max-width: 800px) {
  .homepage {
    background: linear-gradient(-90deg, #292832, #212120);
  }
  .homepage__body__container {
    width: 100%;
    padding-left: unset;
    padding: 1.5rem;
    gap: 3.2rem;
    padding-top: 20%;
  }
  .homepage .form__body {
    width: auto;
  }
  .homepage .form__body .input-frame, .homepage .form__body .button-frame {
    width: auto;
  }
}
@media screen and (min-width: 1921px) {
  .homepage__body .button-frame {
    width: 24vw;
    gap: 0.65vw;
  }
  .homepage .form__body {
    gap: 0.65vw;
  }
  .homepage .form__body .input-frame {
    width: 24vw;
    height: 2.6vw;
  }
}
.prejoin {
  background: linear-gradient(-130deg, #5F3C7A -20%, #3B3656 16%, #292832 72%, #212120 100%);
  position: relative;
  overflow: hidden;
}
.prejoin__body {
  position: relative;
  display: flex;
  height: 100vh;
}
.prejoin__body__container {
  height: 100%;
  margin-left: 6.4%;
  width: 100%;
  display: grid;
  grid-template-columns: 0.35fr 0.65fr;
  gap: 1rem;
  padding: 1.5rem;
}
.prejoin__body__container > div {
  opacity: 0;
}
.prejoin__left-panel {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  gap: 1rem;
  padding: 1.5rem;
}
.prejoin__right-panel {
  padding: 0;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.prejoin__right-panel__video-frame {
  border: 1px solid rgba(255, 255, 255, 0.062745098);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 1px 0px rgba(0, 0, 0, 0.0705882353), 0px 2px 2px 1px rgba(0, 0, 0, 0.0862745098);
  border-radius: 10px;
  background: #242129;
  background-color: rgba(36, 33, 41, 0.768627451);
  border-radius: 16px;
  flex-basis: 90%;
  overflow: hidden;
  position: relative;
}
.prejoin__right-panel__action-frame {
  flex-basis: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .prejoin__right-panel__action-frame {
    gap: 5px;
  }
}
.prejoin__right-panel__action-frame .action-frame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-basis: 24%;
}
@media screen and (max-width: 800px) {
  .prejoin__right-panel__action-frame .action-frame {
    gap: 5px;
  }
}
.prejoin__right-panel__action-frame .btn-frame {
  flex-basis: 24%;
}
.prejoin__right-panel__action-frame .empty-frame {
  flex-basis: 24%;
}

@media screen and (max-width: 1600px) {
  .prejoin__body__container {
    padding: 1.25rem;
  }
}
@media screen and (max-width: 1400px) {
  .prejoin__right-panel__action-frame .btn-frame {
    flex-basis: 30%;
  }
}
@media screen and (max-width: 1200px) {
  .prejoin__body {
    height: auto;
    min-height: 100vh;
  }
  .prejoin__body__container {
    grid-template-columns: 1fr;
    margin-left: 10%;
    padding: 1rem;
  }
  .prejoin__right-panel__video-frame {
    min-height: 512px;
  }
}
@media screen and (max-width: 800px) {
  .prejoin {
    overflow: auto;
    background: linear-gradient(-90deg, #292832, #212120);
  }
  .prejoin__body {
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .prejoin__body__container {
    margin-left: unset;
    grid-template-columns: 1fr;
    padding-top: 0;
    gap: unset;
    height: fit-content;
    grid-template-areas: "a" "b";
  }
  .prejoin__body .greetings-box__mobile {
    padding-top: 20%;
  }
  .prejoin__left-panel, .prejoin__right-panel {
    box-shadow: unset;
  }
  .prejoin__left-panel {
    grid-area: b;
    padding: unset;
    height: max-content;
    background: unset;
    border-radius: 0;
    overflow-y: hidden;
    bottom: 0;
    left: 0;
    width: 100%;
    border: unset;
    padding: 1rem 0.5rem;
    z-index: 99;
  }
  .prejoin__right-panel__action-frame {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .prejoin__right-panel .empty-frame {
    display: none;
  }
  .prejoin__right-panel__video-frame {
    min-height: 270px;
    max-height: 270px;
    height: 270px;
  }
}
@media screen and (min-width: 1920px) {
  .prejoin__body__container {
    padding: 2vw;
    gap: 2vw;
  }
}
.devroom {
  background: linear-gradient(-130deg, #5F3C7A -20%, #3B3656 16%, #292832 72%, #212120 100%);
  position: relative;
  overflow: hidden;
}
.devroom__body {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  flex-wrap: wrap;
  gap: 0px 10px;
  padding: 10px;
  font-family: "Poppins Regular", sans-serif;
}
.devroom__body__main {
  overflow: overlay;
  display: flex;
  height: calc(100vh - 90px - 12px);
  min-height: 32vw;
  width: auto;
  flex: 1;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom__body__main {
    gap: 5px;
  }
}
.devroom__body__side-panel {
  display: flex;
  width: 400px;
  min-width: 400px;
  height: calc(100vh - 90px - 12px);
}
.devroom__participant-panel {
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  gap: 10px;
}
@media screen and (max-width: 800px) {
  .devroom__participant-panel {
    gap: 5px;
  }
}
.devroom__side-participants {
  border: 4px solid olivedrab;
}
.devroom__featured-participant {
  border: 4px solid #72BBF1;
  border-radius: 16px;
  overflow: hidden;
}
.devroom__featured-participant__box {
  display: flex;
  background-image: url("assets/pictures/portrait-peter.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  opacity: 0.72;
  width: 100%;
}
.devroom__featured-participant__box input[type=file] {
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.devroom__featured-participant__display-layout {
  position: relative;
  top: -100%;
  height: 100%;
  display: flex;
}
.devroom__featured-participant__display-layout .top-left, .devroom__featured-participant__display-layout .top-right, .devroom__featured-participant__display-layout .bottom-left, .devroom__featured-participant__display-layout .bottom-right {
  position: absolute;
}
.devroom__featured-participant__display-layout .top-left {
  top: 0.75rem;
  left: 0.75rem;
}
.devroom__featured-participant__display-layout .top-right {
  top: 0.75rem;
  right: 0.75rem;
}
.devroom__featured-participant__display-layout .bottom-left {
  bottom: 0.75rem;
  left: 0.75rem;
}
.devroom__featured-participant__display-layout .bottom-right {
  bottom: 0.75rem;
  right: 0.75rem;
}
.devroom__option-panel {
  flex-basis: 100%;
  position: relative;
}

.devroom .test-cross {
  position: absolute;
  color: rgba(255, 255, 255, 0.9333333333);
  left: 0.65rem;
  top: 0.65rem;
}
.devroom .test-cross button {
  background: linear-gradient(-120deg, #823CCA, #4C57BB);
  border: 1px solid #242129;
  display: flex;
}
.devroom .test-cross button:hover {
  background: linear-gradient(-120deg, #A64FFF, #6370E7);
}

@media screen and (max-width: 1024px) {
  .devroom__body__side-panel {
    position: absolute;
    background: linear-gradient(-130deg, #5F3C7A -20%, #3B3656 16%, #292832 72%, #212120 100%);
    width: 100%;
    min-width: initial;
    height: calc(100vh - 144px);
    overflow: hidden;
    padding: 0rem;
    border-radius: unset;
    top: 0;
    right: 0;
    z-index: 99;
  }
  .devroom__body__side-panel .chat-panel {
    border-radius: unset;
  }
}
@media screen and (max-width: 800px) {
  .devroom__body {
    padding: 6px;
    height: calc(100vh - 148px);
    overflow: hidden;
  }
  .devroom__body__main {
    height: 100%;
    overflow: overlay;
  }
  .devroom__option-panel {
    border: 1px solid rgba(255, 255, 255, 0.062745098);
    border-left: unset;
    border-right: unset;
    border-bottom: transparent;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 72px;
    gap: 10px;
    padding: 1rem;
    z-index: 99;
  }
}
@media screen and (max-width: 800px) and (max-width: 800px) {
  .devroom__option-panel {
    gap: 5px;
  }
}
@media screen and (max-width: 800px) {
  .devroom__body__main.--flex-layout {
    flex-direction: column;
  }
}
@media screen and (max-width: 800px) {
  @supports (-webkit-touch-callout: none) {
    .devroom__body {
      overflow: overlay;
      box-sizing: border-box;
      height: 80dvh;
      max-height: 80dvh;
    }
    .devroom__body__side-panel {
      height: 100%;
    }
    .devroom__option-panel {
      min-height: 10dvh;
    }
  }
}

