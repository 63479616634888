.input-checkbox{
    @include flexbox(row,center,center);
    @include gap-responsive;
    input[type=checkbox] {
        -webkit-appearance: none;
        appearance: none;
        background: $btn-gradient;
        border-radius: 6px;
        color: currentColor;
        cursor: pointer;
        display: grid;
        font: inherit;
        height: 20px;
        width: 20px;
        margin: 0;
        place-content: center;
        transform: translateY(-0.075em);
    }
    input[type=checkbox]:after{
        content: "";

        background-color: #242129e4;
        height: 18px;
        width: 18px;
        border-radius: 6px;
        position: absolute;
        left: 1px;
        top: 1px;
    
    }
    input[type=checkbox]:before {
        box-shadow: inset 1em 1em #A64FFF;
        clip-path: polygon(14% 44%,0 65%,50% 100%,100% 16%,80% 0,43% 62%);
        content: "";
        height: 13px;
        width: 13px;
        padding: 0px;
        transform: scale(0);
        transform-origin: bottom left;
        transition: transform 0.05s linear;
        z-index: 99;
    }
    input[type=checkbox]:checked:before {
        transform: scale(1);
    }
}

.input-checkbox.--alt{
    input[type=checkbox] {
        background: #fff1;
        border-radius: 6px;
        border-color: transparent;
        height: 32px;
        width: 40px;
        place-content: center;
        transform: translateY(-0.075em);
    }
    input[type=checkbox]:after{
        content: "";
        background-color: #242129;
        height: 30px;
        width: 38px;
        left: 1px;
        top: 1px;
    }
    input[type=checkbox]:before {
        box-shadow: inset 1em 1em $write-transp;
        clip-path: polygon(14% 44%,0 65%,50% 100%,100% 16%,80% 0,43% 62%);
        height: 16px;
        width: 16px;
    }   
}


.participant-request{
    .input-checkbox{
        input[type=checkbox] {
            background:transparent;
            border-radius:2px;
        }
        input[type=checkbox]:after{
            content: "";
            background-color: transparent;
            border: 1px solid $write;
            border-radius: 2px;
            left: -0px;
            top: 1px;
        }
        input[type=checkbox]:before {
            box-shadow: inset 1em 1em $write;
            height: 12px;
            width: 12px;
        }
    }
}

// fieldset{
//     @include flexbox(column,flex-start);
//     @include gap-responsive;
// }

.option-radio{
    border: 1px solid #72BBF160;
    border-radius: $button-radius;
    @include padding-responsive;
    position: relative;
    // background:$button-gradient;
    input[type="radio"]{
        opacity: 0.1;
        appearance: none;
        background-color: #72BBF120;
        position: absolute;
        padding: 0;
        z-index: 0;
        cursor: pointer;
        margin: 0;
        border: unset;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        transition: 0.16s ease;
    }
    input[type="radio"]:hover{
        background-color: #72BBF140;
    }
    input[type="radio"]:checked{
        opacity: 0.3;
        background-color: #72BBF140;
    }
}

@media screen and (max-width:800px) {

    .input-checkbox input[type=checkbox]{
        margin-top:2.4px;
    }

}