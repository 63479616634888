/* toggle box  */

.toggle-box {
    display: flex;
  }
  
.toggle-box input {
    visibility: hidden;
    width: 0px;
    height: 0px;
    position: absolute;
}

  
.toggle-box label {
    width: 56px;
    height: 30px;
    position: relative;
    display: block;
    border:1px solid $border;
    border-radius: 32px;
    // background: linear-gradient(-90deg, #A64FFF36, #6370E736);
    background: #3e3b42;
    cursor: pointer;
  }
  
.toggle-box label::after {
    content:'';
    width: 24px;
    height: 24px;
    top: 2px;
    left: 3px;
    position: absolute;
    background-color: $primary;
    border-radius: 50%;
    transition: all 0.32s ease;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    display: flex;
  }
  
.toggle-box input:checked + label {
    // background: #777;
    // background-color: $write;
    background: linear-gradient(-90deg, #A64FFFa4, #6370E7a4);
}
  
.toggle-box input:checked + label:after {
    content:'';
    // background: linear-gradient(-90deg, #A64FFF, #6370E7);
    left: 28px;
}


@media screen and (max-width:1600px) {

  .toggle-box label {
    width: 50px;
    height: 26px;
    top: 1px;
    left: 0px;
  }

  .toggle-box label::after {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2.5px;
  }


  .toggle-box input:checked + label:after {
    left: 25px;
  }


}


@media screen and (min-width:1920px) {

  .toggle-box label {
    width: 64px;
    height: 34px;
  }
  
  .toggle-box label::after {
      width: 28px;
      height: 28px;
      top: 2px;
      left: 3px;
    }
    
    
  .toggle-box input:checked + label:after {
      left: 32px;
  }


}