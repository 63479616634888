.top-info-box{
    position: absolute;
    left: 0;
    top: 0;
    margin: 20px;
    @include flexbox(column);
    @include gap-responsive;
    z-index: 9;
}



.someone-raising-hand{
    color: #fff;
    border-radius: $screen-radius;
    border: 2px solid $border-alt;
    width: min-content;
    @include glassmorphism;
    background: #24212999;
    @include padding-responsive;
    @include gap-responsive;
    @include flexbox(row);
}