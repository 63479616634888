.typecode-frame__footer{
    .notif-frame{
        @include flexbox(row,center,center);
        @include gap-responsive;
        p{
            @include font-responsive;
        }
    }
    .notif-frame.success{
        p{
            background: -webkit-linear-gradient(-20deg,#3696CF, #40E0CF);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .notif-frame.danger{
        p{
            background: -webkit-linear-gradient(-20deg,#FF8888, #F05F5F);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    
    }
}


#participant-panel-notif.dot{
    position: absolute;
}

.dot{
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.dot.--danger{
    background: radial-gradient(at center, #FF8888, #F05F5F);
    right:2px;
    top: -2px;
}


