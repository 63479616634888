.pill-stats{
    border: 1px solid $border;
    border-radius: $button-radius;
    position: relative;
    &__content{
        position: static;
        @include padding-responsive;
        background: linear-gradient(90deg,#72BBF1a4,#4B90E2a4);
        display: flex;
        span{
            &:nth-of-type(2){
                position: absolute;
                right:0.75rem;
            }
        }
    }
}