.Collapsible{
    @include flexbox(column);
}

.Collapsible__contentInner{
    padding-top:2vw;
    padding-bottom:2vw;
}

.Collapsible__trigger {
    display: block;
    position: relative;
    // height: 2.4vw;
    padding-left: 0vw;
    // padding-right: 1vw;
    @include flexbox(column,flex-start,flex-start);
    transition: all ease 300ms; 
    &:after {
        content:'';
        position: absolute;
        background-image: url('./assets/icons/arrow-down.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 28px;
        right: 0vw;
        top: 0;
        display: block;
        transition: transform 100ms;
    }

    &.is-open {
        &:after {
            transform: scaleY(-1);
        }
    }

    &.is-disabled {
        opacity: 0.5;
        background-color: grey;
    }
}


.Collapsible__trigger{
    margin-block-end: 0;
    margin-block-start: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.stats-panel{
    .Collapsible__trigger{
        justify-content: center;
    }
}

.stats-panel{
    .Collapsible__contentInner,
    .Collapsible__trigger{
        padding-top: 1rem;
        padding-bottom: 1rem;

    }
}

.stats-panel{
    .Collapsible__contentInner{
        @include flexbox(column);
        @include gap-responsive;
    }
}


.is-open + .Collapsible__contentOuter{
    overflow: visible!important;
}

@media screen and (max-width:800px) {


    .Collapsible__trigger:after{
        background-size: contain;
        width: 24px;
    }

    .Collapsible__trigger{
        padding-top: unset;
        padding-bottom: unset;
        padding-right: unset;
        height: 48px;
    }

}