.option-frame{
    input[type="radio"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 1.25px solid #6370E7;
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    place-content: center;
  }


  input[type="radio"]::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    background:$pill-gradient;
  }
  
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
}

.all-settings{
    .option-frame{
        &__legend{
            span{
                color: $write-transp;
                @include font-responsive;
            }
        }
    }
}