.prejoin{
    &__left-panel{
        &__body{
            @include flexbox(column,center);
            gap:1rem;
            .notif-box{
                @include flexbox(column);
                gap:1rem;
                &__body{
                    .form{
                        @include flexbox(column);
                        gap:1rem;
                    }
                    .form__body{
                        @include flexbox(column);
                        gap:1rem;
                    }
                    .form__header{
                        @include flexbox(column,center,center);
                        @include padding-responsive;
                        gap:1rem;
                    }
                }
            }
            .logo-box{
                @include flexbox(column,center,center);
                @include padding-responsive;
                transform-origin: center;
                animation: logoRotate 1s ease-out infinite;
            }
        }
    }
}



@media screen and (max-width:1024px) {

    .prejoin{
        &__left-panel{
            &__header{
                display: none;
            }
        }
    }

}