table,td,tr,caption{
    font-family: $font-secondary;
    @include font-responsive;
}

.basic-settings{
    caption {
        caption-side: bottom;
        text-align: start;
        @include padding-responsive;
        display: flex;
        align-items: center;
        gap:1rem;
    }
    table{
        width: 100%;
        margin: auto;
        td{
            text-align: center;
            padding: 0.5rem 0;
        }
        td:nth-child(1){
            display: flex;
            align-items: center;
            gap: 1.5rem;
            span{
                font-family: $font-secondary-bold;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: start;
                overflow: hidden;
                width: 200px;
            }
        }
        th{
            color:#fff8;
            font-weight: initial;
            @include font-responsive;
        }
        tbody{
            tr{
                td:nth-child(2),
                td:nth-child(3){
                    width: 14%;
                }
            }
        }
    }
}


@media screen and (max-width:1600px) {


    .basic-settings{
        table{
            th{
                @include font-responsive;
            }
        }
    }


}


@media screen and (max-width:800px) {

    .basic-settings table th {
        font-size: 12px;
    }

}


@media screen and (min-width:1921px) {

    .basic-settings table td:nth-child(1){
        gap:1vw;
    }


}