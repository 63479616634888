.macaroon-box{
    &__frame{
        background-color: goldenrod;
        width: 25%;
        height: auto;
        aspect-ratio: 1/1;
        border-radius: 50%;
        @include flexbox(row,center,center);

    }
}

.devroom__mosaic{
    .macaroon-box{
        height: auto;
        &__frame{
            height: auto;
            min-width: 6.8vw;
        }
        span{
            font-size: 2.6vw;
            text-indent: 8px;
        }
    }
}

.devroom__mosaic.--twenty-participants{
    .devroom__mosaic__screen{
        .macaroon-box{
            &__frame{
                min-width: 4.4vw;
            }
            span{
                font-size: 2vw;
            }
        }
    }
}
.featured-frame{
    .devroom__mosaic__screen .macaroon-box__frame{
        width: 13vw;
        height: 13vw;    
    }
}

.devroom__body__main.--flex-layout{
    .devroom__mosaic .macaroon-box__frame{
        min-width: 5.6vw;
    }
}


@media screen and (max-width:1600px) {


    .devroom__mosaic{
        .macaroon-box{
            &__frame{
                min-width: 7.2vw;
            }
        }
    }


}


@media screen and (max-width:1024px) {

    .macaroon-box{
        &__frame{
            width: 144px;
            height: 144px;
        }
    }

    .devroom__mosaic{
        .macaroon-box{
            &__frame{
                min-width: 8vw;
                height: initial;
                width: initial;
                span{
                    text-indent: 2px;
                }
            }
        }
    }

    .featured-frame{
        .devroom__mosaic__screen .macaroon-box__frame{
            width: 140px;
            height: 140px;
        }
    }

}


@media screen and (max-width:800px) {

    .devroom__mosaic{
        .macaroon-box{
            &__frame{
                min-width: 12vw;
            }
        }
    }

    .devroom__body__main.--flex-layout{
        .devroom__mosaic{
            .macaroon-box__frame{
                min-width: 9.6vw;
            }
        }
    }

    .devroom__mosaic.--twenty-participants .devroom__mosaic__screen{
        .macaroon-box{
            &__frame{
                min-width: 12vw;
            }
            span{
                font-size: 3vw;
            }
        }
    }

}


@media screen and (min-width:1920px) {

    .macaroon-box{
        &__frame{
            width: 20%;
        }
    }


}