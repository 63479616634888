.basic-settings{
    display: flex;
    flex-direction: column;
    @include gap-responsive;
    .input-frame{
        display: flex;
        width: 100%;
        &__input{
            width: 100%;
        }
    }

    .icon-frame{
        display: flex;
        width: 20%;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        margin-left: -20%;
    }
    .form{
        display: grid;
        @include gap-responsive;
        &__row:nth-child(1){
            .input-frame{
                width: 84%;
            }
        }
        &__row:nth-child(4){
            .input-frame{
                align-items: center;
                @include gap-responsive;
                >div{
                    width: 100%;
                }
            }
        }
        &__row:nth-child(3){
            .grid{
                &__row:nth-child(1){
                    width: 100%;
                    .form__row__block{
                        width: 100%;
                        justify-content: flex-start;
                        @include gap-responsive;
                    }
                }
            }
        }
        &__row__gap{
            .input-frame{
                @include gap-responsive;
                @include flexbox(row,center,center);
                >div{
                    width: 100%;
                    display: flex;
                }
            }
        }
    }



    .grid{
        display: flex;
        flex-direction: column;
        width: 100%;
        @include gap-responsive;
        &__row:nth-child(1){
            display: flex;
            flex-wrap: wrap;
            @include gap-responsive;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
        }
        &__row:nth-child(2){
            display: flex;
            @include gap-responsive;
        }
    }


}


.basic-settings .grid__row:nth-child(2){
    .form__row__block{
        width: 50%;
        select{
            width: 100%;
        }
    }

}


.advanced-settings{
    .form{
        display: flex;
        flex-direction: column;
        @include gap-responsive;
        &__row{
            .input__frame{
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                @include gap-responsive;
            }
            .content{
                @include flexbox(column);
                gap:0.25rem;
            }   
        }
    }
}


@media screen and (max-width:1600px) {

    .basic-settings{
        .grid{
            &__row:nth-child(1){
                justify-content: flex-start;
            }
        }
    }
    

}



@media screen and (max-width:800px) {

    .basic-settings{
        .form{
            &__row:nth-child(1){
                .input-frame{
                    width: 100%;
                    gap:0.75rem;
                    @include flexbox(column);
                }
                .icon-frame{
                    margin: auto;
                    width: 100%;
                    display: flex;
                    gap:0.75rem;
                    img{
                        padding: 0.5rem;
                        width: 100%;
                        height: 36px;
                        border-radius:$button-radius;
                        background: $button-gradient;
                        opacity: 1;
                        &:hover{
                            background: $button-gradient-opaque-hover;
                        }
                    }
                }
            }
            &__row{
                &__icon{
                    display: none;
                }
            }

            &__row:nth-child(3){
                .grid{
                    &__row:nth-child(1){
                        .form__row__block{
                            justify-content: flex-start;
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }   
        .grid__row:nth-child(2) {
            flex-direction: column;
            .form__row__block{
                width: 100%;
            }
        }
    }

}



@media screen and (min-width:1921px) {

    .advanced-settings .form,
    .basic-settings .form{
        gap: 1vw;
    }


}