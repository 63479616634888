.pill-carousel__controls{
    @include flexbox(row,center,center);
    gap:0.5rem;
    .prev-button,.next-button{
        @include flexbox(row,center,center);
        background: $button-gradient-opaque;
        border:1px solid $border;
        border-radius: $button-radius;
        padding: 0;
        height: 24px;
        width: 24px;
        svg{
            width: 16px;
            height: 16px;
        }
    }
}