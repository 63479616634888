.meeting-info-modale{
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    @include flexbox(row,center,center);
    z-index: 999;
    left: 0;
    &__frame{
        @include basic-frame;
        @include flexbox(column);
        gap:40px;
        border-radius: $screen-radius;
        padding: 20px;
        // border: 2px solid orange;
    }
    &__header{
        @include flexbox(row,space-between,center);
    }
    &__heading{
        @include flexbox(row,flex-start,center);
        @include gap-responsive;
    }
    &__close{
        @include flexbox(row);
    }

    .shortcuts-box{
        // border: 2px solid red;
        &__list{
            @include flexbox(column);
            gap:0.4rem;
            overflow-y: scroll;
            max-height: 288px;
        }
        &__item{
            padding: 6px 10px;
            @include flexbox(row,space-between,center);
            @include basic-frame;
            border-radius: 6px;
            border:unset;
            box-shadow: $modal-shadow;
            background-color: #ffffff16;
        }
    }
    &__body.duo-tab{
        min-height: 290px;
    }
}



.meeting-info-modale {
    backdrop-filter: blur(2px) grayscale(0.4) brightness(0.25);
}
  
.meeting-info-modale__frame {
    backdrop-filter: none;
}


.meeting-details-box__frame{
    @include flexbox(column,flex-start);
    @include gap-large;

}

.meeting-details-box__container{
    @include flexbox(column,flex-start);
    @include gap-responsive;
}

.meeting-details-box__container__content .input-link.input-frame {
    input,textarea{
        background: $border-modal;
    }
}


@media screen and (max-width:800px) {

    .meeting-info-modale{
        &__frame{
            padding: 1rem;
        }
        &__body.duo-tab{
            max-height: 320px;
            min-height: 320px;
            @include flexbox(column);
        }
    }

}