.devroom{
    .btn-frame#test{
        @include flexbox(row,center,center);
        @include gap-responsive;
        gap: 0;
        width: auto;
        top: 0;
        left: 0;
        position: fixed;
        height: auto;
        margin: auto;
        transform: translate(calc(50vw - 200px),0);
        .btn-regular{
            background:$green;
            border: 1px solid $border;
            border-radius: $button-radius;
            box-shadow: $modal-shadow;
            border-radius: unset;
            padding: 0.5rem;
            width: 200px;
            margin: auto;
        }
    }
    .btn-frame#test.hidden{
        display: none;
    }
    &__option-panel{
        @include flexbox(row,space-between,center);
        padding:20px 20px 10px;
        // height: calc(10vh - 6px);
        // border: 2px solid red;
        min-height: max-content;
        &__left{
            display: flex;
            gap:16px;
            // padding:0.5rem;
            color:$write;
            .info-box{
                @include flexbox(row,flex-start,center);
                gap:1.5rem;
                &__heading{
                    @include flexbox(row,center,center);
                    gap:1.5rem;
                }
                #meet-info{
                    display: flex;
                    position: relative;
                    svg{
                        cursor: pointer;
                    }
                }
            }
        }
        &__center{
            .action-frame{
                @include flexbox(row,center,center);
                @include gap-responsive;
            }
        }
        &__right{
            @include flexbox(row,center,center);
            @include gap-large;
            color:$write;
            .action-frame{
                @include flexbox(row,center,center);
                position: relative;
                gap:0.65rem;
            }
        }
        .laptop-options{
            position: absolute;
            top: -8rem;
            right: 0;
            z-index: 999;
            &__body{
                @include flexbox(column);
                gap:0.25rem;
            }
        }
    }
}




@media screen and (max-width:800px) {

    .devroom{
        &__option-panel{
            position: fixed;
            background:$primary;
            justify-content: center;
            bottom:0;
            left: 0;
            @include padding-responsive;
            border-top: 1px solid $border-alt;
            width: 100%;
            height: auto;
            padding: 1rem;
            &__center{
                // border: 2px solid red;
                width: 100%;
                @include flexbox(row,center);
                .action-frame{
                    // .btn-record,
                    #upload-folder{
                        display: none;
                    }
                }
            }
            .btn-frame#test{
                width: -webkit-fill-available;
                transform: initial;
            }
            &__right{
                display: none;
            }
            .action-frame{
                @include flexbox(row,center);
                flex-wrap: wrap;
                width: 100%;
                gap:1rem;
                // @include gap-responsive;
            }
        }
        .laptop-options{
            display: none;
        }
    }

    .devroom{
        &__option-panel{
            &__left{
                display: none;
            }
        }
    }



    .devroom__option-panel.--expanded{
        border-radius: 24px 24px 0 0;
    }
    
    .devroom{
        .action-frame{
            &__mobile{
                width: 100%;
                @include flexbox(column);
                @include gap-responsive;
                flex-wrap: wrap;
                color:$write;
                &__options{
                    @include flexbox(column);
                    width: 100%;
                    li{
                        @include flexbox(row,flex-start,center);
                        gap:10px;
                        padding:1rem;
                        border-bottom: 1px solid $border-alt;
                        &:nth-last-child(2){
                            border:unset;
                        }
                        &:last-child{
                            border:unset;
                            justify-content: flex-end;
                        }
                    }
                }
                &__top,&__bottom{
                    @include flexbox(row,center,center);
                    flex-wrap: wrap;
                    gap:1rem;
                }
                &__bottom{
                    justify-content: flex-end;
                }
            }
        }
    }

    .participant-fixed-option-box{
        .action-frame{
            &__mobile{
                &__options{
                    li{
                        &:nth-last-child(2){
                            border-bottom: 1px solid $border-alt;
                        }
                        &:last-child{
                            border:unset;
                            justify-content: initial;
                            color:$write;
                        }
                    }
                }
            }
        }
    }



}