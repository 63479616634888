.scroll-bar {
	&::-webkit-scrollbar {
		overflow: hidden;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 2px transparent;
		// border-radius: 6px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $border;
		box-sizing: border-box; /* Inclure la bordure dans la taille */
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: $border-modal;
	}
}

.scroll-bar {
	overflow: hidden;
}

*::-webkit-scrollbar {
	width: 0px;
}

.scroll-bar.invisible {
	&::-webkit-scrollbar {
		width: 0px;
	}
}

/* Pour Mozilla Firefox */
body {
	// scrollbar-width: none;
    scrollbar-color: #ffffff12 #ffffff04;
}

/* Désactiver l'élargissement du thumb lorsqu'il est survolé */
body::-moz-scrollbar-thumb:hover {
    min-width: inherit !important;
    max-width: inherit !important;
}


@media screen and (max-width: 800px) {
	.scroll-bar {
		&::-webkit-scrollbar {
			width: 4px;
		}
	}
}
