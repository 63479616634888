@function random-color() {
    $colors: (#A64FFF40, #4fcdff40, #91b94040); // Liste des couleurs possibles
    $random-index: random(length($colors)); // Index aléatoire dans la liste
    @return nth($colors, $random-index); // Couleur aléatoire sélectionnée
}

.preview-send-anim-modale{
    position: absolute;
    top:-140px;
    @include basic-frame;
    @include padding-responsive;
    @include gap-responsive;
    @include flexbox(column);
    &__body{
        .animated-svg{
            @include flexbox(row,space-between,center);
            @include gap-responsive;
            #test-mario{
                border-radius: 50%;
                width: 48px;
                height: 48px;
                @include padding-responsive;
                @include flexbox(row,center,center);
                cursor: pointer;
                // position: relative;
                transition: 0.4s ease-in;
                &:hover{
                    background-color: #4fffc440;
                }
            }
            &__item{
                border-radius: 50%;
                width: 48px;
                height: 48px;
                @include padding-responsive;
                @include flexbox(row,center,center);
                cursor: pointer;
                // position: relative;
                transition: 0.4s ease-in;
                svg{
                    position: relative;
                    transform-origin: center;
                }
                &:hover{
                    background-color: random-color(); // Utilisation de la fonction random-color()
                    transform: scale(1.16)
                }
            }
            #heart{
                &:hover{
                    svg{
                        animation:pulse 1.2s linear forwards infinite; 
                    }
                    background-color: #ff4f6c40;
                }
            }
            #thumbs-up{
                &:hover{
                    background-color: #4fffc440;
                }
            }
            #thumbs-down{
                &:hover{
                    background-color: #ffdf4f40;
                }
            }
        }
    }
    &__frame{
        // @include padding-responsive;
        padding:0 0 0.5rem;
    }
}

#clapping-hands{
    &:hover{
        svg{
            animation:pulse 1.2s linear forwards infinite; 
        }
    }
}

.clapping-hands{
    animation:clappingHands 2s linear forwards; 
}

.clone{
    position: absolute;
    transition: transform 1s ease-in-out;
}

.thumbs-up{
    animation:rotateThumbs ease 3s forwards; 
}

.thumbs-down{
    animation:thumbsDown ease-out 4s forwards; 
}





