.tab {
    display: flex;
    justify-content: space-around;
    height: 2rem;

    &>* {
        flex: 1;
        height: 100%;
        line-height: 1rem;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        border: 10px solid;
        border-image-slice: 1;
        border-width: 2px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }

    &__no-active {
        font-family: $font-secondary;

        color: $write-transp;
        border-image-source: linear-gradient(240.94deg, rgba(166, 79, 255, 0.2) 13.05%, rgba(99, 112, 231, 0.2) 81.16%);

    }

    &__active {
        font-family: $font-secondary-bold;
        color: #FFFFFF;
        border-image-source: $btn-gradient;
    }
}

@media screen and (max-width:800px) {

    .tab {
        height: 3rem;
    }

}