.devroom{
    .participant-panel,
    .screen-layout-panel,
    .visual-effects-panel{
        width: 100%;
        color:$write;
        @include flexbox(column);
        @include basic-frame;
        @include gap-large;
        border-radius: 12px;
        padding:1rem;
        &__body{
            overflow: overlay;
            @include flexbox(column,flex-start);
            @include gap-responsive;
            .search-frame{
                @include flexbox(row,flex-start,center);
                @include gap-responsive;
            }
        }
        &__frame{
            @include basic-frame;
            @include padding-responsive;
        }
    }
    .participant-list{
        @include flexbox(column);
        gap:0.75rem;
        margin-top: 10px;
        &__item{
            list-style-type: none;
            @include flexbox(row,space-between,center);
            @include gap-responsive;
            @include basic-frame;
            @include padding-responsive;
            flex-wrap: wrap;
            &__content{
                @include flexbox(row,flex-start,center);
                gap:10px;
                width: 50%;
                flex-basis: 60%;
            }
            &__options{
                .option-frame{
                    @include flexbox(row,flex-start,center);
                    gap:10px;
                }
            }
        }
        &__item.--talking{
            border:2px solid #72BBF196;
            background-color: #72BBF11A;
        }
        &__item.--sharing{
            border:2px solid #ffcf4fc4;
            background: linear-gradient(-120deg, #ffcf4f16, #ff9b4a16);
        }
        &__avatar{
            @include flexbox(row);
        }

    }
}

.devroom .screen-layout-panel__body{
    overflow: hidden;
}


@media screen and (max-width:800px){

    .devroom{
        .participant-panel, 
        .visual-effects-panel, 
        .screen-layout-panel{
            border-radius: 0;
            border:unset;
        }
    }

    @supports (-webkit-touch-callout: none) {

        .devroom{
            .screen-layout-panel,
            .visual-effects-panel,
            .participant-panel{
                // border:2px solid purple;
                height: 79.5dvh;
            }
        } 
    }

    

}
