
h1,h2,h3,h4,h5{
    font-weight: initial;
    user-select: none;
}

h1{
    font-family: $font-primary;
    
}

h2{
    font-family: $font-primary;
    margin-block: 0px;
    span{
        font-size: inherit;
    }
}



h3{
    font-family: $font-secondary;
}

h4{
    font-family: $font-secondary;
    margin-block-start:0.6rem;
    margin-block-end:0.6rem;
}

h5{
    font-family: $font-secondary;
}

// modals 

.share-modal,.contacts-modal,.meet-created-modal {
    h3{
        font-family: $font-primary;
        font-size: 24px;
        color: $write;
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

.user-modal{
    h4{
        font-family: $font-secondary-extrabold;
        font-size: 16px;
        text-align: center;
        color: $write;
        line-height: 22px;
    }
}

.meet-created-modal{
    .heading-frame{
        display: flex;
        align-items: center;
        @include gap-responsive;
    }
}

.modale-setup{
    h3{
        font-size: 18px;
    }
}

.devroom{
    h1{
        color:$write-transp;
    }
}



// panels 


.meeting-room-info{
    h4{
        font-family: $font-secondary-bold;
    }
}


// navbar 

.navbar__body #text_meet_by_ringover{
    color: $write;
    font-size: 18px;
    display: none;
}

// homepage 

.homepage{
    h1,h2,h4{
        color: $write;

    }
    h1{
        font-size: 42px;
        span{
            font-family: $font-primary-light;
            font-size: inherit;
        }
    }
    h2{
        font-size: 32px;
        margin-block-end: 0.5rem;
        margin-block-start: 0.5rem;
        span{
            font-size: inherit;
            font-family: inherit;
        }

    }
    h4{
        font-size: 16px;
        font-family: $font-secondary;
    }
    .heading-frame{
        h4{
            color:#fff9;
        }
    }
    .form-frame{
        h4{
            margin-block-start: 0;
            margin-block-end: 1rem;
            font-family: $font-secondary-bold;
        }
    }
    h5{
        color:$write-transp;
        @include font-responsive;
        width: fit-content;
        background-image: linear-gradient(
          -90deg,
          #A64FFF 0%,
          #6370E7 24%,
          #fffe 50%,
          #ffff 100%
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
        span{
            font-size: inherit;
            font-family: $font-secondary-bold;
            background: -webkit-linear-gradient(-20deg,#A64FFF, #6370E7);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &::after{
            content:'ok';
            transition: 0.4s ease;
            background-color: $write-transp;
            display: flex;
            height: 0.5px;
            width: 100%;
            opacity: 0;
        }
        &:hover{
            animation: textclip 3.2s ease-in infinite;
            span{
                background:unset;
                -webkit-background-clip:unset;
                -webkit-text-fill-color: unset;
            }
            &::after{
                opacity: 1;
            }
        }
    }

}


.stats-panel{
    h4{
        font-size: 16px;
    }
}


.share-modal,.contacts-modal,.preview-settings-modal {
    h3{
        color: $write;
    }
}

.advanced-settings{
    .form{
        &__row{
            .input__frame{
                h4{
                    margin-block-start: 0;
                    margin-block-end: 0;
                }
            }
        }
    }
}


.advanced-settings h4{
    font-size: 16px;
    font-family: $font-secondary;
}

.room-not-found__body__header{
    h2{
        text-align: center;
        color:$write;
    }
}


// prejoin 

.prejoin__left-panel .greetings-box,
.greetings-box__mobile{
    h2{
        color:$write;
        text-align: center;
        text-transform: capitalize;
        font-size: 28px;
    }

}


// upload-frame

.chat-panel{
    &__upload-frame{
        h4{
            margin-block-start: 0;
            margin-block-end: 0;
        }
    }
}


.message__box{
    &__heading{
        text-transform: capitalize;
        span{
            font-family: $font-secondary-bold;
            color:#72BBF1;
        }
    }
}

// top info box 

.participant-request{
    h4{
        color:$write;
    }
}


// record modals 

.record-modal{
    h2{
        &::first-letter{ 
            text-transform: uppercase;
        }
    }
    h5{
        font-family: $font-secondary-bold;
        font-size: 15px;
        margin-block: 0px;
    }
}

// meeting info modal 

.meeting-info-modale,.all-settings{
    h2{
        margin-block:unset;
    }
    h4{
        font-family: $font-secondary-bold;
        font-size: 15px;
        margin-block:unset;
    }
}


// windows 

.all-settings__frame.--ms-settings h2{
    margin-block-start: 0.25rem;
    margin-block-end: 0.25rem;
}



@media screen and (max-width:1600px) {

    .share-modal {
        h3{
            color: $write;
        }
    }


    .homepage{
        h2{
            font-size: 30px;
        }
        h1{
            font-size: 36px;
        }
    }

    .homepage,
    .meeting-room-info,
    .advanced-settings,
    .stats-panel{
        h4{
            font-size: 15px;
        }
    }

    .modale-setup{
        h3{
            font-size: 18px;
        }
    }

    .share-modal,.contacts-modal,.meet-created-modal{
        h3{
            font-size: 22px;
        }
    }

    .prejoin{
        &__left-panel{
            .greetings-box{
                h2{
                    font-size: 24px;
                }
            }
        }
    }

    .record-modal{
        h2{
            font-size: 22px;
        }
    }    


}



@media screen and (max-width:1024px) {



    .homepage{
        h2{
            font-size: 28px;

        }
        h1{
            font-size: 34px;
        }
        h4{
            font-size: 15px;
        }
    }

    .advanced-settings h4{
        font-size: 14px;
    }


    .share-modal,.contacts-modal,.meet-created-modal{
        h3{
            font-size: 18px;
        }
    }

    .preview-settings-modal{
        h3{
            font-size: 16px;
        }
    }

    .modale-setup{
        h3{
            font-size: 15px;
        }
    }



}


@media screen and (max-width:800px) {

    #text_set_up_meet,
    .navbar__body #text_meet_by_ringover{
        display: block;
    }
    
    .homepage{
        h1{
            font-size: 28px;
        }
        h2{
            font-size: 17px;
            display: none;
        }
        h4{
            font-size: 14px;
        }
    }

    .meeting-room-info{
        h4{
            font-size: 14px;
        }
    }


    .modale-setup{
        &__body{
            &__heading{
                h2{
                    font-size: 20px;
                }
            }
        }
    }

    .greetings-box__mobile{
        h2{
            font-size: 22px;
        }
    
    }

    .meeting-info-modale{
        h4{
            font-size: 14px;
        }
    }
    
    

}



@media screen and (min-width:1921px) {

    .homepage{
        h2{
            font-size: 1.72vw;
        }
        h1{
            font-size: 2.3vw;
        }
        h4{
            font-size: 0.84vw;
        }
    }

    .modale-setup h3 {
        font-size: 0.8vw;
    }

    #text_set_up_meet{
        font-size: 1.4vw;
    }

    h3{
        font-size: 0.72vw;    
    }

    .share-modal,.contacts-modal,.meet-created-modal{
        h3{
            font-size: 1.2vw;
        }
    }
    .share-modal,.contacts-modal{
        h3{
            font-size: 1.5vw;
        }
    }
}




