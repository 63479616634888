// gestion des croix, des fleches, interrupteurs 


.exit-frame{
    img{
        cursor: pointer;
        transform-origin: center;
        &:hover{
            transform: scale(0.92);
        }
    }
}
