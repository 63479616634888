.input-box{ 
    // border:4px solid red;
    font-family: $font-secondary;
    width: auto;
    &__value{
        @include padding-responsive;
        padding:0.65rem;
        border:1px solid $border-alt;
        background-color: #27252b;
        border-radius: $button-radius;
        @include flexbox(row,center,center);
        justify-content: space-between;
        cursor: pointer;
        height: 100%;
        color:$write;
        min-width: 160px;
        span{
            display: flex;
        }
    }
    &__options{
        position: relative;
        height: auto;
        min-height: auto;
        width: 100%;

        ul{
            position: absolute;
            margin-block-end: 0;
            margin-block-start: 0;
            margin-top: 0.75rem;
            z-index: 99;
            // min-height: 100px;
            max-height: 200px;
            overflow-y: scroll;
            width: 100%;
            list-style-type: none;
            padding-inline-start: 0;
            min-width: 220px;
            text-align: start;
            border:1px solid $border-alt;
            border-radius: $button-radius;
            background: #27252b;
            li{
                @include padding-responsive;
                @include font-responsive;
                cursor: pointer;
                color:$write;
                &:hover{
                    background:#242129;
                }
            }
            // li:first-child{
            //     border-top: 1px solid $border-alt;
            //     border-top-left-radius: $button-radius;
            //     border-top-right-radius: $button-radius;
            // }
            // li:last-child{
            //     border-bottom: 1px solid $border-alt;
            //     border-bottom-left-radius: $button-radius;
            //     border-bottom-right-radius: $button-radius;
            // }
        }
    }
}

.audio-video-box{
    .input-box__options ul{
        overflow: hidden;
    }
}

.preview-settings-modal{
    .input-box{
        &__value{
            // box-shadow: $modal-shadow;
            box-shadow:$button-shadow;
        }
    }
}


@media screen and (max-width:800px) {

    .input-box{ 
        &__options{
            ul{
                min-width: 160px;
                margin-top: 0.5rem;
            }
        }
    }

}