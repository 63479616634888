.action-frame span.tooltip,
.devroom__option-panel .info-box .tooltip,
.tooltip{
    position: absolute;
    font-size: 11px;
    padding: 0.1rem;
    @include basic-frame;
    z-index: 99;
    background-color: #24212948;
    color:$write-transp;
    border-radius: 4px;
    width: auto;
    text-align: center;
}



.tooltip::first-letter{
    text-transform: uppercase;
}


@media screen and (min-width:1920px) {

    .tooltip{
        font-size: 0.58vw;
    }

}