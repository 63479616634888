.devroom{
    &__mosaic{
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(24%,1fr));
        flex-wrap: wrap;
        justify-content: center;
        place-items: center;
        place-content: baseline;
        overflow: overlay;
        min-height: 40vw;
        width: 100%;
        @include gap-responsive;
    }

    &__mosaic.--two-participants{
        grid-template-columns: repeat(auto-fit, minmax(49%,1fr));
        .devroom__mosaic__screen{
            height: auto;   
        }
    }

    &__mosaic.--six-participants{
        grid-template-columns: repeat(auto-fit, minmax(33%,1fr));
    }
    
    &__mosaic.--four-participants{
        grid-template-columns: repeat(auto-fit, minmax(49%,1fr));
        .devroom__mosaic__screen{
            aspect-ratio: 16/9;
        }
    }


    &__mosaic.--four-participants .devroom__mosaic__screen{
        width: 100%;
        height: -webkit-fill-available;
    }

    &__mosaic.--three-participants{
        .devroom__mosaic__screen:nth-child(3){
            grid-column-start: span 2;
            width: 50%;
        }
        .devroom__mosaic__screen{
            aspect-ratio: 16/9;
        }
    }

    &__mosaic.--single-participant{
        .devroom__mosaic__screen{
            height: 100%;
            aspect-ratio: 16/9;
        }
    }

    &__mosaic.--nine-participants{
        grid-template-columns: repeat(auto-fit, minmax(20%,1fr));
    }
    &__mosaic.--twenty-participants{
        grid-template-columns: repeat(auto-fit, minmax(17%,1fr));
    }
    &__mosaic.--twenty-four-participants{
        grid-template-columns: repeat(auto-fit, minmax(15%,1fr));
    }


    .featured-frame{
        // border:4px solid #72BBF1;
        // border-radius: 12px;
        overflow: hidden;
        flex-basis: 80%;
        @include flexbox(row,center,center);
        display: none;
        color:$write-transp;
    }

    .featured-frame.flex{
        display: flex;
    }    
}


.devroom__mosaic.--single-participant,
.devroom__mosaic.--two-participants{
    place-content: initial;
}


.devroom__body__main.--flex-layout{
    .devroom__mosaic{
        grid-template-columns: repeat(auto-fit, minmax(99%,1fr));
        flex-basis: 20%;
        place-items: flex-start;
        place-content: baseline;
        .devroom__mosaic__screen{
            min-height: 220px;
            overflow: hidden;
        }
    }
}





@media screen and (max-width:800px) {

    
    .devroom{
        &__mosaic,
        &__mosaic.--six-participants,
        &__mosaic.--nine-participants,
        &__mosaic.--twenty-participants,
        &__mosaic.--twenty-four-participants{
            place-content: baseline;
            grid-template-columns: repeat(auto-fit, minmax(49%,1fr));
            min-height: unset;
            // max-height: 90svh;
            &__screen{
                height: 100%;
                min-height: 26vw;
            }
        }   
    }

    .devroom__mosaic.--four-participants .devroom__mosaic__screen{
        width: 100%;
        height: 100%;
    }

    .devroom__body__main.--flex-layout{
        .devroom__mosaic{
            .devroom__mosaic__screen{
                min-height: 30vw;
            }
        }
    }

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */ 

        .devroom{
            &__mosaic{
                place-content: flex-start;
                // min-height: 580px;
                position:-webkit-sticky;
            }
        }

        .devroom__body__main.--flex-layout .devroom__mosaic,
        .devroom__body__main .devroom__mosaic{
                .devroom__mosaic__screen{
                    min-height: 32vw;
                }
        }

        .devroom__body__main.--flex-layout{
            .devroom__mosaic{
                max-height: fit-content;
            }
        }
    }

    .devroom{
        &__mosaic{
            grid-template-columns: repeat(auto-fit, minmax(48%,1fr));
        }
    }

    .devroom__mosaic__screen{
        -webkit-overflow-scrolling: auto;
    }


    .devroom .featured-frame{
        flex-basis: 100%;
        max-height: 68vw;
        min-height: 68vw;
    }

    .devroom__body__main.--flex-layout{
        .devroom__mosaic{
            // border:2px solid orangered;
            flex-basis: unset;
            overflow-y: overlay;
            grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

        }
    }


}