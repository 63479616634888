// gestion de différentes tailles d'icones
// fonctionnement : en html on set un data-attribute data-size et on passe une valeur large, medium ou small 


*[data-interactive=true]{
    user-select: none;
    display: flex;
}


*[data-interactive=true] span,
*[data-interactive=true] svg,
*[data-interactive=true] img{
    opacity: 0.5;
    transition: 0.16s ease;
    object-fit:contain;
    user-select: none;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }    
}

*[data-interactive=true] span{
    padding: 0.25rem;
    opacity: 1;
}

*[data-interactive=true]{
    .opaque,
    #participant-add-user-icon,
    #pinned{
        opacity: 1;
        &:hover{
            opacity: initial;
        }
    }
}

*[data-interactive=true] img.top{
    transform-origin: center;
    transform: scaleY(-1);
}


#participant-add-user-icon{
    width: 30px;
    height: 30px;
}

button[data-interactive=true]:hover{
    img,svg{
        opacity: 1;
    }
}

*[data-size="x-large"] img{
    width: 36px;
    height: 36px;
}

*[data-size="large"] img{
    width: 26px;
    height: 26px;
}

*[data-size="medium"] img{
    width: 24px;
    height: 24px;
}

*[data-size="small"] img{
    width: 24px;
    height: 24px;
}

*[data-size="x-small"] img{
    width: 24px;
    height: 24px;
}


// gestion de classe active

img.active{
    opacity: 1;
}


particle {
    position:fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 9999;
}

@media screen and (max-width:1600px) {


    *[data-size="x-large"] img{
        width: 32px;
        height: 32px;
    }
    

}



@media screen and (min-width:1921px) {

    *[data-size="large"] img{
        width: 24px;
        height: 24px;
    }

    *[data-size="medium"] img{
        width: 24px;
        height: 24px;
    }
    


}
