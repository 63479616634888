/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.typecode-frame{
    &__body{
        .box{
          @include flexbox(row,center);
            @include gap-responsive;
            input[type=number]{
                width: 48px;
                height: 48px;
                text-align: center;
                background: $input-gradient;
                border: 2px solid $border;
                color:$write;
            }
        }
    }
}