.bg-panel{
    min-height: 100vh;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    &__left,&__right{
        position: absolute;
        .ornament-frame{
            width: auto;
            display: flex;
            svg{
                opacity: 0.72;
                width: 42vw;
                height: auto;
            }
        }
    }   
    &__left{
        bottom: -20%;
        left:0%;
        transform: rotate(36deg) translateX(-24%);
    }
    &__right{
        right: -4%;
        top:6%;
        width: 42vw;
        height: 42vw;
        animation: rotatingCircles 48s ease  infinite alternate;
        .ornament-frame{
            flex-direction: column;
            svg{
                width: auto;
                height: auto;
                &:nth-child(2){
                    transform: scaleY(-1) translateY(-20%);
                }
            }

        }
    }
}


@media screen and (max-width:800px) {



    .bg-panel__left{
        bottom: -4%;
        left:-4%;
        .ornament-frame svg{
            width: 104vw;
        }
    }
    .bg-panel__right{
        display: none;
    }

}


