.Alert {
    width: 256px;
    z-index: 10;
    top: 0;
    margin: auto;
    padding: 16px;
    border: 1px solid black;
    background-color: white;
    box-shadow: 1px 2px 8px rgb(0 0 0 / 30%);
    margin-top: 48px;
    border-radius: 4px;
    font-family: "Courier New", Courier, monospace;
}

.Alert button {
    padding: 4px;
    border-radius: 3px;
    border: none;
    width: 72px;
    font-weight: bold;
}

button#ok {
    background-color: blue;
    color: white;
}

button#ok:hover {
    background-color: rgb(47, 47, 255);
}

.Alert .Actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px 0px 0px 0px;
}

.Alert h1 {
    font-size: 21px;
}

.Alert h3 {
    font-size: 12px;
}

.Backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    user-select: none;
}