.preview-settings-modal{
    &__container{

        width: auto;
        position: relative;
        overflow: visible;
        gap: 1.5rem;
    }
    &__body{
        display: grid;
        grid-template-columns: auto 1fr;
        gap:1.5rem;
        margin-bottom: 1rem;
        &__left{
            @include flexbox(column);
            .btn-frame{
                @include gap-responsive;
                @include flexbox(column);
                min-width: 230px;
            }
        }
        &__right{
            @include flexbox(column);
            @include gap-responsive;
            position: relative;
            min-height: 580px;
            width: 680px;
            .audio-video-box{
                @include flexbox(column);
                @include gap-responsive;
                position: relative;
            }
            .option-frame{
                @include flexbox(row,flex-start,flex-start);
                padding: 0.65rem;
                gap:1rem;
            }
        }
    }
}

.audio-video-box,.more-options-box{
    .input-frame{
        @include flexbox(column);
        @include gap-responsive;
        &__single,&__double{
            @include flexbox(column);
            gap:0.6rem;
            &__row{
                display: grid;
                grid-template-columns: 1fr 0.6fr;
                @include gap-responsive;
            }
        }
    }
}

@media screen and (max-width:1600px) {

    .preview-settings-modal__body{
        &__right{   
            min-height: 510px;
            width: 560px;
        }
    }

    .preview-settings-modal{
        overflow-y: auto;
        padding: 2%;
        &__container{
            margin: auto;	
        }
    }

    
}


@media screen and (max-width:1200px) {

    .preview-settings-modal{
        &__container{
            width: -webkit-fill-available;
        }
    }


}


@media screen and (max-width:1024px) {

    .preview-settings-modal__body__right{
        // width: 540px;
        width: auto;
    }

}


@media screen and (max-width:800px) {


    .preview-settings-modal{
        overflow-y: initial;
        padding: initial;
        &__container{
            width: auto;
        }
        &__body{
            &__right{
                min-height: auto;
            }
        }
    }

    .preview-settings-modal{
        height: inherit;    
        padding:1.5rem 1rem;
        &__container{
            // overflow-y: scroll;
            overflow-y: auto;
            // height: -webkit-fill-available;
            height: 100%;
        }
    }


    .preview-settings-modal__body{
        grid-template-columns: 1fr;
        gap:1rem;
        &__right{
            // width: 300px;
            min-height: 480px;
        }
    }

    .audio-video-box,.more-options-box{
        .input-frame{
            &__double,&__single{
                gap:0.5rem;
            }
        }
        .input-frame__double__row{
            @include flexbox(column);
        }
    }

}


@media screen and (min-width:1921px) {

    .preview-settings-modal{
        &__body{
            grid-template-columns: auto 1fr;
        }
    }


}