/* todo refacto  */

.HourPicker{
    width: 100%;
    flex-direction: column;

    &__container{
        ul{
            list-style-type: none;
            display: grid;
            place-items: center;
            grid-template-columns: repeat(12,1fr);
            padding-inline-start: 0;
            margin-block-end: 0.5rem;
            margin-block-start: 0.5rem;
            li{
                border: 1px solid $border;
                width: 100%;
                padding: 0.5rem;
                font-family: $font-secondary;
                font-size: smaller;
                &:hover{
                    background-color: #6370E7a4;
                }
            }
            .active{
                background-color: #6370E7;
            }
        }
        &__span{
            @include flexbox(row,center,center);
        }
    }

}

.HourPicker__container:nth-child(1){
    @include flexbox(row,center,center);
    @include gap-responsive;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}

.calendar{
    display: flex;
    flex-direction: column;
    @include gap-responsive;
    &__body{
        display: flex;
        @include gap-responsive;
        .grid-box{
            ul{
                display: grid;
                grid-template-columns: repeat(5,1fr);
            }
        }
    }
    &__month-select{
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}


@media screen and (max-width:1600px) {

    .HourPicker__container ul {
        grid-template-columns: repeat(8, 1fr);
    }


}


@media screen and (max-width:800px) {

    .HourPicker input {
        width: 40%;
    }

    .HourPicker__container ul {
        grid-template-columns: repeat(5, 1fr);
    }

    .HourPicker__container:nth-child(1){
        justify-content: flex-start;
        gap:0.5rem;
    }

}