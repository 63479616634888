.modale-setup__body,
.chat-panel,
.participant-panel,
.screen-layout-panel,
.visual-effects-panel,
.stats-panel {
	&__heading {
		@include flexbox(row, space-between, center);
		align-items: center;
		.exit-frame {
			display: flex;
			img {
				cursor: pointer;
			}
		}
	}
}
