.duo-tab{
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    @include gap-large;
    &__option{

        .btn-frame{
            @include flexbox(column);
            min-width:230px;
            gap:0.32rem;
        }
    }
    &__content{
        min-width: 400px;
        overflow-y: overlay;
        // max-height: 300px;
    }

}


.all-settings{
    .duo-tab{
        &__option{
            .btn-frame{
                min-width: 280px;
            }
        }
        &__content{
            overflow: visible;
            position: relative;
            z-index: 99;
            min-width: 560px;
            max-width: 560px;
            height: 520px;
        }
    }
}


@media screen and (max-width:1600px) {

    .all-settings{
        .duo-tab{
            &__content{
                min-width: 540px;
                max-width: 540px;
                height: 480px;
            }
        }
    }

}


@media screen and (max-width:800px) {

    .duo-tab{
        grid-template-columns: 1fr;
        overflow-y: scroll;
        &__content{
            min-width: 310px;
        }
    }

}