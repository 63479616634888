// global 

textarea{
    resize: none;
}

textarea,
::placeholder,
input,select,option{
    @include font-responsive;
    font-family: $font-secondary;
    border-radius: $button-radius;
}

input[type="number"]{
    font-size: 20px;
    font-family: $font-secondary-bold;
}


input[type="time"] {
    border: 2px solid #ccc;
    padding: 8px;
    font-size: 16px;
    color: #333;
    background-color: $primary;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
  
input[type="time"]:focus {
    outline: none;
    border-color: #5c5cff;
    box-shadow: 0 0 5px rgba(92, 92, 255, 0.5);
}


input[type="submit"]{
    -webkit-appearance: none;
    cursor: pointer;
}

input:focus,
textarea:focus,
select:focus{
    outline: unset;
}


::placeholder{
    color:#fff4;
}


select,option{
    width: fit-content;
    color: $write;
    @include padding-responsive;
    appearance: initial;
}



.input-frame{
    &__input,textarea{
        background:$input-gradient;
        padding: 10px 15px;
        color: $write;
        border:1px solid $border;
    }
}



.basic-settings{
    .form__row__block{
        select,option{
            background-color:#242129;
            border:1px solid $border;
            width: 100%;
        }
        select{
            background: $border;
        }
    }

}



// specific 

.homepage,.prejoin{
    .form{
        &__body{
            .input-frame{
                input{
                    background:unset;
                    color:$write;
                    border:unset;
                    height: auto;
                    position: absolute;
                    text-indent: 3.8rem;
                    width: inherit;
                    height: 48px;
                }
                svg,img{
                    position: absolute;
                    margin: auto 1.25rem;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

.prejoin{
    .form{
        &__body{
            .input-frame{
                @include padding-responsive;
                input{
                    position: relative;
                }
            }
        }
    }
}





.input-frame.input-searchbar{
    display: flex;
    align-items: center;
    width: 100%;
    background: $input-gradient;
    height: 100%;
    position: relative;
    input{
        background:unset;
        border:unset;
        border: 1px solid $border-alt;
        height: 100%;
        text-indent: 50px;
        // @include padding-responsive;
        width: inherit;
    }
    svg,img{
        position: absolute;
        margin: auto 10px auto 15px;
        width: 24px;
        height: 24px;
    }
}

.HourPicker{
    input{
        background-color: $primary;
        border: 1px solid $border-alt;
        @include padding-responsive;
        color:$write;
        width: 18%;
    } 
    input[type="submit"]{
        background:$button-gradient;
    }
}

// prejoin 


// room 

.chat-panel{
    &__dialog-action{
        .input-frame{
            // position: relative;
            border: 1px solid $border;
            border-radius: $button-radius;
            display: flex;
            align-items: flex-end;
            background: $input-gradient;
            width: 100%;
            // height: 42px;
            textarea{
                background:unset;
                border: unset;
                // @include padding-responsive;
                font-family: 'EmojiMart';
                width: 100%;
                height: 42px;
                // max-height: 144px;
                resize: none;
                z-index: 0;
                color:$write;
                font-size: 16px;
                border-radius: unset;
                max-height: 122px;
            }
            .overlay{
                height: 100%;
                display: flex;
                right: 0;
                gap: 10px;
                width: auto;
                z-index: 1;
                @include flexbox(row,center,center);
                padding: 8px 15px 8px 10px;
            }
        }
    }
    // on personnalise le label comme on veut
    .label-file {
        background: $button-gradient;
        border-radius: $button-radius;
        cursor: pointer;
        color: $write-transp;
        @include padding-responsive;
    }
    .label-file:hover {
        color: $write;
    }

    // et on masque le input
    .input-file {
        display: none;
    }
}

.external-box{
    &__textarea{
        background:unset;
        border: 1px solid $border;
        height: 50%;
        padding: 0.5rem;
        width: 100%;
        resize: none;
        z-index: 0;
        color:$write;
        border-radius: unset;
    }
}





.input-link.input-frame{
    textarea,input,::placeholder{
        color:$write-transp;
    }
}

.meeting-details-box__container__content .input-link.input-frame input::placeholder{
    color:$write;
}


@media screen and (max-width:1600px) {


    
    .homepage{
        .form{
            &__body{
                .input-frame{
                    input{
                        height: 46px;
                    }
                }
            }
        }
    }



}


@media screen and (max-width:1024px) {

    .chat-panel{
        &__dialog-action{
            .input-frame{
                textarea{
                    height: 40px;
                    line-height: 20px;
                    @include flexbox(row,flex-start,center);
                }
            }
        }
    }


}


@media screen and (max-width:800px) {


    .homepage{
        .form{
            &__body{
                .input-frame{
                    input{
                        width: 100%;
                    }
                }
            }
        }
    }


    .input-link.input-frame{
        textarea,input,::placeholder{
            font-size: 11px;
        }
    }
    
    




}



@media screen and (min-width:1921px) {

    .homepage{
        .form{
            &__body{
                .input-frame{
                    input{
                        font-size: 0.72vw;
                        height: 2.6vw;
                        text-indent: 3.2vw;
                    }
                    svg,img{
                        margin: auto 1vw;
                        width: 1.25vw;
                        height: 1.25vw;
                    }
                }
                ::placeholder{
                    font-size: 0.72vw;
                }
            }
        }
    }



}

